import React from 'react';
import { MenuItem } from './MenuItem';
import { PersonAddIcon } from '@zitcha/component-library';
import { RenderUserManagementModal } from 'app/OrganisationSettings/UserManagement/RenderUserManagementModal';
import { useUserManagementModalState } from 'app/OrganisationSettings/UserManagement/hooks/useUserManagementModalState';

export const InviteUserButton = ({ onlyIcon }: { onlyIcon?: boolean }) => {
  const { modalState, openModal, closeModal } = useUserManagementModalState();

  return (
    <>
      <MenuItem
        Icon={PersonAddIcon}
        title='Invite user'
        onlyIcon={onlyIcon}
        onClick={() =>
          openModal({
            user: null,
            mode: 'create',
          })
        }
      />
      <RenderUserManagementModal modalState={modalState} closeModal={closeModal} />
    </>
  );
};
