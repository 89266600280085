import React from 'react';
import { Outlet } from 'react-router-dom';
import { DynamicBreadcrumbs } from '../components/DynamicBreadcrumbs/DynamicBreadcrumbs';
import { Container } from '@zitcha/component-library';

export const BaseLayout: React.FC = () => {
  return (
    <>
      <Container maxWidth='xl' sx={{ mt: 3, width: '100%' }}>
        <DynamicBreadcrumbs showHomeIcon={true} />
        <Outlet />
      </Container>
    </>
  );
};
