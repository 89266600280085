import React from 'react';
import { Box, DeleteIcon, Divider, IconButton, Stack, Typography } from '@zitcha/component-library';
import { Product } from 'features/common/types';

const ProductListItem = ({
  product,
  removeProduct,
}: {
  product: Product;
  removeProduct: (productId: string) => void;
}) => {
  return (
    <Box display='flex' alignItems='center' height={50} padding={1} gap={1} justifyContent='space-between'>
      <Box display='flex' alignItems='center' gap={1} height={'100%'}>
        <img src={product.image_url} alt={product.name} style={{ height: '100%' }} />
      </Box>
      <Typography variant='body1' sx={{ flexGrow: 1, textAlign: 'left' }}>
        {product.name.length > 30 ? product.name.substring(0, 27) + '...' : product.name}
      </Typography>
      <IconButton onClick={() => removeProduct(product.id)} aria-label='remove product'>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export const SelectedProductsList = ({
  products,
  removeProduct,
  minProducts,
  maxProducts,
}: SelectedProductsListProps) => {
  const isProductListEmpty = !products || products.length === 0;
  let message = '';

  if (minProducts === 0 && maxProducts) {
    message = `Select up to ${maxProducts} product${maxProducts > 1 ? 's' : ''}`;
  } else if (!maxProducts) {
    message = `Select at least ${minProducts} product${minProducts !== 1 ? 's' : ''}`;
  } else if (minProducts > 0 && maxProducts) {
    message = `Select between ${minProducts} and ${maxProducts} products`;
  }

  return (
    <Box width={400} padding={1} data-testid='selected-products-list'>
      <Stack>
        <Box mb={2}>
          {isProductListEmpty ? (
            <Typography variant='h6' sx={{ textAlign: 'center' }}>
              {message}
            </Typography>
          ) : (
            <Typography variant='h6' sx={{ paddingLeft: '10px' }}>
              {Number(products.length).toLocaleString()} selected product{products.length !== 1 ? 's' : ''}
            </Typography>
          )}
        </Box>
        {products.map((product, index) => (
          <React.Fragment key={product.id}>
            <ProductListItem product={product} removeProduct={removeProduct} />
            {index < products.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export interface SelectedProductsListProps {
  products: Array<Product>;
  removeProduct?: (productId: string) => void;
  minProducts: number;
  maxProducts?: number;
}
