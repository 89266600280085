import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { unixTimestampToDate } from '../../lib/dates';
import {
  checkMPAEligibility as checkMPAEligibilityRequest,
  getMPASupplierSettings as getMPASupplierSettingsRequest,
  getOrganisation,
  onBoardMPASupplier as onBoardMPASupplierRequest,
  updateSettings as updateSettingsRequest,
} from '../../lib/api';

export const updateSettings = createAsyncThunk(
  'organisation_settings/updateSettings',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
      const data = {
        read_permissions: selectReadPermissions(getState()),
        settings: selectSettings(getState()),
      };
      const response = await updateSettingsRequest(arg, data);
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOrganisation = createAsyncThunk(
  'organisation_settings/organisation',
  async (organisationID, { getState, dispatch, rejectWithValue }) => {
    try {
      let response = await getOrganisation(organisationID);

      dispatch(organisationUpdate(response.data.data));
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMPASupplierSettings = createAsyncThunk(
  'organisation_settings/getMPASupplierSettings',
  async (supplierId, { dispatch, rejectWithValue }) => {
    try {
      const response = await getMPASupplierSettingsRequest(supplierId);
      dispatch(mpaResetOnboardingValidationErrors());
      dispatch(mpaUpdate(response.data.data.mpa));
      return response.data.data.mpa;
    } catch (err) {
      console.error('getMPASupplierSettings error', err);
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);


export const checkMPAEligibility = createAsyncThunk(
  'organisation_settings/checkMPAEligibility',
  async (supplierId, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await checkMPAEligibilityRequest(supplierId);
      dispatch(getMPASupplierSettings(supplierId));
      return response.data;
    } catch (err) {
      console.error('checkMPAEligibility error', err);
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const onboardMPASupplier = createAsyncThunk(
  'organisation_settings/onboardMPASupplier',
  async ({ supplierId, formFields }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await onBoardMPASupplierRequest(supplierId, formFields);
      dispatch(getMPASupplierSettings(supplierId));
      return response.data;
    } catch (err) {
      console.error('onboardMPASupplier error', err);
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const initialState = {
  organisation: {
    id: undefined,
    read_permissions: {
      metrics: {},
      facebook_custom_audiences: [],
      google_remarketing_audiences: [],
      product_catalogs: [],
      product_sets: [],
    },
    settings: {
      margin: {},
      min_budget: {},
      enabled_ads: {},
    },
  },
  mpa: {},
  mpaEligibilityRequestStatus: 'idle',
  mpaEligibilityRequestErrors: {},
  mpaOnboardingRequestStatus: 'idle',
  mpaOnboardingRequestErrors: {},
  mpaSupplierSettingsRequestStatus: 'idle',
  mpaSupplierSettingsRequestErrors: {},
  status: 'idle',
  errors: {},
};
const organisationSettingsSlice = createSlice({
  name: 'organisation_settings',

  initialState,
  reducers: {
    idUpdate(state, action) {
      state.organisation.id = action.payload;
    },
    readPermissionsUpdate(state, action) {
      state.organisation.read_permissions = action.payload;
    },
    metricsUpdate(state, action) {
      state.organisation.read_permissions.metrics = action.payload;
    },
    facebookCustomAudienceUpdate(state, action) {
      state.organisation.read_permissions.facebook_custom_audiences = action.payload;
    },
    facebookLookalikeAudienceUpdate(state, action) {
      state.organisation.read_permissions.facebook_lookalike_audiences = action.payload;
    },
    facebookSavedAudienceUpdate(state, action) {
      state.organisation.read_permissions.facebook_saved_audiences = action.payload;
    },
    mpaUpdate(state, action) {
      state.mpa = {
        ...state.mpa,
        ...action.payload,
      };
    },
    mpaResetOnboardingValidationErrors(state, action) {
      state.mpaOnboardingRequestErrors = {};
    },
    googleRemarketingAudienceUpdate(state, action) {
      state.organisation.read_permissions.google_remarketing_audiences = action.payload;
    },
    productsUpdate(state, action) {
      const [catalogues, productSets] = action.payload;

      state.organisation.read_permissions.product_catalogs = catalogues;
      state.organisation.read_permissions.product_sets = productSets;
    },
    settingsUpdate(state, action) {
      state.organisation.settings = action.payload;
    },
    organisationUpdate(state, action) {
      state.organisation = action.payload;
    },
    marginUpdate(state, action) {
      state.organisation.settings.margin = action.payload;
    },
    minBudgetsUpdate(state, action) {
      state.organisation.settings.min_budget = action.payload;
    },
    enabledAdTypesUpdate(state, action) {
      state.organisation.settings.enabled_ads = action.payload;
    },
    resetOrgSettings: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(updateSettings.pending, (state, action) => {
      state.status = 'loading';
      state.errors = {};
    });

    builder.addCase(updateSettings.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.read_permissions = action.payload.read_permissions;
        state.settings = action.payload.settings;
        state.status = 'success';
      }
    });

    builder.addCase(updateSettings.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.errors = action.payload;
      }
    });

    builder.addCase(updateOrganisation.pending, (state, action) => {
      state.status = 'loading';
      state.errors = {};
    });

    builder.addCase(updateOrganisation.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        state.read_permissions = action.payload.read_permissions;
        state.settings = action.payload.settings;
        state.status = 'success';
      }
    });

    builder.addCase(updateOrganisation.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
        state.errors = action.payload;
      }
    });

    builder.addCase(checkMPAEligibility.pending, (state, action) => {
      state.mpaEligibilityRequestStatus = 'loading';
      state.mpaEligibilityRequestErrors = {};
    });

    builder.addCase(checkMPAEligibility.fulfilled, (state, action) => {
      if (state.mpaEligibilityRequestStatus === 'loading') {
        state.mpaEligibilityRequestStatus = 'success';
      }
    });

    builder.addCase(checkMPAEligibility.rejected, (state, action) => {
      if (state.mpaEligibilityRequestStatus === 'loading') {
        state.mpaEligibilityRequestStatus = 'failed';
        state.mpaEligibilityRequestErrors = action.payload;
      }
    });

    builder.addCase(onboardMPASupplier.pending, (state, action) => {
      state.mpaOnboardingRequestStatus = 'loading';
      state.mpaOnboardingRequestErrors = {};
    });

    builder.addCase(onboardMPASupplier.fulfilled, (state, action) => {
      if (state.mpaOnboardingRequestStatus === 'loading') {
        state.mpaOnboardingRequestStatus = 'success';
      }
    });

    builder.addCase(onboardMPASupplier.rejected, (state, action) => {
      if (state.mpaOnboardingRequestStatus === 'loading') {
        state.mpaOnboardingRequestStatus = 'failed';
        state.mpaOnboardingRequestErrors = action.payload;
      }
    });

    builder.addCase(getMPASupplierSettings.pending, (state, action) => {
      state.mpaSupplierSettingsRequestStatus = 'loading';
      state.mpaSupplierSettingsRequestErrors = null;
    });

    builder.addCase(getMPASupplierSettings.fulfilled, (state, action) => {
      state.mpaSupplierSettingsRequestStatus = 'success';
    });

    builder.addCase(getMPASupplierSettings.rejected, (state, action) => {
      state.mpaSupplierSettingsRequestStatus = 'failed';
      state.mpaSupplierSettingsRequestErrors = action.payload;
    });
  },
});

export const {
  idUpdate,
  readPermissionsUpdate,
  metricsUpdate,
  facebookCustomAudienceUpdate,
  facebookLookalikeAudienceUpdate,
  facebookSavedAudienceUpdate,
  mpaResetOnboardingValidationErrors,
  mpaUpdate,
  googleRemarketingAudienceUpdate,
  productsUpdate,
  settingsUpdate,
  minBudgetsUpdate,
  enabledAdTypesUpdate,
  organisationUpdate,
  marginUpdate,
  resetOrgSettings,
} = organisationSettingsSlice.actions;

export const selectFacebookMPA = (state) => state?.organisation_settings.mpa;
export const selectMPALastCheckedEligibilityTimestamp = (state) => {
  const facebookMPA = selectFacebookMPA(state);
  return facebookMPA?.eligibility?.last_check ? unixTimestampToDate(facebookMPA.eligibility.last_check) : null;
};
export const selectMPAEligibilityRequestStatus = (state) => state.organisation_settings.mpaEligibilityRequestStatus;
export const selectMPASupplierSettingsRequestStatus = (state) =>
  state.organisation_settings.mpaSupplierSettingsRequestStatus;
export const selectMPASupplierActivePagesRequestStatus = (state) =>
  state.organisation_settings.mpaSupplierActivePagesRequestStatus;
export const selectMPAOnboardingRequestStatus = (state) => state.organisation_settings.mpaOnboardingRequestStatus;
export const selectMPAOnboardingRequestErrors = (state) => state.organisation_settings.mpaOnboardingRequestErrors;

export const selectReadPermissions = (state) => selectOrganisation(state).read_permissions;
export const selectSettings = (state) => selectOrganisation(state).settings;
export const selectStatus = (state) => state.organisation_settings.status;
export const selectId = (state) => selectOrganisation(state).id;

export const selectOrganisation = (state) => state.organisation_settings.organisation;

export default organisationSettingsSlice.reducer;
