import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import MetricPermissions from '../../features/organisation_settings/components/MetricPermissions';
import { FacebookPermissions } from './FacebookPermissions';
import { GooglePermissions } from './GooglePermissions';
import { MetaManagedPartnerAdSettings } from './MetaManagedPartnerAdSettings';
import AdSettings from '../../features/organisation_settings/components/AdSettings';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { CHANNELS_MANAGEMENT_FACEBOOK, CHANNELS_MANAGEMENT_GOOGLE } from 'lib/permissions';
import { usePermissions } from 'v2/lib/hooks/usePermissions';

export const GlobalSettingsTab = ({
  control,
  setValue,
  permissions,
  adSettings,
  adTypes,
  organisation,
  hasFacebookMPAEnabled,
  ENABLE_META_SETTINGS_FEATURE,
}) => {
  const { hasPermission } = usePermissions();
  const canManageFacebook = hasPermission(CHANNELS_MANAGEMENT_FACEBOOK);
  const canManageGoogle = hasPermission(CHANNELS_MANAGEMENT_GOOGLE);

  return (
    <>
      <Row className='mb-3'>
        <div className='rounded pl-3 d-flex'>
          To change settings for a specific supplier, click on the edit button next to the supplier’s name under Manage
          Supplier below.
        </div>
      </Row>
      <StyledRow className='mb-4'>
        {hasFacebookMPAEnabled && adSettings?.page_handlers?.length === 0 && (
          <Alert variant='warning'>
            <div className='d-flex'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>Select the default page(s) to display Meta Managed Partner Ads in the settings below.</span>
            </div>
          </Alert>
        )}
      </StyledRow>

      <Row className='mb-4'>
        <Col lg='12' xs='12'>
          <MetricPermissions isGeneralSettings={true} permissionsData={permissions} />
        </Col>
      </Row>
      <Row>
        {canManageFacebook && (
          <Col>
            <FacebookPermissions control={control} adSettings={adSettings} organisation={organisation} />
          </Col>
        )}
        {canManageGoogle && (
          <Col>
            <GooglePermissions control={control} adSettings={adSettings} organisation={organisation} />
          </Col>
        )}
      </Row>

      {ENABLE_META_SETTINGS_FEATURE && hasFacebookMPAEnabled && (
        <Row>
          <MetaManagedPartnerAdSettings control={control} adSettings={adSettings} setValue={setValue} />
        </Row>
      )}

      <Row className='mb-2'>
        <Col lg='12' xs='12'>
          <div className='d-sm-flex justify-content-between align-items-center my-3'>
            <h5 className='text-dark mb-0'>Ad Types</h5>
          </div>
          <hr />

          <AdSettings adTypes={adTypes} adSettings={adSettings} />
        </Col>
      </Row>
    </>
  );
};

const StyledRow = styled(Row)`
  width: 100%;
  margin-inline: auto;
  .alert {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background: #FFE787;
    font-size: 0.875rem;
    color: #00001e;
    font-weight: 600;
  }
  .d-flex{
    align-items: center;
    gap:6px;
  }
  svg{
    color:#00001E
  }
`;