import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import OrganisationList from '../../features/organisation_settings/components/List';
import { Throbber } from '../../features/common/Throbber';
import { MANAGE_SUPPLIER_SETTINGS } from 'lib/permissions';
import { usePermissions } from 'v2/lib/hooks/usePermissions';

export const MySuppliersSettingsTab = ({ suppliers, isLoading, navigate }) => {
  const { hasPermission } = usePermissions();
  const canManageSupplierSettings = hasPermission(MANAGE_SUPPLIER_SETTINGS);

  const hasSuppliers = suppliers.length > 0;

  return (
    <Row className='mb-4'>
      <Col lg='12'>
        {isLoading && <Throbber />}
        {hasSuppliers && !isLoading ? (
          <>
            <div className='mb-3'>
              <h5 className='text-dark mb-0'>View and edit my suppliers.</h5>
            </div>
            <OrganisationList
              organisations={suppliers}
              onClick={(organisation) => {
                if (canManageSupplierSettings) {
                  navigate(`/settings/suppliers/${organisation.id}`);
                }
              }}
            />
          </>
        ) : (
          <Button variant='secondary' onClick={() => navigate('/organisations/invite')}>
            Invite supplier
          </Button>
        )}
      </Col>
    </Row>
  );
};