import React, { useState } from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { usePermissionSets } from 'v2/lib/hooks/usePermissionSets';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';

type PermissionSetAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const PermissionSetAutocomplete: React.FC<PermissionSetAutocompleteProps> = (props) => {
  const [PermissionSetsQuery, setPermissionSetsQuery] = useState('');
  const PermissionSets = usePermissionSets({
    query: PermissionSetsQuery,
  });
  const debouncedPermissionSetQuery = useDebounceSetter(setPermissionSetsQuery);

  return (
    <AutocompleteFactory
      labelText={props.labelText || 'Product sets'}
      placeholderText={props.placeholderText || 'All product sets'}
      {...props}
      isLoading={PermissionSets.isLoading}
      options={PermissionSets.data || []}
      debouncedQuery={debouncedPermissionSetQuery}
    />
  );
};