import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axios from '../../../../axios';

export const useSendPasswordReset = () => {
  const sendResetPassword = async (email: string): Promise<void> => {
    try {
      await axios.post(`/user/reset`, {
        email,
      });
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        error.response.data;
      }
      throw error;
    }
  };

  const { isPending, isSuccess, isError, mutateAsync } = useMutation({
    mutationFn: sendResetPassword,
  });
  return {
    sendingPasswordReset: isPending,
    passwordResetSent: isSuccess,
    passwordResetError: isError,
    sendPasswordReset: mutateAsync,
  };
};
