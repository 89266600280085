import { PerPageEnum, useGetOrders } from 'v2/lib/api/access-management';

export const useOrders = ({ query }: { query?: string }) => {
  const { data, isLoading, isError } = useGetOrders({
    q: query,
    page: 1,
    perPage: PerPageEnum.NUMBER_25,
  });

  return {
    data: data?.data ?? [],
    isLoadingOrders: isLoading,
    ordersError: isError,
  };
};
