import { useSelector } from 'react-redux';
import { selectUserOrganisation } from 'features/session/sessionSlice';

type Logo = {
    url: string;
    name: string;
    path: string;
    storage: string;
    extension: string;
    creator_id: string;
};

type Settings = {
    logo: Logo;
    timezone: string;
    defaultCurrency: string;
};

export type Organisation = {
    id: string;
    name: string;
    is_active: boolean;
    settings: Settings;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    is_sandboxmode: boolean;
    permissions: null | Record<string, unknown>;
    is_retailer: boolean;
    has_wallets: boolean;
};

export const useUserOrganisation = (): Organisation => {
    return useSelector(selectUserOrganisation);
};