import { EditIcon, SaveIcon, PersonAddIcon } from '@zitcha/component-library';

interface Params {
  isViewMode: boolean;
  isCreateMode: boolean;
  isActive: boolean;
}
export const getConfig = ({ isViewMode, isCreateMode, isActive }: Params) => {
  const mode = isViewMode ? 'view' : isCreateMode ? 'create' : 'edit';

  const config = {
    view: {
      title: 'View user',
      primaryButtonLabel: 'Edit user',
      primaryButtonIcon: EditIcon,
      secondaryButtonLabel: 'Close',
      secondaryButtonColor: undefined,
      secondaryButtonVariant: 'text',
    } as const,
    create: {
      title: 'Invite new user',
      primaryButtonLabel: 'Invite user',
      primaryButtonIcon: PersonAddIcon,
      secondaryButtonLabel: 'Cancel',
      secondaryButtonColor: 'error',
      secondaryButtonVariant: 'text',
    } as const,
    edit: {
      title: 'Edit user',
      primaryButtonLabel: 'Save changes',
      primaryButtonIcon: SaveIcon,
      secondaryButtonLabel: isActive ? 'Deactivate user' : 'Activate user',
      secondaryButtonColor: isActive ? 'error' : 'success',
      secondaryButtonVariant: 'contained',
    } as const,
  };

  return config[mode];
};