import React from 'react';
import { CircularProgress, Box, Typography, Grid } from '@zitcha/component-library';
import { ProductGridTile, ProductListTile } from './ProductTile';
import { ProductSelectorViewMode } from './ToggleViewSwitch';
import { Product, ProductSet } from 'features/common/types';

interface ProductsContentProps {
  status: string;
  filteredProducts: Array<Product>;
  selectAllChecked: boolean;
  handleSelectAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleProductClick: (product: Product) => void;
  viewMode: ProductSelectorViewMode;
  setViewMode: (viewMode: ProductSelectorViewMode) => void;
  selectedProducts: Array<Product>;
  productSets: Array<ProductSet>;
}

const ProductsContentLoading = () => (
  <Box display='flex' justifyContent='center' alignItems='center' height='10rem'>
    <CircularProgress />
  </Box>
);

const ProductsContentError = () => (
  <Box display='flex' justifyContent='center' alignItems='center' height='10rem'>
    <Typography variant='h6'>Error loading products</Typography>
  </Box>
);

const ProductsContentBlankState = () => (
  <Box display='flex' justifyContent='center' alignItems='center' height='10rem'>
    <Typography variant='h6'>No products available</Typography>
  </Box>
);

const ProductSetsBlankState = () => (
  <Box display='flex' justifyContent='center' alignItems='center' height='10rem'>
    <Typography variant='h6'>No products available</Typography>
  </Box>
);

interface ProductsGridOrListProps {
  filteredProducts: Array<Product>;
  selectedProducts: Array<Product>;
  handleProductClick: (product: Product) => void;
}

const ProductsGrid = ({ filteredProducts, selectedProducts, handleProductClick }: ProductsGridOrListProps) => {
  return (
    <Grid container spacing={2} padding={2}>
      {filteredProducts.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id} display={'table'}>
          <ProductGridTile
            product={product}
            checked={selectedProducts.some((p) => p.id === product.id)}
            onClick={handleProductClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const ProductsList = ({ filteredProducts, selectedProducts, handleProductClick }: ProductsGridOrListProps) => {
  return filteredProducts.map((product, index) => (
    <ProductListTile
      key={product.id}
      checked={selectedProducts.some((p) => p.id === product.id)}
      product={product}
      onClick={handleProductClick}
      index={index + 1}
    />
  ));
};

const ProductsContentDisplay: React.FC<ProductsContentProps> = ({
  filteredProducts,
  handleProductClick,
  viewMode,
  selectedProducts,
}) => (
  <>
    {filteredProducts.length === 0 ? (
      <ProductsContentBlankState />
    ) : viewMode === ProductSelectorViewMode.Grid ? (
      <ProductsGrid
        filteredProducts={filteredProducts}
        selectedProducts={selectedProducts}
        handleProductClick={handleProductClick}
      />
    ) : (
      <ProductsList
        filteredProducts={filteredProducts}
        selectedProducts={selectedProducts}
        handleProductClick={handleProductClick}
      />
    )}
  </>
);

//todo destructure props
export const ProductsContent: React.FC<ProductsContentProps> = (props) => {
  const { status, filteredProducts, productSets } = props;
  if (!productSets.length) {
    return <ProductSetsBlankState />;
  } else if (status === 'pending') {
    return <ProductsContentLoading />;
  } else if (status === 'error') {
    return <ProductsContentError />;
  } else if (status === 'success' && filteredProducts.length === 0) {
    return <ProductsContentBlankState />;
  } else if (status === 'success') {
    return <ProductsContentDisplay {...props} />;
  } else {
    return null;
  }
};
