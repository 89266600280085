import { useCallback } from 'react';
import debounce from 'debounce';
export const useDebounceSetter = (setter: (value: unknown) => void, delay = 500) => {
  return useCallback(
    debounce((value: unknown) => {
      setter(value);
    }, delay),
    [setter, delay]
  );
};
