import React from 'react';
import { Box, InputLabel, TextField } from '@zitcha/component-library';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { ModalInputCaption } from '../components/ModalInputCaption';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { BaseOption } from '@zitcha/component-library/dist/components/Inputs/Autocomplete/Autocomplete';
import { FormValues } from './CreateRole';
import { PermissionSetAutocomplete } from '../components/PermissionSetAutocomplete';

export type InputMode = 'creating' | 'editing';

type SetValueDataType = FormValues[keyof FormValues] | Array<OptionsType> | Array<BaseOption>;

interface RenderModalInputsProps {
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  setValue: (name: keyof FormValues, value: SetValueDataType) => void;
}

export const RenderModalInputs: React.FC<RenderModalInputsProps> = ({
  control,
  errors,
  setValue,
}) => {
  const selectedPermissionSets = useWatch({ control, name: 'permissionSets' });

  return (
    <>
      <Box display='flex' gap={2} flexDirection='column' my={2}>
        <Box>
          <InputLabel>Role name</InputLabel>
          <Controller
            name='name'
            control={control}
            render={({ field: controllerRenderProps }) => (
              <TextField
                variant='standard'
                fullWidth
                {...controllerRenderProps}
                ref={null}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
              />
            )}
          />
        </Box>
        <Box>
          <InputLabel>Role description</InputLabel>
          <Controller
            name='description'
            control={control}
            render={({ field: controllerRenderProps }) => (
              <TextField
                variant='standard'
                fullWidth
                {...controllerRenderProps}
                ref={null}
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : ''}
              />
            )}
          />
        </Box>
        <Box display='flex' gap={3} flexDirection='column'>
          <Box>
            <InputLabel>Select permission sets</InputLabel>
            <Controller
              name='permissionSets'
              control={control}
              render={({ field: controllerRenderProps }) => (
                <PermissionSetAutocomplete
                  type='form'
                  selectedOptions={selectedPermissionSets}
                  setSelectedOptions={(data) => setValue('permissionSets', data)}
                  controllerRenderProps={controllerRenderProps}
                />
              )}
            />
            {!!errors.permissionSets && (<>{errors.permissionSets.message}</>)}
            <ModalInputCaption list={selectedPermissionSets} name='permission sets' />
          </Box>
        </Box>
      </Box>
    </>
  );
};