import { useDeleteTeam } from 'v2/lib/api/access-management';
import { useQueryClient } from '@tanstack/react-query';
import { TEAM_MANAGEMENT_TEAMS_QUERY_KEY } from 'v2/constants/accessManagementConstants';

interface UpdateTeamWithHandlerParams {
  teamId: number | null;
  handleClose?: () => void;
}

export const useDeleteTeamWithHandler = ({
  teamId,
  handleClose,
}: UpdateTeamWithHandlerParams) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteTeam,
    isError,
    isSuccess,
    error,
    isPending,
  } = useDeleteTeam({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [TEAM_MANAGEMENT_TEAMS_QUERY_KEY] });
      },
    }
  });

  const handleDeleteTeam = async () => {
    if (!teamId) return;
    try {
      await deleteTeam({ teamId: teamId }).then(() => {
        handleClose?.();
      });
    } catch (error) {
      return error;
    }
  };

  return { isError, isSuccess, isPending, error, handleDeleteTeam };
};
