import { GridRenderCellParams } from '@zitcha/component-library';

// if the value has the adset but not the supplier or owner, then it is unavailable (based on permissions)
export const isSpaceUnavailable = (params: GridRenderCellParams): boolean => {
  if (params.value) {
    const { supplier, owner } = params.value;
    if (Array.isArray(supplier) && supplier.length === 0) {
      return true;
    }
    if (Array.isArray(owner) && owner.length === 0) {
      return true;
    }
  }
  return false;
};
