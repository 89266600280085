import React, { useState } from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useOrders } from 'v2/lib/hooks/useOrders';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';

type OrderAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const OrderAutocomplete: React.FC<OrderAutocompleteProps> = (props) => {
  const [OrdersQuery, setOrdersQuery] = useState('');
  const Orders = useOrders({
    query: OrdersQuery,
  });
  const debouncedOrderQuery = useDebounceSetter(setOrdersQuery);

  return (
    <AutocompleteFactory
      labelText={props.labelText || 'Orders'}
      placeholderText={props.placeholderText || 'All orders'}
      isLoading={Orders.isLoadingOrders}
      {...props}
      options={Orders.data || []}
      debouncedQuery={debouncedOrderQuery}
    />
  );
};