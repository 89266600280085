import React, { useState } from 'react';
import { Button, Form, InputGroup, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectSession } from '../../session/sessionSlice';
import { useNavigate } from 'react-router-dom';
import styles from './list.module.scss';
import styled from 'styled-components';

const applyFilters = (organisation, filter) => {
  return organisation.filter(
    (org) => !filter.searchTerm || org.name.toLowerCase().includes(filter.searchTerm.toLowerCase())
  );
};

const List = ({ organisations, onClick, isSupplier }) => {
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const [filters, setFilters] = useState({ searchTerm: null });
  const [tablePage, setTablePage] = useState(0);
  const [tablePageAmount, setTablePageAmount] = useState(5);
  const handleFilterChange = (filterType) => setFilters({ ...filters, ...filterType });

  const filteredOrganisations = applyFilters(_.orderBy(organisations, 'name', 'asc'), filters);

  function nextTable() {
    let x = tablePage;
    x++;
    setTablePage(x);
  }

  function prevTable() {
    let x = tablePage;
    if (tablePage >= 1) x--;
    setTablePage(x);
  }

  return (
    <Row>
      <Col md={12}>
        {!isSupplier && organisations && organisations.length > 5 && (
          <div className='d-flex align-items-center justify-content-end py-2'>
            <Form.Group className={styles.search_bar}>
              <InputGroup>
                <Form.Control
                  type='text'
                  value={filters.searchTerm || ''}
                  style={session?.theme?.components?.form_control}
                  onChange={(e) => {
                    handleFilterChange({ searchTerm: e.target.value });
                    setTablePage(0);
                  }}
                  placeholder='Search Suppliers'
                />
                <InputGroup.Text className={styles.search_icon}>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Button
              className={styles.invite_button}
              variant='primary'
              onClick={() => navigate('/organisations/invite')}
            >
              Invite New Supplier
            </Button>
          </div>
        )}
      </Col>
      <Col>
        <hr />
      </Col>
      <Col md={12}>
        <StyledTable className={styles.tablestyle}>
          <thead>
            <TableHeaderRow>
              <th>Logo</th>
              <th>Supplier Name</th>
              <th></th>
            </TableHeaderRow>
          </thead>
          <tbody>
            {filteredOrganisations.map(
              (organisation, index) =>
                tablePage * tablePageAmount < index + 1 &&
                index < (tablePage + 1) * tablePageAmount && (
                  <TableRow
                    key={`${organisation.name}${index}`}
                    onClick={() => {
                      if (onClick) {
                        onClick(organisation);
                      }
                    }}
                  >
                    <td>
                      {organisation.logoUrl ? (
                        <StyledImgDiv>
                          <StyledLogo src={organisation.logoUrl} alt={`${organisation.name} logo`} />
                        </StyledImgDiv>
                      ) : (
                        <StyledImgDivDashed />
                      )}
                    </td>
                    <td>
                      {isSupplier ? (
                        <p className='m-0'>{organisation.name}</p>
                      ) : (
                        <Link
                          to={'#'}
                          onClick={(e) => {
                            e.preventDefault();
                            return onClick(organisation);
                          }}
                        >
                          {organisation.name}
                        </Link>
                      )}
                    </td>
                    <td>
                      {onClick && (
                        <Link
                          to={'#'}
                          className='ml-auto sup-link'
                          onClick={(e) => {
                            e.preventDefault();
                            return onClick(organisation);
                          }}
                        >
                          <span>Edit</span>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Link>
                      )}
                    </td>
                  </TableRow>
                )
            )}
          </tbody>
        </StyledTable>

        <SelectContainer>
          <Form.Label>Show</Form.Label>
          <select
            className='custom-select'
            value={tablePageAmount}
            onChange={(e) => {
              setTablePageAmount(+e.target.value);
              setTablePage(0);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
          <Form.Label>Suppliers per page</Form.Label>
        </SelectContainer>
        <div className={styles.paginator}>
          <PaginationBtn type='button' onClick={prevTable}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </PaginationBtn>
          <span>
            {tablePage + 1} of {Math.floor(filteredOrganisations.length / tablePageAmount + 1)}
          </span>
          <PaginationBtn
            type='button'
            disabled={tablePage + 1 >= Math.floor(filteredOrganisations.length / tablePageAmount + 1)}
            onClick={
              tablePage + 1 < Math.floor(filteredOrganisations.length / tablePageAmount + 1)
                ? nextTable
                : () => {
                    return;
                  }
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </PaginationBtn>
        </div>
      </Col>
    </Row>
  );
};

export default List;

const StyledTable = styled(Table)`
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
`;
const TableRow = styled.tr`
  td:last-child {
    text-align: end;
    .sup-link {
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: underline;
      span {
        margin-left: auto;
      }
    }
  }
  &:hover {
    background-color: #f3f0ff;
  }
  cursor: pointer;
  transition: background-color 0.3s;
`;
const TableHeaderRow = styled.tr`
  width: 100%;
  border: 1px solid #ededef;
  border-bottom: 0px;
  background-color: #ededef;
`;
const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: flex-end;
  .custom-select {
    width: 80px;
    margin-right: 5px;
  }
`;
const StyledImgDiv = styled.div`
  width: 100px;
  max-height: 50px;
  margin-right: 1rem;
  position: relative;
  text-align: center;
`;
const StyledImgDivDashed = styled(StyledImgDiv)`
  border: 2px dashed #ddd;
  height: 50px;
`;
const StyledLogo = styled.img`
  max-width: 100px;
  min-height: 50px;
  max-height: 50px;
  margin: 0 auto;
  display: block;
  object-fit: contain;
  mix-blend-mode: multiply;
`;
const PaginationBtn = styled.button`
  all: unset;
  cursor: pointer;
`;