import React from 'react';
import useDebounce from 'lib/hooks/useDebounce';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { TextField } from '@zitcha/component-library';

type TextFieldDebouncedProps = {
  debounceMs?: number;
} & TextFieldProps;

export const TextFieldDebounced: React.FC<TextFieldDebouncedProps> = ({ debounceMs = 300, onChange, ...rest }) => {
  const debouncedChangeHandler = useDebounce((value) => {
    onChange && onChange(value);
  }, debounceMs);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    debouncedChangeHandler(target.value);
  };

  return <TextField onChange={handleChange} {...rest} />;
};