import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Badge, Card, Col, Row, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import {
  MPA_ONBOARDING_COMPLETED,
  MPA_ONBOARDING_FAILED,
  MPA_ONBOARDING_SUBMITTED,
  MPA_ONBOARDING_WAITING_FOR_SUPPPLIER,
  MPA_ONBOARDING_IN_PROGRESS,
} from '../../../lib/constants';
import {
  checkMPAEligibility,
  onboardMPASupplier,
  selectId,
  selectFacebookMPA,
  selectMPALastCheckedEligibilityTimestamp,
  selectMPAEligibilityRequestStatus,
  selectMPAOnboardingRequestErrors,
  selectMPAOnboardingRequestStatus,
} from '../organisationSettingsSlice';
import { Eligibility } from './FacebookMPA/Elegibility';
import { OnboardingStatus } from './FacebookMPA/OnboardingStatus';
import { OnboardingForm } from './FacebookMPA/OnboardingForm';
import styled from 'styled-components';
import { selectUser } from 'features/session/sessionSlice';
import { ThirdStep } from './FacebookMPA/ThirdStep';
import { CustomToggle } from './FacebookMPA/CustomToggle';
import { SecondStepAlert } from './FacebookMPA/SecondStepAlert';

const HeadDesc = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-aligned: left;
`;

const Container = styled.div`
  flex-grow: 1;
  font-size: 14px;

  .alert svg {
    margin-right: 8px;
  }

  .badge-not-eligible {
    color: #ffffff;
    background-color: #ef767a;
  }

  .badge-eligible {
    background-color: #87f5c0;
  }
`;

const StepTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  h5 {
    color: #000;
    padding-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const FacebookMPA = ({ controlId }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isRetailer = user.active_organisation.is_retailer;
  const supplierId = useSelector(selectId);
  const retailerId = user.active_organisation.id;
  const facebookMPA = useSelector(selectFacebookMPA);
  const isEligible = facebookMPA?.eligibility?.is_eligible;
  const notEligibleReason = facebookMPA?.eligibility?.reason_description;
  const lastCheckedEligibilityTimestamp = useSelector(selectMPALastCheckedEligibilityTimestamp);
  const eligibilityRequestStatus = useSelector(selectMPAEligibilityRequestStatus);
  const mpaOnboardingRequestStatus = useSelector(selectMPAOnboardingRequestStatus);
  const mpaOnboardingRequestErrors = useSelector(selectMPAOnboardingRequestErrors);
  const mpaOnboardingStatus = facebookMPA?.status || 'not-started';
  const mpaOnboardingUnsuccessfulReason = facebookMPA?.onboarding.error_user_msg;
  const mpaOnboardingSavedFormFields = facebookMPA?.settings || {};
  const [panelIndex, setPanelIndex] = useState(null);
  const handleClickCheckEligibility = () => {
    dispatch(checkMPAEligibility(supplierId));
  };

  const handleSubmitOnboardingForm = (formFields) => {
    dispatch(onboardMPASupplier({ supplierId, formFields }));
  };

  return (
    <section>
      <StyledAccordion className='mb-2'>
        <StyledCard>
          <CustomToggle eventKey={controlId} panelIndex={panelIndex} setPanelIndex={setPanelIndex}>
            Meta - Managed Partner Ads <Badge variant='new'>New</Badge>
          </CustomToggle>
          <Accordion.Collapse eventKey={controlId}>
            <StyledCardBody>
              <Container>
                <Row>
                  <Col md='8'>
                    <StepTitle>
                      <h5>Step 1: Check Eligibility</h5>
                    </StepTitle>
                    <HeadDesc>
                      Check if suppliers are eligible for Managed Partner Ads. This is determined based on their ad
                      spend on Facebook.
                    </HeadDesc>
                  </Col>
                </Row>
                <Row>
                  <Col md='8'>
                    <span style={{ display: 'flex', gap: '22px' }}>
                      <Eligibility
                        isChecking={eligibilityRequestStatus === 'loading'}
                        isEligible={isEligible}
                        lastStatusCheck={lastCheckedEligibilityTimestamp}
                        notEligibleReason={notEligibleReason}
                        onClickCheckEligibility={handleClickCheckEligibility}
                        enableRecheck={true}
                      />
                    </span>

                    <SecondStepAlert
                      isEligible={isEligible}
                      mpaOnboardingStatus={mpaOnboardingStatus}
                      mpaOnboardingUnsuccessfulReason={mpaOnboardingUnsuccessfulReason}
                    />
                  </Col>
                </Row>
              </Container>
              <Container>
                {isEligible && (
                  <>
                    <Row>
                      <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                      <Col md='8'>
                        <StepTitle>
                          <h5>Step 2: Onboard Supplier</h5>
                          <OnboardingStatus
                            status={
                              mpaOnboardingStatus === MPA_ONBOARDING_WAITING_FOR_SUPPPLIER
                                ? MPA_ONBOARDING_COMPLETED
                                : mpaOnboardingStatus
                            }
                            showLabel={false}
                          />
                        </StepTitle>

                        {![
                          MPA_ONBOARDING_COMPLETED,
                          MPA_ONBOARDING_SUBMITTED,
                          MPA_ONBOARDING_FAILED,
                          MPA_ONBOARDING_WAITING_FOR_SUPPPLIER,
                          MPA_ONBOARDING_IN_PROGRESS,
                        ].includes(mpaOnboardingStatus) && (
                          <WarningAlert warningText='Once submitted this section will no longer be editable.' />
                        )}
                        <HeadDesc>
                          {mpaOnboardingStatus === MPA_ONBOARDING_COMPLETED &&
                            'This information has been confirmed with Meta and is no longer editable.'}
                        </HeadDesc>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='8'>
                        <OnboardingForm
                          errors={mpaOnboardingRequestErrors?.errors}
                          onSubmit={handleSubmitOnboardingForm}
                          previousFormFields={mpaOnboardingSavedFormFields}
                          disabled={mpaOnboardingRequestStatus === 'loading'}
                          readOnly={[
                            MPA_ONBOARDING_SUBMITTED,
                            MPA_ONBOARDING_COMPLETED,
                            MPA_ONBOARDING_WAITING_FOR_SUPPPLIER,
                            MPA_ONBOARDING_IN_PROGRESS,
                          ].includes(mpaOnboardingStatus)}
                          onBoardingStatus={mpaOnboardingStatus}
                          supplierId={supplierId}
                        />
                      </Col>
                    </Row>
                    {(mpaOnboardingStatus === MPA_ONBOARDING_WAITING_FOR_SUPPPLIER ||
                      mpaOnboardingStatus === MPA_ONBOARDING_COMPLETED) && (
                      <ThirdStep
                        retailerId={retailerId}
                        supplierId={supplierId}
                        mpaOnboardingStatus={mpaOnboardingStatus}
                        isRetailer={isRetailer}
                        pageHander={mpaOnboardingSavedFormFields.page_handler || []}
                      />
                    )}
                  </>
                )}
              </Container>
            </StyledCardBody>
          </Accordion.Collapse>
        </StyledCard>
      </StyledAccordion>
    </section>
  );
};

FacebookMPA.propTypes = {
  controlId: PropTypes.string.isRequired,
};

const StyledAlert = styled(Alert)`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: #ffe787;
  font-size: 0.875rem;
  color: #00001e;
  font-weight: 600;
  margin-top: 1rem;
  max-width: fit-content;
  svg {
    color: #00001e;
  }
`;
const StyledCard = styled.div`
  .badge-new {
    background-color: #b5d0f7;
  }
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;
const StyledCardBody = styled(Card.Body)`
  overflow: visible !important;
  .card-body {
    overflow: auto;
  }
`;
const StyledAccordion = styled(Accordion)`
  overflow: visible !important;
`;

const WarningAlert = ({ warningText }) => {
  return (
    <StyledAlert variant='warning'>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <span>{warningText}</span>
      </span>
    </StyledAlert>
  );
};
WarningAlert.propTypes = {
  warningText: PropTypes.string.isRequired,
};
