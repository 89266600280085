import { useState, useEffect, useMemo } from 'react';
import { AdSet, AdSetStatusEnum } from 'v2/lib/api/ad-management/model';

export const useAdSetManagement = (adSetsData: Array<AdSet>) => {
    const [modifiedAdSets, updateModifiedAdSets] = useState<Array<AdSet>>([]);

    useEffect(() => {
        const filteredModifiedAdSets = modifiedAdSets.filter(modifiedAdSet => {
            const originalAdSet = adSetsData.find(adSet => adSet.id === modifiedAdSet.id);
            if (!originalAdSet) return true;
            return (
                modifiedAdSet.status !== originalAdSet.status ||
                modifiedAdSet?.bundleIds?.[0] !== originalAdSet?.bundleIds?.[0]
            );
        });

        if (filteredModifiedAdSets.length !== modifiedAdSets.length) {
            updateModifiedAdSets(filteredModifiedAdSets);
        }
    }, [adSetsData, modifiedAdSets]);

    const sortedAdSetsData = useMemo(() => sortAdSets([...adSetsData]), [adSetsData]);

    const EditClashRows = useMemo(() => {
        return sortedAdSetsData?.map((adSet: AdSet) => {
            const updatedAdSet = modifiedAdSets.find((updatedAdSet) => updatedAdSet.id === adSet.id);
            return {
                id: adSet.id,
                createdAt: adSet.createdAt,
                brand: adSet?.plan?.supplier?.name,
                status: updatedAdSet ? updatedAdSet.status : adSet?.status,
                bundleIds: updatedAdSet ? updatedAdSet.bundleIds : adSet?.bundleIds,
                retailerId: adSet?.plan?.owner?.id,
            };
        }) || [];
    }, [sortedAdSetsData, modifiedAdSets]);

    const ReviewPreviousRows = useMemo(() => {
        return sortedAdSetsData
            ?.filter((adSet: AdSet) => adSet.status === AdSetStatusEnum.clashed)
            .map((adSet: AdSet) => ({
                id: adSet.id,
                createdAt: adSet.createdAt,
                brand: adSet?.plan?.supplier?.name,
                status: adSet?.status,
                bundleIds: adSet?.bundleIds,
                retailerId: adSet?.plan?.owner?.id,
            })) || [];
    }, [sortedAdSetsData]);

    const ReviewAmendedRows = useMemo(() => {
        return modifiedAdSets?.map((adSet: AdSet) => {
            const originalAdSet = sortedAdSetsData.find((sortedAdSet) => sortedAdSet.id === adSet.id);
            return {
                id: adSet.id,
                createdAt: adSet.createdAt,
                brand: originalAdSet?.plan?.supplier?.name,
                status: adSet.status,
                bundleIds: adSet.bundleIds,
                retailerId: adSet?.plan?.owner?.id,
            };
        }) || [];
    }, [modifiedAdSets, sortedAdSetsData]);

    return {
        modifiedAdSets,
        updateModifiedAdSets,
        sortedAdSetsData,
        EditClashRows,
        ReviewPreviousRows,
        ReviewAmendedRows,
    };
};

function sortAdSets(adSetArray: Array<AdSet>) {
    return adSetArray?.sort((a: AdSet, b: AdSet) => {
        if (a.status === AdSetStatusEnum.clashed && b.status !== AdSetStatusEnum.clashed) return -1;
        if (b.status === AdSetStatusEnum.clashed && a.status !== AdSetStatusEnum.clashed) return 1;

        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);

        return dateA.getTime() - dateB.getTime();
    });
}