import { useDeleteRole } from 'v2/lib/api/access-management';
import { useQueryClient } from '@tanstack/react-query';
import { ROLE_MANAGEMENT_ROLES_QUERY_KEY } from 'v2/constants/accessManagementConstants';

interface UpdateRoleWithHandlerParams {
  roleId: number | null;
  handleClose?: () => void;
}

export const useDeleteRoleWithHandler = ({
  roleId,
  handleClose,
}: UpdateRoleWithHandlerParams) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteTeam,
    isError,
    isSuccess,
    error,
    isPending,
  } = useDeleteRole({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [ROLE_MANAGEMENT_ROLES_QUERY_KEY] });
      },
    }
  });

  const handleDeleteRole = async () => {
    if (!roleId) return;
    try {
      await deleteTeam({ roleId: roleId }).then(() => {
        handleClose?.();
      });
    } catch (error) {
      return error;
    }
  };

  return { isError, isSuccess, isPending, error, handleDeleteRole };
};
