import React from 'react';
import { Box, CircularProgress, Typography } from '@zitcha/component-library';

interface LoadingModalDataProps {
  isLoading: boolean;
  isError: boolean;
  subject?: string;
}

export const LoadingModalData: React.FC<LoadingModalDataProps> = ({
  isLoading,
  isError,
  subject = 'data'
}) => {
  if (isLoading)
    return (
      <Box display='flex' padding='2em' height={100} justifyContent='center' alignItems='center' gap={2}>
        <CircularProgress />
        <Typography variant='h6'>Loading...</Typography>
      </Box>
    );
  if (isError) {
    return (
      <Box display='flex' padding='2em' height={100} justifyContent='center' alignItems='center' gap={2}>
        <Typography variant='h6'>An error occurred while fetching {subject}</Typography>
      </Box>
    );
  }
  return null;
};