import React, { useEffect, useState } from 'react';
import { User, UsersList } from './UsersList';
import { Box, Typography } from '@zitcha/component-library';
import { Control, Controller } from 'react-hook-form';
import { useRoles } from 'v2/lib/hooks/useRoles';
import { useUsers } from 'v2/lib/hooks/useUsers';
import { FormValues } from './CreateTeam';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { InputMode } from './RenderModalInputs';
import { PairedInputsWrapper } from '../PairedInputsWrapper';
import { CustomAutocomplete } from '../components/CustomSingleAutocomplete';
interface UserRoleInputProps {
  control: Control<FormValues>;
  selectedUsers: Array<User>;
  setValue: (name: keyof FormValues, value: FormValues['users']) => void;
  mode?: InputMode;
}
export const UserRoleInput = ({ control, selectedUsers, setValue, mode = 'creating' }: UserRoleInputProps) => {
  const [user, setUser] = useState<User['user'] | null>(null);
  const [role, setRole] = useState<User['role'] | null>(null);
  const [userQuery, setUserQuery] = useState<string>('');
  const [roleQuery, setRoleQuery] = useState<string>('');

  const { users: usersOptions, isLoading: loadingUsers } = useUsers({ query: userQuery });
  const { roles, isLoading: loadingRoles } = useRoles({
    query: roleQuery,
  });
  const resetUser = () => {
    setUser(null);
    setRole(null);
  };
  const removeUser = (user: User) => {
    const newUsers = selectedUsers.filter((item) => !(item.user.id === user.user.id && item.role.id === user.role.id));
    setValue('users', newUsers);
  };

  const handleAddUser = ({ user, role }: User) => {
    const isPresent = selectedUsers.find((item) => item.user.name === user.name && item.role.name === role.name);
    if (isPresent) {
      resetUser();
      return;
    }
    setValue('users', [...selectedUsers, { user, role }]);
    resetUser();
  };

  const debouncedSetUserQuery = useDebounceSetter(setUserQuery, 500);
  const debouncedSetRoleQuery = useDebounceSetter(setRoleQuery, 500);

  useEffect(() => {
    if (user?.name && role?.name) {
      handleAddUser({ user, role });
    }
  }, [user, role]);

  return (
    <Box>
      <PairedInputsWrapper
        labels={['User', 'Role']}
        components={[
          <>
            <Controller
              name='users'
              control={control}
              render={({ field }) => (
                <CustomAutocomplete
                  {...field}
                  options={usersOptions || []}
                  loading={loadingUsers}
                  onInputChange={(value) => debouncedSetUserQuery(value)}
                  value={user}
                  onChange={(data) => {
                    if (!data) {
                      return;
                    }
                    const transformedData = {
                      id: String(data.id),
                      name: data.name,
                    };
                    setUser(transformedData);
                  }}
                  showAdornment={true}
                />
              )}
            />
            {mode === 'editing' ? (
              <Typography variant='caption'>You can manage a user&apos;s team(s) from the Users tab.</Typography>
            ) : null}
          </>,
          <Controller
            key='user-role-autocomplete'
            name='users'
            control={control}
            render={({ field }) => (
              <CustomAutocomplete
                {...field}
                options={roles || []}
                loading={loadingRoles}
                onInputChange={(value) => debouncedSetRoleQuery(value)}
                value={role}
                onChange={(data) => {
                  if (!data) {
                    return;
                  }
                  const transformedData = {
                    id: Number(data.id),
                    name: data.name,
                  };
                  setRole(transformedData);
                }}
                showAdornment={true}
              />
            )}
          />,
        ]}
      />
      <UsersList users={selectedUsers} onRemoveUser={removeUser} mode={mode} />
    </Box>
  );
};