import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import RegisterUserForm from '../features/users/RegisterUserForm';
import { getBrandingAssets, registerUser } from '../lib/api';
import Footer from './layout/Footer';
import styles from './login.module.scss';
import { registrationSuccess } from '../features/session/sessionSlice';

const Register = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formRef = useRef(null);

  const [form, setForm] = useState({
    token: params.get('token') ?? '',
    first_name: params.get('first_name') ?? '',
    last_name: params.get('last_name') ?? '',
    jobtitle: params.get('jobtitle') ?? '',
    email: params.get('email') ?? '',
    password: '',
    password_confirmation: '',
  });

  const [errors, setErrors] = useState({});
  const [theme, setTheme] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.has('id')) {
      getBrandingAssets(params.get('id')).then((resp) => {
        if (resp?.data?.data) {
          setTheme(resp.data.data);
        }
      });
    }
  }, []);

  const status = useSelector((state) => state.session.status);
  const registerState = useSelector((state) => state.session.registration);
  if (status === 'authenticated') {
    return <Navigate to='/' replace />;
  }
  if (registerState?.outcome === 'success') {
    return <Navigate to='/' replace />;
  }

  const onSubmit = (e: Event) => {
    e.preventDefault();
    // Submit registration
    registerUser(
      form.first_name,
      form.last_name,
      form.jobtitle,
      form.email,
      form.password,
      form.password_confirmation,
      form.token
    )
      .then(() => {
        setErrors({
          _success: ['Successfully Registered'],
        });
        dispatch(registrationSuccess());
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setErrors({
            _error: ['Invalild registration code: please request a new invite.'],
          });
          const form = formRef.current;
          form.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        } else if (err.response) {
          const respError = err.response.data?.errors;
          setErrors(respError);

          const form = formRef.current;
          for (let i = 0; i < form.length; i++) {
            if (respError[form[i].id]?.length > 0) {
              form[i].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
              break;
            }
          }
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Register - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <main className='py-4'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8}>
              <Card>
                <Card.Body>
                  <div className={styles.header}>
                    {theme?.logo?.url && <img src={theme.logo.url} alt='' />}
                    <h4 className='text-center my-4'>Register</h4>
                  </div>
                  <RegisterUserForm
                    ref={formRef}
                    form={form}
                    onChange={(newForm) => setForm(newForm)}
                    errors={errors}
                    onSubmit={onSubmit}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default Register;