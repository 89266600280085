import { useGetTeams } from 'v2/lib/api/access-management';

export const useGetTeamsSlim = ({ query }: { query: string }) => {
  const { data, isError, isLoading } = useGetTeams(
    {
      q: query,
      perPage: 10,
    },
    {
      query: {
        select: (data) => {
          return data.data.map((team) => {
            return {
              id: team.id,
              name: team.name,
            };
          });
        },
      },
    }
  );
  return {
    teams: data,
    loading: isLoading,
    isError,
  };
};
