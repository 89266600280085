import { Box, Chip, Typography } from '@zitcha/component-library';
import React, { useEffect, useState } from 'react';
import type { UseFormRegister, UseFormWatch } from 'react-hook-form';
import type { ResponseUser } from 'v2/lib/api/access-management';
import { PairedInputsWrapper } from '../PairedInputsWrapper';
import { RoleAutocomplete } from './RoleAutocomplete';
import { TeamAutocomplete } from './TeamAutocomplete';
import type { Mode } from './UsersModal';

type TeamOrRole = {
  id: number;
  name: string;
} | null;

interface RoleTeamInputProps {
  value: ResponseUser['teamsRoles'];
  teamRolesSetter: (name: keyof ResponseUser, value: ResponseUser['teamsRoles']) => void;
  watch: UseFormWatch<Partial<ResponseUser>>;
  registerToform: UseFormRegister<Partial<ResponseUser>>;
  mode?: Mode;
  error?: string;
}

export const RoleTeamInput = ({
  value,
  teamRolesSetter,
  watch,
  registerToform,
  mode = 'view',
  error,
}: RoleTeamInputProps) => {
  const [team, setTeam] = useState<TeamOrRole>(null);
  const [role, setRole] = useState<TeamOrRole>(null);

  watch('teamsRoles'); //<-  this is to help rerender this input when the form values of teamsRole changes

  useEffect(() => {
    registerToform('teamsRoles');
  }, []);

  const handleRemoveTeamRole = (teamRole: ResponseUser['teamsRoles'][0], teamRoles: ResponseUser['teamsRoles']) => {
    const newTeamsRoles = teamRoles.filter(
      (item) => !(item.role.id === teamRole.role.id && item.team.id === teamRole.team.id)
    );
    teamRolesSetter('teamsRoles', [...newTeamsRoles]);
  };
  const resetRoleTeam = () => {
    setRole(null);
    setTeam(null);
  };

  const handleAddTeamRole = (teamRoles: ResponseUser['teamsRoles']) => {
    if (role && team) {
      const isPresent = teamRoles.find((item) => item.role.id === role.id && item.team.id === team.id);
      if (isPresent) {
        resetRoleTeam();
        return;
      }
      teamRolesSetter('teamsRoles', [
        ...teamRoles,
        {
          team: team,
          role: role,
        },
      ]);
    }
  };

  useEffect(() => {
    if (role && team) {
      handleAddTeamRole(value);
      resetRoleTeam();
    }
  }, [role, team]);

  return (
    <Box>
      {mode === 'view' ? (
        <Box display='flex' gap={0.5} flexWrap='wrap' mt={1}>
          {value.map((item, index) => (
            <Chip
              key={index}
              label={`${item?.role?.name}, ${item?.team?.name}`}
              size='small'
              variant='filled'
              color='primary'
            />
          ))}
        </Box>
      ) : (
        <Box display='flex' flexDirection='column'>
          <PairedInputsWrapper
            labels={['Role', 'Team']}
            components={[
              <RoleAutocomplete
                key='role-autocomplete'
                value={role}
                onChange={(user) => {
                  setRole(user);
                }}
              />,
              <TeamAutocomplete
                key='team-autocomplete'
                value={team}
                onChange={(team) => {
                  setTeam(team);
                }}
              />,
            ]}
          />
          {error && value.length === 0 && (
            <Box mt={1} sx={{ color: '#d32f2f' }}>
              <Typography variant='caption'>{error}</Typography>
            </Box>
          )}
          <Box display='flex' gap={0.5} flexWrap='wrap' mt={1}>
            {value.map((item) => (
              <Chip
                key={`${item.role.id}-${item.team.id}`}
                label={`${item?.role?.name}, ${item?.team?.name}`}
                size='small'
                variant='filled'
                color='primary'
                onDelete={() => {
                  handleRemoveTeamRole(item, value);
                }}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};