import React, { useState } from 'react';
import { Ad, Plan, useCreateAdSetFromBundle } from 'v2/lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import NewAdSetModal from './NewAdSetModal';
import { AdSetModal } from 'v2/components/AdSetModal/AdSetModal';

interface NewAdSetModalWrapperProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewAdSetModalWrapper = ({ isOpen, onClose }: NewAdSetModalWrapperProps) => {
  const [isAdSetEditModalOpen, setIsAdSetEditModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const adAdSet = useCreateAdSetFromBundle();
  const organisation = useUserOrganisation();

  const handlePlanChange = (newValue: Plan | null) => {
    setSelectedPlan(newValue);
  };

  const handleSaveAdSet = async (adSet: any) => {
    await adAdSet.mutateAsync({
      planId: selectedPlan?.id ?? '',
      bundleId: adSet.bundleIds?.[0],
      data: {
        ads: adSet.ads?.map((ad: Ad) => ({ name: ad.name ?? '', fields: ad.fields ?? [] })),
      },
    });
    onClose();
    setIsAdSetEditModalOpen(false);
  };

  return (
    <>
      <NewAdSetModal
        isOpen={isOpen}
        onClose={onClose}
        selectedPlan={selectedPlan}
        onPlanChange={handlePlanChange}
        onEditAdSet={() => setIsAdSetEditModalOpen(true)}
        organisation={organisation}
      />
      <AdSetModal
        retailerId={organisation.is_retailer ? organisation.id : selectedPlan?.owner?.id}
        isOpen={isAdSetEditModalOpen}
        isReadOnly={false}
        adSet={{}}
        onClose={() => setIsAdSetEditModalOpen(false)}
        saveHandler={handleSaveAdSet}
      />
    </>
  );
};

export default NewAdSetModalWrapper;
