import React from 'react';
import {
  Ad,
  CalendarPeriod,
  CreateDiscountRequestType,
  Location,
  useGetCalendarPeriods,
  useGetLocations,
} from '../../lib/api/ad-management';
import {
  Box,
  CircularProgress,
  DeleteIcon,
  EditIcon,
  GridActionsCellItem,
  GridRenderCellParams,
  TextField,
  Link,
} from '@zitcha/component-library';
import { Autocomplete, AutocompleteRenderInputParams, Typography } from '@mui/material'; //todo export AutocompleteRenderInputParams and isOptionEqualToValue from zitcha/component-library
import { formatDisplayDate } from 'helpers/DateHelpers';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';
import { MediaSpace, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { AdSetData, PlanData } from './PlanContext';
import { AdSetBundleWrapper } from './AdSetBundleWrapper';
import { PlacementAutocomplete } from 'v2/components/PlacementList/PlacementAutocomplete';
import { EllipsisTypography } from './TableCellRenderers';
import { useNavigate } from 'react-router-dom';
import { ViewMode } from './PlanPage/ViewModeType';

export interface DiscountType {
  type: CreateDiscountRequestType;
  /** The discount value, which can be positive or negative. Round to the nearest 3 decimal places. */
  value: number;
}

export const CenteredTableCellContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display='flex' alignItems='center' height='100%'>
      {children}
    </Box>
  );
};
export const ScheduleCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  error = null,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
  error?: string | null;
} & GridRenderCellParams) => {
  const { data: calendarPeriodsData, isLoading: calendarPeriodsIsLoading } = useGetCalendarPeriods({
    organisation_id: retailerId,
  });
  const { row } = props;
  let value = row.schedule;

  if (value && Object.keys(value).length === 1 && value.id) {
    value = calendarPeriodsData?.data.find((period) => period.id === value.id);
  }

  const handleValueChange = (newValue: CalendarPeriod | undefined) => {
    updateAdSet({ ...row, schedule: newValue });
  };

  return (
    <CenteredTableCellContainer>
      <Autocomplete
        size='medium'
        fullWidth
        getOptionLabel={(data) =>
          `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
        }
        renderOption={(props, data) => (
          <li {...props} key={data.id}>
            {`${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} variant='standard' error={!!error} helperText={error ?? ''} />
        )}
        value={value ?? null}
        options={calendarPeriodsData?.data ?? []}
        loading={calendarPeriodsIsLoading}
        onChange={(_, newValue) => handleValueChange(newValue)}
        readOnly={readOnly}
      />
    </CenteredTableCellContainer>
  );
};

export const MediaSpaceCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  error = null,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
  error?: string | null;
} & GridRenderCellParams) => {
  const { row } = props;
  let value = row.mediaSpace;

  const { data: mediaTypesData, isLoading: isLoadingMediaTypes } = useGetMediaSpaces({ organisation_id: retailerId });

  if (value && Object.keys(value).length === 1 && value.id) {
    value = mediaTypesData?.data.find((mediaType) => mediaType.id === value.id);
  }

  const handleValueChange = (newValue: MediaSpace | undefined) => {
    updateAdSet({ ...row, mediaSpace: newValue, placement: null });
  };

  return (
    <CenteredTableCellContainer>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} variant='standard' error={!!error} helperText={error ?? ''} />
        )}
        value={value ?? null}
        options={mediaTypesData?.data ?? []}
        loading={isLoadingMediaTypes}
        onChange={(_, newValue) => handleValueChange(newValue)}
        readOnly={readOnly}
      />
    </CenteredTableCellContainer>
  );
};

export const PlacementCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  disabled = false,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
  disabled?: boolean;
} & GridRenderCellParams) => {
  const { row } = props;
  let value = row.placement;
  const needToFindLocation = value && Object.keys(value).length === 2 && value.id && value.name;

  // TODO: somehow we need to get location by id, probably a new endpoint?
  const { data: locationsData } = useGetLocations(
    { organisation_id: retailerId },
    { query: { enabled: !!needToFindLocation } }
  );

  if (needToFindLocation) {
    value = locationsData?.data.find((location: Location) => location?.id === value.id);
  }

  const handleValueChange = (newValue: Location | undefined) => updateAdSet({ ...row, placement: newValue });

  const handleChange = (location: Location | undefined) => handleValueChange(location);

  return (
    <CenteredTableCellContainer>
      <PlacementAutocomplete
        value={value}
        onChange={handleChange}
        fullWidth={true}
        retailerId={retailerId}
        mediaSpaceId={row?.mediaSpace?.id}
        disabled={readOnly || disabled}
        textFieldProps={{
          variant: 'standard',
        }}
      />
    </CenteredTableCellContainer>
  );
};

// Rate
export const RateCell = ({ retailerId, ...props }: GridRenderCellParams & { retailerId?: string }) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price || '-';
  const effectiveRetailerId =
    retailerId ||
    props?.row?.plan?.retailerId ||
    props?.row?.plan?.owner?.id ||
    props?.row?.adSet?.plan?.owner?.id ||
    '';
  return (
    <CenteredTableCellContainer>
      <RenderCurrency amount={rate} organisationId={effectiveRetailerId} showDecimals={false} />
    </CenteredTableCellContainer>
  );
};

const calculateDiscountValue = (rate: number, discountType: string, discountValue: number): number => {
  switch (discountType) {
    case CreateDiscountRequestType.percentage:
      return rate * (discountValue / 100);
    case CreateDiscountRequestType.fixed:
      return discountValue;
    default:
      return rate;
  }
};

export const calculateBudget = (rate?: number | null, discount?: DiscountType | null): number => {
  if (!rate) {
    return 0;
  }
  const discountValue = discount?.value || 0;
  const discountType = discount?.type || CreateDiscountRequestType.percentage;
  const discountedValue = discountValue !== 0 ? calculateDiscountValue(rate, discountType, discountValue) : 0;
  return rate - discountedValue;
};

// Budget
export const BudgetCell = ({
  retailerId,
  ...props
}: GridRenderCellParams<AdSetData, number> & { retailerId?: string }) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price;
  const effectiveRetailerId =
    retailerId ||
    props?.row?.plan?.retailerId ||
    props?.row?.plan?.owner?.id ||
    props?.row?.adSet?.plan?.owner?.id ||
    '';
  return (
    <CenteredTableCellContainer>
      <RenderCurrency
        amount={calculateBudget(rate, props?.row?.discount)}
        organisationId={effectiveRetailerId}
        showDecimals={false}
      />
    </CenteredTableCellContainer>
  );
};

export const PlanName = (params: GridRenderCellParams) => {
  const navigate = useNavigate();
  const planId = params.row?.planId || '';

  return (
    <CenteredTableCellContainer>
      <Link
        color='inherit'
        underline='hover'
        onClick={() => navigate(`/plans-ad-sets/${planId}`, { state: { viewMode: ViewMode.REVIEWING } })}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: '#000',
          },
        }}
      >
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
          {params.value}
        </Typography>
      </Link>
    </CenteredTableCellContainer>
  );
};

// discount
export const DiscountCell = ({
  retailerId,
  ...props
}: GridRenderCellParams<AdSetData, number> & { retailerId?: string }) => {
  const discountValue = props?.row?.discount?.value || 0;
  const discountType = props?.row?.discount?.type || CreateDiscountRequestType.percentage;
  const effectiveRetailerId =
    retailerId ||
    props?.row?.plan?.retailerId ||
    props?.row?.plan?.owner?.id ||
    props?.row?.adSet?.plan?.owner?.id ||
    '';

  return (
    <CenteredTableCellContainer>
      {discountType === CreateDiscountRequestType.fixed ? (
        <RenderCurrency amount={discountValue} organisationId={effectiveRetailerId} />
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            minWidth: '80px',
            textAlign: 'left',
          }}
        >
          <Typography variant='subtitle1'>% {discountValue}</Typography>
        </Box>
      )}
    </CenteredTableCellContainer>
  );
};

// list of all selected product SKUs across all ads
// todo we should check for fields of type ProductSelectorComponent instead of fields.SKU
export const SkuCodesCell = ({ ...params }: GridRenderCellParams) => {
  const ads = params.row?.adSet?.ads || params?.row?.ads || [];
  // Extract SKU codes from ads into an array
  const skuCodes: Array<string> = ads.reduce((acc: Array<string>, ad: Ad) => {
    if (Array.isArray(ad?.fields?.SKU)) {
      const adSkuCodes = ad?.fields?.SKU?.map((sku: { id: string }) => sku.id) || [];
      return acc.concat(adSkuCodes);
    }
    return acc;
  }, []);

  return (
    <CenteredTableCellContainer>
      <EllipsisTypography>
        {skuCodes.map((code) => (
          <React.Fragment key={code}>
            {code}
            <br />
          </React.Fragment>
        ))}
      </EllipsisTypography>
    </CenteredTableCellContainer>
  );
};

export const ActionCell = ({
  params,
  plan,
  setEditAdSet,
  removeAdSet,
}: {
  params: GridRenderCellParams<AdSetData>;
  plan: PlanData;
  setEditAdSet: React.Dispatch<React.SetStateAction<AdSetData | undefined>>;
  removeAdSet: (id: string) => void;
}) => (
  <AdSetBundleWrapper adSet={params.row} retailerId={plan.retailerId}>
    {(isLoading) =>
      isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            aria-label='edit ad set'
            className='textPrimary'
            onClick={() => {
              setEditAdSet(params.row);
            }}
            color='inherit'
          />
          <GridActionsCellItem
            key='delete'
            icon={<DeleteIcon />}
            label='Delete'
            aria-label='delete ad set'
            onClick={() => removeAdSet(params.row.id)}
            color='inherit'
          />
        </>
      )
    }
  </AdSetBundleWrapper>
);
