import React from 'react';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { ResponseUser } from 'v2/lib/api/access-management';
import { RoleTeamInput } from './RoleTeamInput';
import { Box, InputLabel, TextField, Typography } from '@zitcha/component-library';
import { Mode } from './UsersModal';

interface LabelValuePairProps {
  field: keyof ResponseUser;
  value: string | ResponseUser['teamsRoles'] | undefined;
  registerToform: UseFormRegister<Partial<ResponseUser>>;
  mode?: Mode;
  teamRolesSetter: (name: keyof ResponseUser, value: ResponseUser['teamsRoles']) => void;
  watch: UseFormWatch<Partial<ResponseUser>>;
  errors?: FieldErrors<Partial<ResponseUser>>;
}

export const LabelValuePair = ({
  field,
  value,
  registerToform,
  mode = 'view',
  teamRolesSetter,
  watch,
  errors,
}: LabelValuePairProps) => {
  const isArrayValue = Array.isArray(value);
  const getInputLabel = (field: keyof ResponseUser) => {
    switch (field) {
      case 'firstName':
        return 'First name';
      case 'lastName':
        return 'Last name';
      case 'email':
        return 'Email';
      case 'jobTitle':
        return 'Job title';
      case 'teamsRoles':
        return 'Roles & teams';
      default:
        return field;
    }
  };

  return (
    <Box>
      {isArrayValue ? (
        <Box display='flex' gap={1} flexDirection='column'>
          {mode === 'view' ? <InputLabel size='small'>{getInputLabel(field)}</InputLabel> : null}
          <RoleTeamInput
            value={value as ResponseUser['teamsRoles']}
            registerToform={registerToform}
            mode={mode}
            teamRolesSetter={teamRolesSetter}
            watch={watch}
            error={errors?.['teams' as keyof ResponseUser]?.message}
          />
        </Box>
      ) : (
        <Box display='flex' gap={1} flexDirection='column'>
          <InputLabel size='small'>{getInputLabel(field)}</InputLabel>
          {mode === 'view' ? (
            <Box
              sx={{
                color: 'text.primary',
                padding: '4px 0 5px',
              }}
            >
              <Typography variant='body1'>{value || 'N/A'}</Typography>
            </Box>
          ) : (
            <TextField
              {...registerToform(field)}
              variant='standard'
              fullWidth
              data-testid={field}
              error={!!errors?.[field]}
              helperText={errors?.[field]?.message || ''}
            />
          )}
        </Box>
      )}
    </Box>
  );
};