import { useState } from 'react';
import { useSearchTeams } from 'v2/lib/api/access-management';
import { PerPageEnum, RequestSearchTeams } from 'v2/lib/api/access-management/model';
import { TEAM_MANAGEMENT_TEAMS_QUERY_KEY } from 'v2/constants/accessManagementConstants';

export const useFetchTeams = (initialTeamsSearch: RequestSearchTeams = {}) => {
  const [teamsSearch, setTeamsSearch] = useState(initialTeamsSearch);
  const [teamsPage, setTeamsPage] = useState<number>(1);
  const [teamsPerPage, setTeamsPerPage] = useState<PerPageEnum>(PerPageEnum.NUMBER_10);

  const { data, isLoading, isError } = useSearchTeams(
    {
      ...teamsSearch,
      page: teamsPage,
      perPage: teamsPerPage,
    },
    {
      query: {
        queryKey: [TEAM_MANAGEMENT_TEAMS_QUERY_KEY, { ...teamsSearch, page: teamsPage, perPage: teamsPerPage }],
      },
    }
  );

  const changeTeamsPagination = (newPage: number, newPerPage: PerPageEnum) => {
    if (newPage > 0 && newPage !== teamsPage) {
      setTeamsPage(newPage);
    }

    if (newPerPage > 0 && newPerPage !== teamsPerPage) {
      setTeamsPerPage(newPerPage);
    }
  };

  return {
    teams: data?.data ?? [],
    teamsPagination: {
      currentPage: data?.meta?.current_page,
      from: data?.meta?.from,
      lastPage: data?.meta?.last_page,
      perPage: data?.meta?.per_page,
      to: data?.meta?.to,
      total: data?.meta?.total,
    },
    changeTeamsPagination: changeTeamsPagination,
    isLoadingTeams: isLoading,
    teamsError: isError,
    setTeamsSearch,
  };
};
