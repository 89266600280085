import React from 'react';
import { Box, Typography } from '@zitcha/component-library';
import { LoadingModalData } from '../components/LoadingModalData';
import { ChipList } from 'v2/components/ChipList';
import { useGetRoleFormData } from './hooks/useGetRoleFormData';

interface ViewRoleProps {
  roleId: number;
}

export const ViewRole: React.FC<ViewRoleProps> = ({
  roleId
}) => {
  const {
    name,
    description,
    permissionSets,
    isLoading,
    isError,
  } = useGetRoleFormData({ roleId });

  if (isLoading || isError) {
    return <LoadingModalData isLoading={isLoading} isError={isError} subject={'role data'} />;
  }

  return (
    <Box display='flex' flexDirection='column' gap='2rem'>
      <Box>
        <Typography variant='caption'>Role name</Typography>
        <ScopeValue value={name || ''} />
      </Box>
      <Box>
        <Typography variant='caption'>Role description</Typography>
        <ScopeValue value={description || ''} />
      </Box>
        <Box>
          <Typography variant='caption'>{permissionSets?.length} Permission Sets</Typography>
          <ChipList list={permissionSets} keyId='id' labelId='name' />
        </Box>
    </Box>
  );
}

const ScopeValue = ({ value }: { value: string }) => {
  return (
    <Box sx={{ fontWeight: 400, color: 'rgba(0, 0, 0, 0.87)' }}>
      <Typography variant='body2'>{value}</Typography>
    </Box>
  );
};