import React from 'react';
import { useRoles } from 'v2/lib/hooks/useRoles';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { CustomAutocomplete } from '../components/CustomSingleAutocomplete';

type Role = {
  id: number;
  name: string;
} | null;

export const RoleAutocomplete = ({ value, onChange }: { value: Role; onChange: (value: Role) => void }) => {
  const [rolesQuery, setRolesQuery] = React.useState('');
  const { roles, isLoading } = useRoles({ query: rolesQuery });
  const debounceSetRolesQuery = useDebounceSetter(setRolesQuery, 500);
  return (
    <CustomAutocomplete
      options={roles || []}
      loading={isLoading}
      onInputChange={(value) => debounceSetRolesQuery(value)}
      value={value}
      onChange={(data: Role) => onChange(data)}
      showAdornment={true}
    />
  );
};
