export const USER_MANAGEMENT = 'user:management';
export const INVITE_SUPPLIERS = 'user:invite_suppliers';
export const INVITE_EMPLOYEE = 'user:invite_employee';
export const BULK_INVITE = 'user:bulk_invite';
export const CREATE_ORDERS = 'order:create';
export const PUBLISH_ORDERS = 'order:publish';
export const APPROVE_OR_REJECT_ORDERS = 'order:approve_or_reject';
export const VIEW_ALL_ORDERS = 'order:view_all';
export const ORDER_REQUEST_REVIEW = 'order:request_review';
export const ORDER_ARCHIVE = 'order:archive';
export const EDIT_PENDING_ORDERS = 'order:edit_pending';
export const EDIT_APPROVED_ORDERS = 'order:edit_approved';
export const VIEW_ORDER_METRICS = 'order:view_metrics';

export const PLAN_VIEW = 'plan:view';
export const PLAN_CREATE = 'plan:create';
export const PLAN_EDIT = 'plan:edit';
export const PLAN_REVIEW = 'plan:review';
export const PLAN_PROPOSE = 'plan:propose';
export const PLAN_PUBLISH = 'plan:publish';
export const PLAN_ARCHIVE = 'plan:archive';
export const PLAN_METRICS = 'plan:metrics';
export const PLAN_ADSET_REVIEW = 'plan.adset:review';

export const DISCOUNT_VIEW = 'discount:view';
export const DISCOUNT_UPDATE = 'discount:update';
export const DISCOUNT_DELETE = 'discount:delete';
export const DISCOUNT_CREATE = 'discount:create';

export const CHANNELS_MANAGEMENT_FACEBOOK = 'channel:facebook_management';
export const CHANNELS_MANAGEMENT_GOOGLE = 'channel:google_management';
export const OWNED_CHANNELS_MANAGEMENT_WEB = 'channel:owned_channels_management_web';
export const ACCESS_REPORTS = 'report:access';
export const MANAGE_SUPPLIER_SETTINGS = 'setting:manage_supplier_settings';
export const MANAGE_ORGANISATION_SETTINGS = 'setting:manage_organisation_settings';
export const BUILD_PACKAGES = 'package:build';
export const WALLET_MANAGEMENT = 'wallet:management';
export const WALLET_VIEW = 'wallet:view';
export const ROLE_VIEW = 'role:view';
export const ROLE_EDIT = 'role:edit';
export const ROLE_DELETE = 'role:delete';
export const TEAM_VIEW = 'team:view';
export const TEAM_EDIT = 'team:edit';
export const TEAM_DELETE = 'team:delete';