import React from 'react';
import { CheckIcon, EditIcon, GridRenderCellParams, IconButton } from '@zitcha/component-library';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AdSetStatusEnum } from 'v2/lib/api/ad-management';
import { ClashManagementModalMode } from '../../types/ClashManagementModalMode';
import { EditAdSetBundleModal } from 'v2/components/EditAdSetBundleModal/EditAdSetBundleModal';
import { useClashManagementContext } from '../../context/ClashManagementContext';

const ManageClashActionButtons = (props: GridRenderCellParams<any, string>) => {
  const { state, actions } = useClashManagementContext();
  const { row } = props;

  if (row.status !== AdSetStatusEnum.clashed || state.viewMode === ClashManagementModalMode.REVIEW) {
    return null;
  }

  const adSetIsModified = state.modifiedAdSets.some((adSet) => adSet.id === row.id);
  const isRowApprovable = state.availableApprovals > 0;

  return (
    <>
      <IconButton onClick={() => actions.selectAdSetForEditing(row)} aria-label='Edit plan'>
        <EditIcon className='twd-text-gray-500' />
      </IconButton>

      <IconButton
        disabled={!isRowApprovable}
        onClick={() => actions.approveClashedAdSet(row.id)}
        aria-label='Approve plan'
      >
        {adSetIsModified ? (
          <CheckCircleIcon className='twd-text-green-500' />
        ) : (
          <CheckIcon className={isRowApprovable ? 'twd-text-green-500' : ''} />
        )}
      </IconButton>
      {state.adSetBeingEdited && (
        <EditAdSetBundleModal
          isOpen={!!state.adSetBeingEdited}
          adSet={state.adSetBeingEdited}
          onClose={() => actions.selectAdSetForEditing(undefined)}
          saveHandler={actions.saveEditedAdSet}
          retailerId={state.retailerId}
        />
      )}
    </>
  );
};

export default ManageClashActionButtons;