import { connect } from 'react-redux';
import GoogleFeed from '../components/Feed/GoogleFeed';
import { selectReadOnly } from '../orderFormSlice';

const mapStateToProps = (state, ownProps) => {
  const availableProductSets = ownProps?.productSets || [];
  const productSets = availableProductSets.filter((set) => set.platform === 'google');

  return {
    controlId: 'google-catalogs',
    required: false,
    readOnly: selectReadOnly(state),
    productSets: productSets,
  };
};

export default connect(mapStateToProps)(GoogleFeed);