import React, { useMemo } from 'react';
import { Box, GridRenderCellParams } from '@zitcha/component-library';
import { AdSetStatusEnum, Location } from 'v2/lib/api/ad-management';
import { snakeCaseToTitleCase } from 'lib/strings';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';

const PlacementCell = ({
  ...props
}: GridRenderCellParams<any, string> & { retailerId: string }) => {
  const { row } = props;
  const { data: bundle, isLoading } = useSafeGetBundle(row.bundleIds?.[0]);

  const isClashed = row.status === AdSetStatusEnum.clashed;

  const location: Location | undefined = useMemo(() => bundle?.data?.locations?.[0], [bundle]);
  const textValue = location
    ? snakeCaseToTitleCase(location.locationType?.name || '') + ' - ' + snakeCaseToTitleCase(location.name || '')
    : '';

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box
      sx={{
        backgroundColor: isClashed ? '#FDEDED' : '',
        width: '100%',
        height: '100%',
        paddingLeft: '12px',
      }}
    >
      <p>{textValue}</p>
    </Box>
  );
};

export default PlacementCell;