import { type GetOrderPermissionsParams, useGetOrderPermissions } from '../api/access-management';

export const useOrderPermissions = (params: GetOrderPermissionsParams) => {
  const { data } = useGetOrderPermissions(params, { query: { enabled: !!params.organisationId }});

  const userOrderPermissions: Array<string> = data?.data?.permissions || [];

  const hasOrderPermission = (permission: string): boolean => {
    return userOrderPermissions.includes(permission);
  }

  const hasAllOrderPermissions = (permissions: Array<string>): boolean => {
    return permissions.every((permission) => hasOrderPermission(permission));
  }

  const hasAnyOrderPermissions = (permissions: Array<string>): boolean => {
    return permissions.some((permission) => hasOrderPermission(permission));
  }

  return { hasOrderPermission, hasAllOrderPermissions, hasAnyOrderPermissions };
};