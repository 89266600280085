import { PerPageEnum, ResponseRoleList, useGetRoles } from 'v2/lib/api/access-management';
import { useMemo } from 'react';

export const useRoles = ({ query }: { query: string }) => {
  const {
    data: roles,
    isLoading,
    isError,
    error,
  } = useGetRoles({
    q: query,
    page: 1,
    perPage: PerPageEnum.NUMBER_25,
  });

  const transformedData = (data: ResponseRoleList | undefined) => {
    return data?.data?.map((role) => ({
      id: role.id,
      name: role.name,
    }));
  };
  const transformed = useMemo(() => {
    return transformedData(roles);
  }, [roles?.data]);

  return {
    roles: transformed,
    isLoading,
    isError,
    error,
  };
};
