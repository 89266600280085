import React from "react";
import { BaseAutocomplete, BaseAutocompleteProps } from "./BaseAutocomplete";

interface FilterAutocompleteProps extends BaseAutocompleteProps {
  labelText: string;
  placeholderText: string;
}

export const FilterAutocomplete: React.FC<FilterAutocompleteProps> = ({
  labelText,
  placeholderText,
  selectedOptions,
  autoCompleteRenderInputParamsOverride = {},
  ...rest
}) => {
  const autoCompleteRenderInputParamsOverrideExtended = {
    ...autoCompleteRenderInputParamsOverride,
    label: labelText,
    placeholder: selectedOptions.length ? '' : placeholderText,
    variant: 'outlined',
  }

  return (
    <BaseAutocomplete
      autoCompleteRenderInputParamsOverride={autoCompleteRenderInputParamsOverrideExtended}
      selectedOptions={selectedOptions}
      {...rest}
    />
  )
}
