import React from 'react';
import { useGetTeamsSlim } from '../TeamManagement/hooks/useGetTeams';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { CustomAutocomplete } from '../components/CustomSingleAutocomplete';
type Team = {
  id: number;
  name: string;
} | null;
export const TeamAutocomplete = ({ value, onChange }: { value: Team; onChange: (value: Team) => void }) => {
  const [teamsQuery, setTeamsQuery] = React.useState('');
  const { teams, loading } = useGetTeamsSlim({ query: teamsQuery });
  const debounceSetTeamsQuery = useDebounceSetter(setTeamsQuery, 500);

  return (
    <CustomAutocomplete
      options={teams || []}
      loading={loading}
      onInputChange={(value) => debounceSetTeamsQuery(value)}
      value={value}
      onChange={(data: Team) => onChange(data)}
      showAdornment={true}
    />
  );
};
