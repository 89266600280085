import React, { useMemo, useState } from 'react';
import type { GridColDef, GridPaginationModel, GridRenderCellParams } from '@zitcha/component-library';
import { formatDisplayDate } from 'helpers/DateHelpers';
import moment from 'moment';
import { useGetCalendarPeriods, PerPageEnum } from 'v2/lib/api/ad-management';
import type { CalendarPeriod } from 'v2/lib/api/ad-management';
import { useGetInventoryReport } from 'v2/lib/api/inventory';
import { RenderInventoryCell } from '../cellrenderers/RenderInventoryCell';
import { generateRows } from '../utils/generateRows';
import { isSpaceUnavailable } from '../utils/isSpaceUnavailable';
import { useInventoryOrgId } from './useInventoryOrgId';

const CALENDAR_PERIODS_KEY = 'calendarPeriods';
const INVENTORY_REPORT_KEY = 'inventoryReport';

export const useInventoryBundles = () => {
  const orgId = useInventoryOrgId();
  const [calendarPeriodPage, setCalendarPeriodPage] = useState(1);
  const [inventoryReportPage, setInventoryReportPage] = useState(1);
  const [inventoryReportPerPage, changeInventoryReportPerPage] = useState<PerPageEnum>(5);
  const [filters, setFilters] = useState<
    Partial<{
      placementId: string;
      mediaTypeId: string;
      walletId: string;
      schedule: Array<CalendarPeriod>;
      retailerId: string;
    }>
  >({});

  const orgisationId = filters.retailerId || orgId;

  //get available calendar periods for the current organisation only 5 at a time
  const { data: calendarPeriodsData, isLoading: loadingCalendarPeriods } = useGetCalendarPeriods(
    {
      organisation_id: orgisationId,
      per_page: PerPageEnum.NUMBER_5,
      page: calendarPeriodPage,
    },
    {
      query: {
        queryKey: [CALENDAR_PERIODS_KEY, { organisation_id: orgisationId, page: calendarPeriodPage }],
      },
    }
  );
  const getCalendarPeriodFilters = () => {
    if (filters?.schedule && filters.schedule.length > 0) {
      return filters.schedule.map((calPeriod: CalendarPeriod) => calPeriod.id);
    } else {
      return calendarPeriodsData?.data?.map((calPeriod: CalendarPeriod) => calPeriod.id) || [];
    }
  };
  const { data: inventoryReport, isLoading: loadingReport } = useGetInventoryReport(
    {
      organisation_id: orgisationId,
      per_page: inventoryReportPerPage,
      page: inventoryReportPage,
      calendar_period_ids: getCalendarPeriodFilters() as Array<string>,
      location_id: filters?.placementId || undefined,
      media_space_id: filters?.mediaTypeId || undefined,
    },
    {
      query: {
        queryKey: [
          INVENTORY_REPORT_KEY,
          orgisationId,
          inventoryReportPerPage,
          inventoryReportPage,
          getCalendarPeriodFilters(),
          filters?.placementId,
          filters?.mediaTypeId,
        ],
      },
    }
  );

  const changeBundlePagination = (newPage: number, newPerPage: PerPageEnum) => {
    if (newPage > 0 && newPage !== inventoryReportPage) {
      setInventoryReportPage(newPage);
    }

    if (newPerPage > 0 && newPerPage !== inventoryReportPerPage) {
      changeInventoryReportPerPage(newPerPage);
    }
  };

  const dataGridPaginationModel = {
    pageSize: inventoryReport?.meta.per_page ?? PerPageEnum.NUMBER_15,
    page: (inventoryReport?.meta.current_page ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };
  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeBundlePagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  const rows = useMemo(() => {
    return generateRows({
      inventoryReportData: inventoryReport?.data,
      calendarPeriodsData: calendarPeriodsData?.data,
    });
  }, [inventoryReport?.data, calendarPeriodsData?.data]);

  const calendarPeriodsHeaders = useMemo(() => {
    const calendaPeriods =
      filters.schedule && filters?.schedule?.length > 0 ? filters.schedule : calendarPeriodsData?.data;
    return calendaPeriods?.map((calPeriod: CalendarPeriod, index: number, array: Array<CalendarPeriod>) => ({
      field: calPeriod.id,
      headerName: `${calPeriod?.name} (${formatDisplayDate(moment(calPeriod.startAt))} - ${formatDisplayDate(moment(calPeriod?.endAt))})`,
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <RenderInventoryCell {...params} />;
      },
      cellClassName: (params: GridRenderCellParams) => {
        if (isSpaceUnavailable(params)) return 'unavailable';
        return params.value?.status || '';
      },
      ...(index === array.length - 1 && { headerClassName: 'inventory-table-right-header' }),
    })) as Array<GridColDef>;
  }, [calendarPeriodsData?.data, calendarPeriodsData?.meta, filters.schedule]);

  return {
    rows,
    dataGridPaginationModel,
    handlePaginationUpdate,
    loadingCalendarPeriods,
    setCalendarPeriodPage,
    inventoryReport,
    calendarPeriodsHeaders,
    calendarPeriodPage,
    calendarPeriodsData,
    loadingReport,
    setFilters,
  };
};
