import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Invite from '../features/organisations/Invite';
import { INVITE_SUPPLIERS } from 'lib/permissions';
import { Navigate } from 'react-router-dom';
import { usePermissions } from 'v2/lib/hooks/usePermissions';

const InviteSupplier = () => {
  const { hasPermission } = usePermissions();
  const canInviteSuppliers = hasPermission(INVITE_SUPPLIERS);

  if (!canInviteSuppliers) {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <Helmet>
        <title>InviteSupplier - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container className='pt-4'>
        <Row className='mb-3'>
          <Col lg='6'>
            <Invite />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InviteSupplier;