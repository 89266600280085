import React, { useContext } from 'react';
import { Box, Button, FactCheckIcon, ReceiptIcon, Typography } from '@zitcha/component-library';
import { NavigationContext } from 'v2/components/Navigation/Chrome';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import { PLAN_VIEW, VIEW_ALL_ORDERS } from 'lib/permissions';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';
import { NewPlanOrOrderButton } from 'v2/components/NewPlanModal/NewPlanOrOrderButton';

export const DashboardHeadingSection = () => {
  const { user } = useContext(NavigationContext);
  const { data: featureFlags } = useGetFeatureFlags();
  const navigate = useNavigate();

  const { hasPermission} = usePermissions();
  const canViewOrders = hasPermission(VIEW_ALL_ORDERS);
  const canViewPlans = hasPermission(PLAN_VIEW);

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
      <Box id='dashboard-heading-section'>
        <Typography variant='h4' data-testid='pageHeading'>
          Welcome back, {user?.first_name}.
        </Typography>
      </Box>

      <Box gap={3} display='flex'>
        {featureFlags?.feature_planning && canViewOrders && (
          <Button
            variant='text'
            color='primary'
            onClick={() => {
              navigate('/orders');
            }}
            startIcon={<ReceiptIcon />}
            id='view-orders-button'
            aria-label='View orders'
          >
            View Orders
          </Button>
        )}
        {featureFlags?.feature_planning && canViewPlans && (
          <Button
            variant='text'
            color='primary'
            onClick={() => {
              navigate('/plans-ad-sets?tab=plans');
            }}
            startIcon={<FactCheckIcon />}
            id='view-plans-button'
            aria-label='View plans'
          >
            View Plans
          </Button>
        )}
        <NewPlanOrOrderButton featuresEnabled={featureFlags} />
      </Box>
    </Box>
  );
};