import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, TextField } from '@zitcha/component-library';
import { useGetMediaSpaces } from 'v2/lib/api/inventory';
import { CalendarPeriod, MediaSpace, useGetCalendarPeriods, Location } from 'v2/lib/api/ad-management';
import { formatDisplayDate } from 'helpers/DateHelpers';
import type { Moment } from 'moment';
import { useLocation } from 'react-router-dom';
import { useInventoryOrgId } from './hooks/useInventoryOrgId';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import { PlacementAutocomplete } from 'v2/components/PlacementList/PlacementAutocomplete';
import { useNetworkBrandingContext } from 'app/OrganisationSettings/useNetworkBranding';
import { useRetailerSettings } from 'v2/lib/hooks/useRetailerSettings';

type FilterOption = { id: string; selected: boolean; name: string };
type InventoryTableFiltersProps = {
  onFilterChange: (newFilter: Partial<unknown>) => void;
};

export const InventoryTableFilters = ({ onFilterChange }: InventoryTableFiltersProps) => {
  const CALENDAR_PERIODS_KEY = 'calendarPeriods';
  const { setRetailerId } = useNetworkBrandingContext();
  const retailers = useScopedOrganisations({}).organisations as unknown as Array<FilterOption>; // Type assertion-> can fix at later date;
  const { data: scopedRetailersWithSettings } = useRetailerSettings({
    ids: retailers.map((retailer) => retailer.id),
  });
  const navigationState = useLocation().state as { retailerId: string };
  const orgId = useInventoryOrgId();
  const organisation = useUserOrganisation();
  const [selectedOrganisationId, setSelectedOrganisationId] = useState<string>('');
  const [selectedRetailerOption, setSelectedRetailerOption] = useState<FilterOption | null>(null);
  const { data: mediaSpacesData, isLoading: isLoadingMediaSpaces } = useGetMediaSpaces({
    organisation_id: selectedOrganisationId,
  });
  const { data: schedules, isLoading: isLoadingSchedules } = useGetCalendarPeriods(
    { organisation_id: selectedOrganisationId },
    {
      query: {
        queryKey: [CALENDAR_PERIODS_KEY, { organisation_id: selectedOrganisationId }],
        select: (data) => {
          return data.data.map((schedule: CalendarPeriod) => {
            return {
              ...schedule,
              selected: false,
            };
          });
        },
      },
    }
  );

  const [selectedPlacementOption, setSelectedPlacementOption] = useState<Location | undefined>(undefined);
  const [selectedMediaTypeOption, setSelectedMediaTypeOption] = useState<FilterOption | null>(null);
  const [selectedScheduleOptions, setSelectedScheduleOptions] = useState<Array<CalendarPeriod & { selected: boolean }>>(
    []
  );

  const selectedCalendarPeriods = useMemo(() => {
    return selectedScheduleOptions?.filter((schedule) => schedule.selected) as Array<FilterOption>;
  }, [selectedScheduleOptions]);

  useEffect(() => {
    onFilterChange({
      retailerId: selectedRetailerOption?.id,
      mediaTypeId: selectedMediaTypeOption?.id,
      placementId: selectedPlacementOption?.id,
      schedule: selectedCalendarPeriods,
    });
  }, [selectedRetailerOption, selectedMediaTypeOption, selectedPlacementOption, selectedCalendarPeriods]);

  useEffect(() => {
    if (!organisation.is_retailer && !selectedRetailerOption) {
      setSelectedRetailerOption(retailers?.find((retailer) => retailer.id === navigationState?.retailerId) || null);
    }
  }, [navigationState, organisation.is_retailer, retailers]);

  useEffect(() => {
    setRetailerId(selectedRetailerOption?.id || orgId);
  }, [selectedRetailerOption?.id]);

  useEffect(() => {
    setSelectedOrganisationId(selectedRetailerOption?.id || orgId);
    setSelectedMediaTypeOption(null); // Clear selected media type as they are dependent on retailer
    setSelectedPlacementOption(undefined); // Clear selected placement as they are dependent on retailer
  }, [selectedRetailerOption]);

  return (
    <Box display='flex' gap={1} padding={2} alignItems='center' role='presentation'>
      {!organisation.is_retailer ? (
        <Autocomplete
          id='retailer-filter'
          value={selectedRetailerOption}
          options={scopedRetailersWithSettings.filter((retailer) => retailer.settings.supplier_inventory_enabled) || []}
          onChange={(_, newValue: FilterOption | null) => {
            setSelectedRetailerOption(newValue);
          }}
          sx={{ minWidth: 200 }}
          getOptionLabel={(option: FilterOption) => option.name}
          renderInput={(params) => <TextField {...params} label='Retailer' />}
        />
      ) : null}

      <Autocomplete
        id='media-type'
        value={selectedMediaTypeOption}
        options={
          mediaSpacesData?.data?.map(
            (mediaSpace: MediaSpace) =>
              ({
                id: mediaSpace.id,
                name: mediaSpace.name,
              }) as FilterOption
          ) || []
        }
        onChange={(_, newValue: FilterOption | null) => {
          setSelectedMediaTypeOption(newValue);
        }}
        loading={isLoadingMediaSpaces}
        sx={{ minWidth: 200 }}
        getOptionLabel={(option: FilterOption) => option.name}
        renderInput={(params) => <TextField {...params} label='Media types' />}
      />
      {selectedRetailerOption && (
        <PlacementAutocomplete
          multiple={false}
          value={selectedPlacementOption as Array<Location>}
          onChange={(placement) => {
            setSelectedPlacementOption(placement as Location);
          }}
          textFieldProps={{ variant: 'outlined', label: 'Placement' }}
          retailerId={selectedRetailerOption.id}
        />
      )}
      <Autocomplete
        id='schedule-filter'
        loading={isLoadingSchedules}
        value={selectedCalendarPeriods}
        options={Array.isArray(schedules) ? (schedules as Array<FilterOption>) : []}
        getOptionLabel={(
          data: FilterOption & {
            name: string;
            startAt: Moment;
            endAt: Moment;
          }
        ) => {
          return `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`;
        }}
        onChange={(_, newValue: Array<FilterOption>) => {
          setSelectedScheduleOptions(
            (schedules || [])?.map((option) => ({
              ...option,
              selected: newValue?.some((selectedOption) => selectedOption.id === option.id) || false,
            }))
          );
        }}
        multiple
        sx={{ minWidth: 300 }}
        renderInput={(params) => <TextField {...params} label='Schedule' />}
      />
    </Box>
  );
};
