import React from "react";
import { BaseAutocomplete, BaseAutocompleteProps } from "./BaseAutocomplete";

export const FormAutocomplete: React.FC<BaseAutocompleteProps> = (props) => {
  return (
    <BaseAutocomplete
      {...props}
    />
  )
}
