import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@zitcha/component-library';
import { InputMode } from './RenderModalInputs';

export type User = {
  user: {
    id: string;
    name: string;
  };
  role: {
    id: number;
    name: string;
  };
};

type Props = {
  users: Array<User>;
  onRemoveUser?: (user: User) => void;
  mode?: InputMode;
};

export const UsersList = ({ users, onRemoveUser, mode = 'creating' }: Props) => {
  const [initialUsers, setInitialUsers] = useState<Array<User>>([]);
  useEffect(() => {
    if (initialUsers?.length > 0 || mode !== 'editing') return;
    setInitialUsers(users);
  }, [users, mode]);

  const isClickable = !!onRemoveUser;
  const extraProps = (user: User) => (isClickable ? { onDelete: () => onRemoveUser?.(user) } : {});
  if (users?.length === 0) return null;

  return (
    <Box display='flex' alignItems='center' gap={1} flexWrap='wrap' mt={1}>
      {users?.map((user, index) => {
        const isInitialUser = initialUsers?.some(
          (initialUser) => initialUser.user.id === user.user.id && initialUser.role.id === user.role.id
        );
        if (isInitialUser)
          return <Chip size='small' key={index} label={`${user.user.name}, ${user.role.name}`} color='default' />;

        return (
          <Chip
            size='small'
            key={index}
            label={`${user.user.name}, ${user.role.name}`}
            color='primary'
            {...extraProps(user)}
          />
        );
      })}
    </Box>
  );
};