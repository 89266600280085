import React from 'react';
import { Tabs, Tab } from '@zitcha/component-library';

export enum ProductSelectorViewMode {
  List = 'list',
  Grid = 'grid',
}

interface ToggleViewSwitchProps {
  viewMode: ProductSelectorViewMode;
  setViewMode: (viewMode: ProductSelectorViewMode) => void;
}

export const ToggleViewSwitch: React.FC<ToggleViewSwitchProps> = ({ viewMode, setViewMode }) => (
  <Tabs
    value={viewMode}
    onChange={(event, newValue: ProductSelectorViewMode) => setViewMode(newValue)}
    aria-label='View Mode Tabs'
  >
    <Tab label='LIST VIEW' value={ProductSelectorViewMode.List} />
    <Tab label='GRID VIEW' value={ProductSelectorViewMode.Grid} />
  </Tabs>
);