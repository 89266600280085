import { useLocation } from 'react-router-dom';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export const useInventoryOrgId = () => {
  const organisation = useUserOrganisation();
  const navigationState = useLocation().state as { retailerId: string };

  const getOrganisationId = () => {
    if (organisation.is_retailer) {
      return organisation.id;
    } else {
      return navigationState?.retailerId;
    }
  };
  return getOrganisationId();
};
