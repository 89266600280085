import React from 'react';
import { Box, Typography } from '@zitcha/component-library';
import { Ad } from 'v2/lib/api/ad-management';

type SkuCodesProps = {
  ads: Array<Ad>;
  isReadOnly: boolean;
};

// todo we should check for fields of type ProductSelectorComponent instead of fields.SKU
export const SkuCodes: React.FC<SkuCodesProps> = ({ ads, isReadOnly }) => {
  // Extract SKU codes from ads into an array
  const skuCodes = ads.reduce((acc: Array<string>, ad: Ad) => {
    if (Array.isArray(ad?.fields?.SKU)) {
      const adSkuCodes = ad?.fields?.SKU?.map((sku: any) => sku.id) || [];
      return acc.concat(adSkuCodes);
    }
    return acc;
  }, []);

  // Apply read-only styling conditionally
  const typographyStyle = isReadOnly ? { color: 'gray', pointerEvents: 'none' } : {};

  return (
    <Box maxHeight={'35px'} overflow={'auto'}>
      <Typography variant='body1' sx={typographyStyle}>
        {skuCodes.map((code) => (
          <React.Fragment key={code}>
            {code}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  );
};
