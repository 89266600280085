import { type GetPlanPermissionsParams, useGetPlanPermissions } from '../api/access-management';

interface PlanPermissionsProps extends Omit<GetPlanPermissionsParams, 'organisationId'> {
  organisationId?: string;
}

export const usePlanPermissions = (params: PlanPermissionsProps) => {
  const { data } = useGetPlanPermissions(params, { query: { enabled: !!params.organisationId }});

  const userPlanPermissions: Array<string> = data?.data?.permissions || [];

  const hasPlanPermission = (permission: string): boolean => {
    return userPlanPermissions.includes(permission);
  }

  const hasAllPlanPermissions = (permissions: Array<string>): boolean => {
    return permissions.every((permission) => hasPlanPermission(permission));
  }

  const hasAnyPlanPermissions = (permissions: Array<string>): boolean => {
    return permissions.some((permission) => hasPlanPermission(permission));
  }

  return { hasPlanPermission, hasAllPlanPermissions, hasAnyPlanPermissions };
};