import { useRetailersWithSettings } from './useRetailersWithSettings';

export interface Organisation {
  id: string;
  name: string;
  is_active: boolean;
  facebook_mpa_enabled: boolean;
  logo: string | null;
  features: Record<string, boolean>;
  settings: {
    defaultCurrency: 'USD' | 'EUR' | 'GBP' | string;
    enabled_ads: Record<string, boolean>;
    supplier_inventory_enabled: boolean;
  };
}
export const useRetailerSettings = ({
  ids,
}: {
  ids: Array<string>;
}): {
  isLoading: boolean;
  data: Array<Organisation>;
} => {
  const { data: retailers, isLoading: loading, error } = useRetailersWithSettings();

  const getOrganisationSettings = () => {
    if (!retailers) return { isLoading: loading, data: [], error: error };
    return {
      isLoading: loading,
      data: retailers
        ?.filter((org) => ids?.includes(org.id))
        .map((organisation): Organisation => {
          return {
            id: organisation.id,
            name: organisation.name,
            is_active: organisation.is_active,
            facebook_mpa_enabled: organisation.facebook_mpa_enabled,
            logo: organisation.logo,
            features: organisation.features,
            settings: organisation.settings,
          };
        }),
      error: error,
    };
  };
  return getOrganisationSettings();
};

export const useSingleRetailerSettings = ({ id }: { id: string }): { isLoading: boolean; data: Organisation } => {
  const { data: data, isLoading: loading } = useRetailerSettings({ ids: [id] });
  return { isLoading: loading, data: data[0] };
};
