import { ArrowForwardIcon, Box, InputLabel } from '@zitcha/component-library';
import React from 'react';
/*
 * This is a wrapper component for the PairedInputs ex: role -> team
 * This component is used to wrap the PairedInputs component as a shell and let's us decide the logic of how the values are displayed and handled
 * children should be passed in as an tuple of inputs, the first one being the left input and the second being the right input as well as the labels
 */
export const PairedInputsWrapper = ({
  labels,
  components,
}: {
  labels: [string, string];
  components: [React.ReactNode, React.ReactNode];
}) => {
  return (
    <Box display='flex' alignItems='flex-start' gap={2} width='80%' minHeight={80}>
      <Box display='flex' flexDirection='column' gap={1} width='100%'>
        <InputLabel>{labels.at(0)}</InputLabel>
        {components.at(0)}
      </Box>
      <Box alignSelf='center'>
        <ArrowForwardIcon />
      </Box>
      <Box display='flex' flexDirection='column' gap={1} width='100%'>
        <InputLabel>{labels.at(1)}</InputLabel>
        {components.at(1)}
      </Box>
    </Box>
  );
};