import React from 'react';
import { Box, Button } from '@zitcha/component-library';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StatusColours } from './StatusColours';

interface PaginationButtonsProps {
  currentPage: number;
  lastPage: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

export const HorizontalPagination = ({
  currentPage,
  lastPage,
  onPreviousClick,
  onNextClick,
}: PaginationButtonsProps) => (
  <Box display='flex' justifyContent='space-between' pt={2} pb={2} mb={2}>
    <Button disabled={currentPage === 1} onClick={onPreviousClick} variant='text' startIcon={<NavigateBeforeIcon />}>
      Previous phase
    </Button>
    <StatusColours />
    <Button disabled={currentPage === lastPage} onClick={onNextClick} variant='text' endIcon={<NavigateNextIcon />}>
      Next phase
    </Button>
  </Box>
);
