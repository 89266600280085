import { useGetScopedOrganisations } from 'v2/lib/api/access-management';

interface ScopedOrganisationParams {
  enabled?: boolean;
  query?: string;
}

export const useScopedOrganisations = ({
  enabled = true,
  query = undefined,
}: ScopedOrganisationParams) => {
  const {
    data,
    isLoading,
    isPending,
    error,
    isError,
  } = useGetScopedOrganisations({
    q: query,
    page: 1,
    perPage: undefined,
  }, {
    query: {
      enabled,
    }
  });

  return {
    organisations: data?.data || [],
    isLoading,
    isPending,
    isError,
    error,
  };
};