import { useState } from 'react';
import { useSearchRoles } from 'v2/lib/api/access-management';
import { PerPageEnum, RequestSearchRoles } from 'v2/lib/api/access-management/model';
import { ROLE_MANAGEMENT_ROLES_QUERY_KEY } from 'v2/constants/accessManagementConstants';

export const useFetchRoles = (initialRolesSearch: RequestSearchRoles = {}) => {
  const [rolesSearch, setRolesSearch] = useState(initialRolesSearch);
  const [RolesPage, setRolesPage] = useState<number>(1);
  const [RolesPerPage, setRolesPerPage] = useState<PerPageEnum>(PerPageEnum.NUMBER_10);

  const { data, isPending, isLoading, isError } = useSearchRoles(
    {
      ...rolesSearch,
      page: RolesPage,
      perPage: RolesPerPage,
    },
    {
      query: {
        queryKey: [ROLE_MANAGEMENT_ROLES_QUERY_KEY, { ...rolesSearch, page: RolesPage, perPage: RolesPerPage }],
      },
    }
  );

  const changeRolesPagination = (newPage: number, newPerPage: PerPageEnum) => {
    if (newPage > 0 && newPage !== RolesPage) {
      setRolesPage(newPage);
    }

    if (newPerPage > 0 && newPerPage !== RolesPerPage) {
      setRolesPerPage(newPerPage);
    }
  };

  return {
    roles: data?.data ?? [],
    rolesPagination: {
      currentPage: data?.meta?.current_page,
      from: data?.meta?.from,
      lastPage: data?.meta?.last_page,
      perPage: data?.meta?.per_page,
      to: data?.meta?.to,
      total: data?.meta?.total,
    },
    changeRolesPagination: changeRolesPagination,
    isLoadingRoles: isPending,
    rolesError: isError,
    setRolesSearch,
  };
};
