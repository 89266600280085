import { useSelector } from 'react-redux';
import { selectUser } from 'features/session/sessionSlice';

export const usePermissions = () => {
  const user = useSelector(selectUser);
  const userPermissions = Array.isArray(user?.permissions) ? user.permissions : [];

  const hasPermission = (permission: string): boolean => {
    return userPermissions?.includes(permission);
  };

  const hasAllPermissions = (permissions: Array<string>) =>
    Array.isArray(permissions) && permissions.every((permission) => hasPermission(permission));

  const hasAnyPermissions = (permissions: Array<string>) =>
    Array.isArray(permissions) && permissions.some((permission) => hasPermission(permission));

  return { hasPermission, hasAllPermissions, hasAnyPermissions };
};
