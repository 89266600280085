import { RequestRole, useCreateRole } from 'v2/lib/api/access-management';
import { useQueryClient } from '@tanstack/react-query';
import { ROLE_MANAGEMENT_ROLES_QUERY_KEY } from 'v2/constants/accessManagementConstants';

interface CreateRoleWithHandlerParams {
  handleClose?: () => void;
}

export interface CreateRoleData {
  data: RequestRole;
}

export const useAddRoleWithHandler = ({
  handleClose,
}: CreateRoleWithHandlerParams) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createNewRole,
    isPending,
    isSuccess,
    isError,
    error,
  } = useCreateRole({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [ROLE_MANAGEMENT_ROLES_QUERY_KEY] });
      }
    },
  });

  const handleAddRole = async (changedFields: CreateRoleData) => {
    try {
      await createNewRole(changedFields).then(() => {
        handleClose?.();
      });
    } catch (error) {
      return error;
    }
  };

  return { isError, isSuccess, isPending, error, handleAddRole };
};
