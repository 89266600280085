import React, { useState } from 'react';
import { Alert, Box, DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams } from '@zitcha/component-library';
import { useFetchAdSets } from 'v2/lib/hooks/useFetchAdSets';
import { AdSetsTableFilters } from './AdSetsTableFilters';
import { type AdSet, AdSetSearch, AdSetStatusEnum, PerPageEnum } from 'v2/lib/api/ad-management';
import { RenderAdSetsStatusChip, renderSwitch, renderText } from 'v2/features/Planning/TableCellRenderers';
import { useToggleAdset } from 'v2/lib/hooks/useToggleAdset';
import { AdSetModal } from 'v2/components/AdSetModal/AdSetModal';
import { RenderSchedule } from './PlanPage/reviewAdSets/ViewPlanAdSetsTableCellRenderers';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { BudgetCell, CenteredTableCellContainer, PlanName, SkuCodesCell } from './NewPlanAdSetsCellRenders';
import { useSaveAdSet } from '../../lib/hooks/useSaveAdSet';
import { AdSetActions, AdSetActionType } from '../../components/AdSetActions/AdSetActions';
import { ViewMode } from './PlanPage/ViewModeType';
import { useAdSetActions } from '../../lib/hooks/useAdSetActions';
import { ClashManagementModal } from '../ClashManagement/components/ClashManagementModal';
import { AdSetData } from './PlanContext';
import { AreYouSureModal } from 'v2/components/AreYouSureModal/AreYouSureModal';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import { PLAN_ADSET_REVIEW, PLAN_REVIEW } from 'lib/permissions';
import { batchedPromises } from 'v2/lib/utils/batchedPromises';
import { useDiscounts } from 'v2/features/Planning/useDiscounts';

export const AdSetsTable: React.FC = () => {
  const [editAdSet, setEditAdSet] = useState<AdSet | undefined>(undefined);
  const [viewAdSet, setViewAdSet] = useState<AdSet | undefined>(undefined);
  const [clashAdSet, setClashAdSet] = useState<AdSet | undefined>(undefined);
  const [proposeAdSetId, setProposeAdSetId] = useState<string | undefined>(undefined);

  const organisation = useUserOrganisation();
  const userPermissions = usePermissions();
  const isRetailer = organisation?.is_retailer;
  const userCanResolveClash =
    isRetailer && userPermissions.hasPermission(PLAN_REVIEW) && userPermissions.hasPermission(PLAN_ADSET_REVIEW);
  const { saveAdSet } = useSaveAdSet();
  const organisationTypeFilter = isRetailer
    ? { retailer_ids: [organisation.id], supplier_ids: [] }
    : { retailer_ids: [], supplier_ids: [organisation.id] };
  const initialAdSetSearch: AdSetSearch = {
    statuses: undefined,
    media_space_ids: undefined,
    start_at: undefined,
    end_at: undefined,
    ...organisationTypeFilter,
  };
  const {
    adSetsData,
    isLoadingAdSets,
    adSetsError,
    adSetPagination,
    changeAdSetPagination,
    setAdSetSearch,
    refetch: refetchAdSets,
  } = useFetchAdSets(initialAdSetSearch);
  const { mutate } = useToggleAdset();
  const { approveAdSetAsync, rejectAdSet, proposeAdSet, isPending: adSetActionPending } = useAdSetActions();
  const { syncAdSetsDiscounts } = useDiscounts();

  const saveAdSetFromModal = async (adSet: AdSet) => {
    try {
      await saveAdSet({ ...adSet, existingAdSet: editAdSet });
      await batchedPromises(syncAdSetsDiscounts([adSet]), 1);
      refetchAdSets();
      setEditAdSet(undefined);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const performAsyncAndRefetch = async (action: () => Promise<void>) => {
    await action();
    refetchAdSets();
  };

  const handleProposeAdSet = async (adSetId: string) => {
    await proposeAdSet(adSetId);
    setProposeAdSetId(undefined);
    setTimeout(() => refetchAdSets(), 2000);
  };

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    params: GridRenderCellParams
  ) => {
    mutate({ id: params.row.id, data: { status: checked ? AdSetStatusEnum.live : AdSetStatusEnum.paused } });
  };

  const handleAction = (action: AdSetActionType, adSet: AdSetData) => {
    switch (action) {
      case 'edit':
        setEditAdSet(adSet.adSet);
        break;
      case 'view':
        setViewAdSet(adSet.adSet);
        break;
      case 'resolve_clash':
        setClashAdSet(adSet.adSet);
        break;
      case 'approve':
        performAsyncAndRefetch(() => approveAdSetAsync(adSet.id));
        setTimeout(() => refetchAdSets(), 2000);
        break;
      case 'reject':
        rejectAdSet(adSet.id);
        setTimeout(() => refetchAdSets(), 2000);
        break;
      case 'propose':
        setProposeAdSetId(adSet.id);
        break;
      default:
        break;
    }
  };

  const rows =
    adSetsData?.map((adSet: AdSetData) => ({
      id: adSet.id,
      planId: adSet.plan?.id,
      active: adSet.status,
      status: adSet.status,
      planName: adSet.plan?.name,
      statuses: {
        status: adSet.status,
        actions: adSet.actions,
      },
      retailer: adSet?.plan?.owner?.name, // when viewing as supplier
      brand: adSet?.plan?.supplier?.name, // when viewing as retailer
      bundleIds: adSet?.bundleIds,
      mediaSpace: adSet?.mediaSpace?.name,
      placement: adSet.bundleLocationNames,
      budget: adSet?.price,
      adSet: adSet,
      permissions: adSet?.permissions,
    })) || [];

  const columns: Array<GridColDef> = [
    {
      field: 'active',
      headerName: 'Active',
      minWidth: 60,
      renderCell: (params) => {
        return <CenteredTableCellContainer>{renderSwitch(params, handleSwitchChange)}</CenteredTableCellContainer>;
      },
    },
    {
      field: 'statuses',
      headerName: 'Status',
      width: 250,
      renderCell: (params) => <RenderAdSetsStatusChip {...params} key='adset-actions-chips' />,
    },
    {
      field: 'planName',
      headerName: 'Plan name',
      width: 150,
      renderCell: PlanName,
    },
    {
      field: isRetailer ? 'brand' : 'retailer',
      headerName: isRetailer ? 'Brand' : 'Retailer',
      width: 150,
      renderCell: renderText,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      width: 250,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    {
      field: 'mediaSpace',
      headerName: 'Media type',
      width: 150,
      renderCell: renderText,
    }, //[]string
    {
      field: 'placement',
      headerName: 'Placement',
      width: 250,
      renderCell: renderText,
    }, //[]string
    {
      field: 'skuCodes',
      headerName: 'SKU code(s)',
      minWidth: 100,
      type: 'custom',
      editable: false,
      renderCell: (params) => {
        return <SkuCodesCell {...params} />;
      },
    },
    {
      field: 'budget',
      headerName: 'Budget',
      // flex: 1,
      // align: 'right',
      headerAlign: 'center',
      width: 120,
      renderCell: (params) => <BudgetCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <CenteredTableCellContainer>
          <AdSetActions
            adSet={params.row}
            viewMode={ViewMode.SEARCH}
            actionCallback={handleAction}
            canApproveOrReject={organisation?.is_retailer ?? false}
            canViewClash={isRetailer}
            canResolveClash={userCanResolveClash}
          />
        </CenteredTableCellContainer>
      ),
    },
  ];

  const handleFilterChange = (newFilter: Partial<AdSetSearch>) => {
    setAdSetSearch((prevFilter) => ({ ...prevFilter, ...newFilter }));
  };

  const dataGridPaginationModel = {
    pageSize: adSetPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (adSetPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeAdSetPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  return (
    <Box sx={{ backgroundColor: 'white' }} padding={2} mt={3}>
      <AdSetsTableFilters onFilterChange={handleFilterChange} isRetailerView={organisation.is_retailer} />
      {adSetsError && <Alert severity='error'>An error occurred while fetching ad sets.</Alert>}
      <DataGrid
        disableColumnFilter
        disableColumnSorting
        rows={rows}
        paginationMode='server'
        columns={columns}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
        autoHeight
        loading={isLoadingAdSets}
        rowCount={adSetPagination.total}
        paginationModel={dataGridPaginationModel}
        onPaginationModelChange={handlePaginationUpdate}
      />
      {editAdSet && (
        <AdSetModal
          isOpen={!!editAdSet}
          isReadOnly={false}
          adSet={editAdSet}
          onClose={() => setEditAdSet(undefined)}
          saveHandler={saveAdSetFromModal}
          retailerId={editAdSet.plan?.owner?.id || null}
          brandId={editAdSet.plan?.supplier?.id || null}
        />
      )}
      {viewAdSet && (
        <AdSetModal
          isOpen={!!viewAdSet}
          isReadOnly={true}
          adSet={viewAdSet}
          onClose={() => setViewAdSet(undefined)}
          retailerId={viewAdSet.plan?.owner?.id || null}
          brandId={viewAdSet.plan?.supplier?.id || null}
        />
      )}
      {clashAdSet && (
        <ClashManagementModal
          retailerId={clashAdSet.plan?.owner?.id || ''}
          bundleId={clashAdSet?.bundleIds?.[0] || ''}
          isOpen={!!clashAdSet}
          closeModal={() => setClashAdSet(undefined)}
        />
      )}
      {proposeAdSetId && (
        <AreYouSureModal
          isOpen={!!proposeAdSetId}
          onClose={() => setProposeAdSetId(undefined)}
          onYes={() => handleProposeAdSet(proposeAdSetId)}
          onNo={() => setProposeAdSetId(undefined)}
          title='Propose ad set'
          message='Are you sure you want to propose this ad set?'
          loading={adSetActionPending}
        />
      )}
    </Box>
  );
};
