import React from 'react';
import { Modal } from '@zitcha/component-library';
import ClashManagementModalContent from './ClashManagementModalContent';
import { ClashManagementProvider } from '../context/ClashManagementContext';

interface ClashManagementModalProps {
  retailerId: string;
  bundleId: string;
  isOpen: boolean;
  closeModal: () => void;
}

export const ClashManagementModal = ({ retailerId, bundleId, isOpen, closeModal }: ClashManagementModalProps) => {
  return (
    <Modal open={isOpen} onClose={closeModal} title='Resolve Clash' size='large' className='focus:outline-none'>
      <ClashManagementProvider retailerId={retailerId} bundleId={bundleId} closeClashManagementModal={closeModal}>
        <ClashManagementModalContent closeClashManagementModal={closeModal} />
      </ClashManagementProvider>
    </Modal>
  );
};