import { Autocomplete, Box, InputAdornment, SearchIcon, TextField } from '@zitcha/component-library';
import React, { useEffect, useState } from 'react';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { useRoles } from 'v2/lib/hooks//useRoles';
import { useGetTeamsSlim } from '../TeamManagement/hooks/useGetTeams';
import { RequestSearchUsers } from 'v2/lib/api/access-management';

export const UserManagementFilters = ({
  onFilterChange,
}: {
  onFilterChange: (filters: Partial<RequestSearchUsers>) => void;
}) => {
  const [roleQuery, setRoleQuery] = useState('');
  const roles = useRoles({ query: roleQuery });
  const [selectedRoles, setSelectedRoles] = useState<Array<{ id: number; name: string }>>([]);

  const [teamsQuery, setTeamsQuery] = useState('');
  const { teams, loading } = useGetTeamsSlim({
    query: teamsQuery,
  });
  const [selectedTeams, setSelectedTeams] = useState<Array<{ id: number; name: string }>>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const debouncedProductSetQuery = useDebounceSetter(setTeamsQuery);
  const debouncedRoleQuery = useDebounceSetter(setRoleQuery);
  const debouncedSearchQuery = useDebounceSetter(setSearchQuery);

  useEffect(() => {
    onFilterChange({
      roleIds: selectedRoles.map((role) => role.id),
      teamIds: selectedTeams.map((team) => team.id),
      q: searchQuery,
    });
  }, [selectedRoles, selectedTeams, searchQuery]);

  return (
    <Box sx={{ display: 'flex', padding: 2, justifyContent: 'space-between', gap: 2, flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Autocomplete
          multiple
          options={roles.roles || []}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedRoles}
          loading={roles.isLoading}
          onChange={(_, data) => {
            setSelectedRoles(data);
          }}
          onInputChange={(event, value) => debouncedRoleQuery(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Roles'
              placeholder={selectedRoles.length ? '' : 'All roles'}
              sx={{ minWidth: 276 }}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          multiple
          options={teams || []}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedTeams}
          onInputChange={(event, value) => debouncedProductSetQuery(value)}
          onChange={(_, data) => {
            setSelectedTeams(data);
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Teams'
              placeholder={selectedTeams.length ? '' : 'All teams'}
              sx={{ minWidth: 276 }}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <TextField
        label='Search'
        sx={{ minWidth: 276 }}
        InputProps={{
          placeholder: 'Search by name, email, etc.',
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(value) => {
          debouncedSearchQuery(value.target.value);
        }}
      />
    </Box>
  );
};