import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
} from '@zitcha/component-library';
import { ProductSet } from 'features/common/types';

interface ProductSetSelectorAndFilter {
  productSets: Array<ProductSet>;
  selectedProductSetId: string;
  onSelectedProductSetChange: (value: string) => void;
  filterTerm: string;
  onFilterTermChange: (value: string) => void;
}

export const ProductSetSelectorAndFilter: React.FC<ProductSetSelectorAndFilter> = ({
  productSets = [],
  selectedProductSetId,
  onSelectedProductSetChange,
  filterTerm,
  onFilterTermChange,
}) => {
  const handleProductSetChange = (event: SelectChangeEvent<string>) => {
    onSelectedProductSetChange(event.target.value as string);
  };

  return (
    <Box gap={1} display={'flex'} justifyContent={'space-between'} mt={1}>
      <Box width={'100%'}>
        <TextField
          fullWidth
          label='Filter by product name or SKUs'
          type='text'
          placeholder='Enter Product Name'
          onChange={(e) => onFilterTermChange(e.target.value)}
          value={filterTerm}
          variant='outlined'
        />
      </Box>
      <Box width={'100%'}>
        <FormControl variant='outlined' fullWidth>
          <InputLabel id='product-catalog-label' style={{ backgroundColor: '#fff', paddingRight: '5px' }}>
            Product Catalog
          </InputLabel>
          <Select
            labelId='product-set-label'
            value={selectedProductSetId}
            onChange={handleProductSetChange}
            label='Product Set'
            placeholder={productSets?.length ? undefined : 'No product sets available'}
          >
            {productSets.map((set) => (
              <MenuItem value={set.id} key={set.id}>
                {set.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
