import { RequestUpdateUser, ResponseUser } from 'v2/lib/api/access-management';

export const mapUserData = (data: Partial<ResponseUser>, deactivate = false): RequestUpdateUser => {
  const isActive = deactivate ? !data.isActive : data?.isActive ?? false;
  return {
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
    jobTitle: data?.jobTitle || '',
    isActive,
    teams:
      data?.teamsRoles?.map((teamRole) => {
        return {
          teamId: teamRole.team.id,
          roleId: teamRole.role.id,
        };
      }) || [],
  };
};
