import React, { useContext } from 'react';
import { OrganisationView } from './OrganisationView';
import { NavigationContext } from './Chrome';
import {
  Box,
  InputAdornment,
  Paper,
  SearchIcon,
  TextField,
  Typography,
  useTheme
} from '@zitcha/component-library';

function Organisations() {
  const { user, user_organisations } = useContext(NavigationContext);
  const [searchTerm, setSearchTerm] = React.useState('');
  const SWITH_ORG_THRESHOLD = 1;
  const SEARCH_FIELD_THRESHOLD = 5;
  const theme = useTheme();
  const filteredOrganisations = user_organisations.filter((organisation) =>
    organisation.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {user_organisations.length > SEARCH_FIELD_THRESHOLD && (
        <TextField
          id='search'
          value={searchTerm}
          type='search'
          onChange={(e) => setSearchTerm(e.target.value)}
          variant='outlined'
          fullWidth
          sx={{
            marginTop: '1rem',
            marginBottom: '1.5rem',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {filteredOrganisations.length > SWITH_ORG_THRESHOLD && (
          <Typography variant='caption'>Switch organisation</Typography>
        )}
      </Box>
      {filteredOrganisations.length > 0 ? (
        <Paper
          sx={{
            padding: '1rem',
            marginTop: '1rem',
            maxHeight: '25rem',
            overflowY: 'auto',
          }}
        >
          {filteredOrganisations.map((organisation) => (
            <OrganisationView
              key={organisation.id}
              id={organisation.id}
              isActive={organisation.id === user.active_organisation_id}
              logoUrl={organisation.logo}
              name={organisation.name}
            />
          ))}
        </Paper>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey[700],
          }}
        >
          <Typography variant='body1'>No match for &quot;{searchTerm}&quot;</Typography>
        </Box>
      )}
    </>
  );
}

export { Organisations };