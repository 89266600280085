import { GridRenderCellParams, Stack, Typography } from '@zitcha/component-library';
import React from 'react';

export const RenderPlacemementCell = (params: GridRenderCellParams) => {
  return (
    <Stack display='flex' justifyContent='center' height='100%'>
      <Typography variant='body2'>{params?.row?.placement?.value}</Typography>
      <Typography
        variant='caption'
        sx={{
          color: 'text.secondary',
        }}
      >
        {`${params?.row?.placement?.slot}/${params?.row?.placement?.totalSlots}`}
      </Typography>
    </Stack>
  );
};
