import { useState } from 'react';
import { useGetPlanAdSets } from 'v2/lib/api/ad-management';
import { GetPlanAdSetsParams, PerPageEnum } from 'v2/lib/api/ad-management/model';
import { AdSet } from 'v2/lib/api/ad-management/model';

export const usePlanAdSets = (planId: string, initialParams: GetPlanAdSetsParams = {}) => {
  //todo change params when BE is ready
  const [params, setParams] = useState({});
  const [page, setPage] = useState<number>(initialParams.page ?? 1);
  const [perPage, setPerPage] = useState<PerPageEnum>(initialParams.per_page ?? PerPageEnum.NUMBER_10);

  const adSetsMutation = useGetPlanAdSets(
    planId,
    {
      page: page,
      per_page: perPage,
      ...params,
    },
    { query: { queryKey: ['usePlanAdSets', page, perPage, planId] } }
  );

  const changePlanAdSetsPagination = (newPage: number, newPerPage: PerPageEnum) => {
    if (newPage > 0 && newPage !== page) {
      setPage(newPage);
    }

    if (newPerPage > 0 && newPerPage !== perPage) {
      setPerPage(newPerPage);
    }
  };

  const planAdSetsData: Array<AdSet> = adSetsMutation.data?.data ?? [];

  return {
    planAdSetsData,
    planAdSetsPagination: {
      currentPage: adSetsMutation.data?.meta?.current_page,
      from: adSetsMutation.data?.meta?.from,
      lastPage: adSetsMutation.data?.meta?.last_page,
      perPage: adSetsMutation.data?.meta?.per_page,
      to: adSetsMutation.data?.meta?.to,
      total: adSetsMutation.data?.meta?.total,
    },
    changePlanAdSetsPagination,
    isLoading: adSetsMutation.isLoading,
    error: adSetsMutation.error,
    setParams,
    refetch: adSetsMutation.refetch,
  };
};
