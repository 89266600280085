import React, { useEffect, useMemo, useState } from 'react';
import {
  AccountBalanceWalletIcon,
  Autocomplete,
  Box,
  CircularProgress,
  EditIcon,
  Modal,
  SearchIcon,
  StoreIcon,
  TextField,
  Button,
} from '@zitcha/component-library';
import { Organisation } from 'v2/lib/hooks/useUserOrganisation';
import { useFetchPlans } from 'v2/lib/hooks/useFetchPlans';
import { PerPageEnum, Plan, PlanStatus } from 'v2/lib/api/ad-management';
import { debounce } from 'debounce';
import { InputAdornment } from '@mui/material';

interface NewAdSetModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPlan: Plan | null;
  onPlanChange: (newValue: Plan | null) => void;
  onEditAdSet: () => void;
  organisation: Organisation;
}

type PlanOption = { id: string; selected: boolean; name: string };

const NewAdSetModal = ({
  isOpen,
  onClose,
  selectedPlan,
  onPlanChange,
  onEditAdSet,
  organisation,
}: NewAdSetModalProps) => {
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const [planName, setPlanName] = useState('');
  const [loading, setLoading] = useState(false);

  const userDictionary = getUserDictionary(organisation, selectedPlan);
  const planSearchData = getPlanSearchData(userDictionary, planName);

  const { plansData, setPlanSearch } = useFetchPlans(planSearchData);

  useEffect(() => {
    setLoading(true);
    setPlanName(planName);
    setPlanSearch(planSearchData);
    setLoading(false);
  }, [planName, setPlanSearch]);

  const debouncedSetPlanName = useMemo(() => {
    return debounce((newPlanName: string) => {
      setLoading(true);
      setPlanName(newPlanName);
    }, 300);
  }, []);

  return (
    <>
      <Modal open={isOpen} size='small' title='New ad set' onClose={onClose}>
        <>
          <Box mt={2}>
            <Autocomplete
              open={isAutoCompleteOpen}
              onOpen={() => setIsAutoCompleteOpen(true)}
              onClose={() => setIsAutoCompleteOpen(false)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option: PlanOption) => option.name}
              renderOption={(props, option: Plan) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              options={plansData}
              loading={loading}
              onInputChange={(_, newPlanName) => {
                debouncedSetPlanName(newPlanName);
              }}
              onChange={(_, newValue) => onPlanChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Choose a plan'
                  variant='standard'
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <OrganizationInfo userDictionary={userDictionary} />

          <WalletInfo selectedPlan={selectedPlan} />

          <ActionButtons onClose={onClose} onEditAdSet={onEditAdSet} selectedPlan={selectedPlan} />
        </>
      </Modal>
    </>
  );
};

const OrganizationInfo = ({ userDictionary }: { userDictionary: any }) => (
  <Box mt={3}>
    <small>{userDictionary.otherOrganisationName}</small>
    <div className='twd-flex twd-mt-2'>
      <StoreIcon />
      <p className='twd-text-sm twd-ml-4 twd-mt-1'>
        {userDictionary.otherOrganisationValue ?? 'This will populate based on the plan you select.'}
      </p>
    </div>
  </Box>
);

const WalletInfo = ({ selectedPlan }: { selectedPlan: Plan | null }) => (
  <Box mt={1}>
    <small>Wallet</small>
    <div className='twd-flex twd-mt-2'>
      <AccountBalanceWalletIcon />
      <p className='twd-text-sm twd-ml-4 twd-mt-1'>
        {selectedPlan?.wallet?.name ?? 'This will populate based on the plan you select.'}
      </p>
    </div>
  </Box>
);

const ActionButtons = ({
  onClose,
  onEditAdSet,
  selectedPlan,
}: {
  onClose: () => void;
  onEditAdSet: () => void;
  selectedPlan: Plan | null;
}) => (
  <div className='twd-mt-8 twd-flex twd-justify-between'>
    <Button variant='text' color='error' className='w-5/12' onClick={onClose}>
      CANCEL
    </Button>
    <Button
      variant='contained'
      color='primary'
      className='w-5/12'
      onClick={onEditAdSet}
      disabled={selectedPlan === null}
    >
      <EditIcon className='twd-mr-2' />
      EDIT AD SET
    </Button>
  </div>
);

const getUserDictionary = (organisation: Organisation, selectedPlan: Plan) => {
  const retailerDictionary = {
    ownerOrganisationId: [organisation.id],
    supplierOrganisationId: undefined,
    otherOrganisationName: 'Brand',
    otherOrganisationValue: selectedPlan?.supplier?.name,
  };
  const supplierDictionary = {
    ownerOrganisationId: undefined,
    supplierOrganisationId: [organisation.id],
    otherOrganisationName: 'Retailer',
    otherOrganisationValue: selectedPlan?.owner?.name,
  };
  return organisation.is_retailer ? retailerDictionary : supplierDictionary;
};

const getPlanSearchData = (userDictionary: any, planName: string) => ({
  owner_organisation_ids: userDictionary.ownerOrganisationId,
  supplier_organisation_ids: userDictionary.supplierOrganisationId,
  statuses: [PlanStatus.planning, PlanStatus.proposed],
  name: planName,
  per_page: PerPageEnum.NUMBER_10,
});

export default NewAdSetModal;
