import React from 'react';
import { Box } from '@zitcha/component-library';
import { DetailItem } from 'v2/features/Planning/PlanPage/components/DetailItem';


export interface ProductSelectorInfoSectionProps {
  mediaSpace: string;
  schedule: string;
  placement: string;
}

export const ProductSelectorInfoSection: React.FC<ProductSelectorInfoSectionProps> = ({
  mediaSpace = '-',
  schedule = '-',
  placement = '-'
}) => {
  return (
    <Box display='flex' gap={2} mt={3}>
      <DetailItem label='Media type' value={mediaSpace} />
      <DetailItem label='Schedule' value={schedule} />
      <DetailItem label='Placement' value={placement} />
    </Box>
  );
};