import React from 'react';
import { Box, Typography } from '@zitcha/component-library';
import { formatCurrency } from 'lib/util/currencyFormatter';
import { useGetOrganisation } from 'v2/lib/hooks/useGetOrganisation';

interface RenderCurrencyProps {
  amount: number | undefined;
  label?: string;
  isReadOnly?: boolean;
  showDecimals?: boolean;
  organisationId?: string;
  currencyCode?: string;
  alignLeft?: boolean;
}

export const RenderCurrency = ({
  amount,
  label,
  isReadOnly,
  showDecimals,
  organisationId,
  currencyCode,
  alignLeft,
}: RenderCurrencyProps) => {
  const { organisation, isLoading } = useGetOrganisation(organisationId || '');
  const currencySymbol = currencyCode || organisation?.settings?.defaultCurrency || ''; // Use currencyCode if provided

  // Apply read-only styling conditionally
  const typographyStyle = isReadOnly ? { color: 'gray', pointerEvents: 'none' } : {};

  const formatted = isLoading ? 'Loading...' : formatCurrency(amount, currencySymbol, showDecimals);

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        minWidth: '80px',
        textAlign: alignLeft ? 'left' : 'right',
      }}
    >
      {label && (
        <Typography variant='caption' sx={typographyStyle}>
          {label}
        </Typography>
      )}
      <Typography variant='subtitle1' gutterBottom sx={typographyStyle}>
        {amount && amount > 0 ? formatted : '-'}
      </Typography>
    </Box>
  );
};
