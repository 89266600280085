import { type RequestTeamCreate, useCreateTeam } from 'v2/lib/api/access-management';
import { useQueryClient } from '@tanstack/react-query';
import { TEAM_MANAGEMENT_TEAMS_QUERY_KEY } from 'v2/constants/accessManagementConstants';

interface CreateTeamWithHandlerParams {
  handleClose?: () => void;
}

export interface CreateTeamData {
  data: RequestTeamCreate;
}

export const useAddTeamWithHandler = ({
  handleClose,
}: CreateTeamWithHandlerParams) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createNewTeam,
    isPending,
    isSuccess,
    isError,
    error,
  } = useCreateTeam({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [TEAM_MANAGEMENT_TEAMS_QUERY_KEY] });
      }
    },
  });

  const handleAddTeam = async (changedFields: CreateTeamData) => {
    try {
      await createNewTeam(changedFields).then(() => {
        handleClose?.();
      });
    } catch (error) {
      return error;
    }
  };

  return { isError, isSuccess, isPending, error, handleAddTeam };
};
