import React, { useState } from 'react';
import { Button } from '@zitcha/component-library';
import { NewPlanModal } from 'v2/components/NewPlanModal/NewPlanModal';
import { usePermissions } from '../../lib/hooks/usePermissions';
import { CREATE_ORDERS, PLAN_CREATE } from '../../../lib/permissions';

export const NewPlanModalButton = () => {
  const [isNewPlanModalOpen, setIsNewPlanModalOpen] = useState(false);

  // Note: Both canCreatePlans and canCreateOrders added to check here as it
  // will be changes in SP-4420 to show a dropdown if both features are enabled
  const { hasPermission } = usePermissions();
  const canCreatePlans = hasPermission(PLAN_CREATE);
  const canCreateOrders = hasPermission(CREATE_ORDERS);

  return canCreatePlans && (
    <div>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={() => setIsNewPlanModalOpen(true)}
        aria-label='Create new plan'
        aria-expanded={isNewPlanModalOpen}
      >
        New Plan +
      </Button>
      <NewPlanModal isOpen={isNewPlanModalOpen} onClose={() => setIsNewPlanModalOpen(false)} />
    </div>
  );
};