import React from 'react';
import { Box, Typography, Checkbox } from '@zitcha/component-library';
import { FormControlLabel } from '@mui/material';
import { ProductSelectorViewMode, ToggleViewSwitch } from './ToggleViewSwitch';
import { Product } from 'features/common/types';

interface ProductsContentControlsProps {
    filteredProducts: Array<Product>;
    selectAllChecked: boolean;
    handleSelectAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    viewMode: ProductSelectorViewMode;
    setViewMode: (viewMode: ProductSelectorViewMode) => void;
}

export const ProductsContentControls: React.FC<ProductsContentControlsProps> = ({
    filteredProducts,
    selectAllChecked,
    handleSelectAllChange,
    viewMode,
    setViewMode,
}) => (
    <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mt={2}
        sx={{ paddingLeft: '10px', paddingRight: '10px' }}
    >
        <Box display='flex' alignItems='center' gap={2}>
            <Typography variant='body1'>{Number(filteredProducts.length).toLocaleString()} products found</Typography>
            <FormControlLabel
                control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />}
                label='Select all'
                sx={{ margin: 0 }}
            />
        </Box>
        <ToggleViewSwitch viewMode={viewMode} setViewMode={setViewMode} />
    </Box>
);