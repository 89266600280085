import React from 'react';
import { Checkbox, Box, Typography } from '@zitcha/component-library';
import { Product } from 'features/common/types';

interface ProductTileProps {
  product: Product;
  checked: boolean;
  onClick: (product: Product) => void;
  index?: number;
}

export const ProductGridTile: React.FC<ProductTileProps> = ({ product, checked, onClick }) => {
  const { image_url, title, price, currency } = product;
  return (
    <Box
      data-testid='product-item'
      onClick={() => onClick(product)}
      display='flex'
      flexDirection='column'
      border={2}
      borderColor={checked ? '#A162D0' : 'transparent'}
      borderRadius={1}
      sx={{
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
        '&:hover': {
          borderColor: !checked ? '#e9cbff' : '#A162D0',
        },
        height: '100%',
      }}
    >
      <Box
        position='relative'
        sx={{
          aspectRatio: '1 / 1',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <img
          src={image_url}
          alt={title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box position='absolute' top={0} right={0}>
          <Checkbox checked={checked} />
        </Box>
      </Box>
      <Box
        sx={{
          padding: '8px 12px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Typography
          variant='body2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            mb: 1,
          }}
        >
          {title}
        </Typography>
        <Typography variant='caption' sx={{ fontWeight: 700 }}>
          {`${currency} ${price}`}
        </Typography>
      </Box>
    </Box>
  );
};

export const ProductListTile: React.FC<ProductTileProps> = ({ product, checked, onClick, index }) => {
  const { image_url, title, price, currency, id } = product;
  return (
    <Box
      data-testid='product-item'
      onClick={() => onClick(product)}
      display={'flex'}
      alignItems={'center'}
      px={2}
      py={1}
      gap={1}
      border={1}
      borderColor={'transparent'}
      bgcolor={index && index % 2 === 0 ? 'white' : '#f2effc80'}
      sx={{
        cursor: 'pointer',
        '&:hover': { borderColor: !checked ? '#e9cbff' : '#A162D0' },
        ...(checked && { borderColor: '#A162D0' }),
      }}
    >
      <Checkbox checked={checked} />
      <Box sx={{ width: '30px', height: '30px', overflow: 'hidden' }}>
        <img src={image_url} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>
      <Typography
        variant='body2'
        sx={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {title}
      </Typography>
      <Typography variant='body2'>{`${price} ${currency}`}</Typography>
      <Typography variant='body2'>{id}</Typography>
    </Box>
  );
};
