import { ResponseUser, useUpdateUser } from 'v2/lib/api/access-management';
import { useQueryClient } from '@tanstack/react-query';
import { USER_MANAGEMENT_QUERY_KEY } from 'v2/constants/accessManagementConstants';

export const useUpdateUserAction = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending, isError, error, isSuccess } = useUpdateUser({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [USER_MANAGEMENT_QUERY_KEY] });
      },
      onMutate: async (data) => {
        const queryKeys = queryClient.getQueryCache().findAll({
          queryKey: [USER_MANAGEMENT_QUERY_KEY],
        });
        const lastQueryKey = queryKeys[queryKeys.length - 1];
        await queryClient.cancelQueries({ queryKey: lastQueryKey.queryKey });
        const previousValue = await queryClient.getQueryData(lastQueryKey.queryKey);
        queryClient.setQueryData(lastQueryKey.queryKey, (old: { data: Array<ResponseUser> }) => {
          return {
            ...old,
            data: old?.data?.map((user) => {
              if (user.id === data.userId) {
                return {
                  ...user,
                  ...data.data,
                };
              }
              return user;
            }),
          };
        });
        return { previousValue };
      },
    },
  });
  return {
    updateUser: mutateAsync,
    isUpdatingUser: isPending,
    isErrorUpdatingUser: isError,
    updatingUserError: error,
    isSuccessUpdatingUser: isSuccess,
  };
};
