import { createContext, Dispatch, ReducerAction, useContext } from 'react';
import {
  type AdSet,
  type CalendarPeriod,
  type Plan,
  type PlanOwner,
  type PlanStatus,
  type PlanSupplier,
  type PlanWallet,
  type Location,
  Bundle,
} from '../../lib/api/ad-management';
import { v4 as uuidv4 } from 'uuid';
import { DiscountData } from '../../components/Discounts/DiscountsModal';

export interface AdSetData extends AdSet {
  existingAdSet?: AdSet;
  placement?: Location | null;
  schedule?: CalendarPeriod | null;
  bundle?: Bundle;
  skuCodes?: string;
  permissions?: Record<string, boolean>;
}

export interface PlanData extends Plan {
  originalPlanData?: Plan;
  adSetCount: number;
  budget: string | null;
  budgetLeft: string | null;
  /** @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$ */
  createdAt?: string;
  /** @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$ */
  updatedAt?: string;
  id: string | null;
  name: string | null;
  notes: string;
  owner: PlanOwner | null;
  status: PlanStatus | null;
  supplier: PlanSupplier | null;
  wallet: PlanWallet | null;
  newAdSets: Array<AdSetData>;
  updatedAdSets: Array<AdSetData>;
  retailerId: string | null;
  brandId: string | null;
  discount: unknown | null;
  walletId: string | null;
  newDiscounts: Array<DiscountData>;
  updatedDiscounts: Array<DiscountData>;
  deletedDiscounts: Array<DiscountData>;
}

export function planReducer(plan: PlanData, action: PlanAction) {
  switch (action.type) {
    case 'updatePlan': {
      return { ...plan, ...action.plan };
    }
    case 'addAdSet': {
      return addAdSet(plan);
    }
    case 'removeAdSet': {
      return removeAdSet(plan, action.adSet);
    }
    case 'updateAdSet': {
      return updateAdSet(plan, action.adSet);
    }
    case 'reset': {
      return { ...initialPlanState, ...action.payload };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const addAdSet = (plan: PlanData) => {
  const newAdSets = [...plan.newAdSets, { id: uuidv4() }];
  return { ...plan, adSetCount: plan.adSetCount + 1, newAdSets };
};

const updateAdSet = (plan: PlanData, adSet: AdSetData) => {
  const updatedAdSets = plan.updatedAdSets.map((row) => (row.id === adSet.id ? adSet : row));
  const newAdSets = plan.newAdSets.map((row) => (row.id === adSet.id ? adSet : row));

  if (!updatedAdSets.find((row) => row.id === adSet.id) && !newAdSets.find((row) => row.id === adSet.id)) {
    updatedAdSets.push(adSet);
  }

  return { ...plan, updatedAdSets, newAdSets };
};

const removeAdSet = (plan: PlanData, adSet: AdSetData) => {
  const initialCount = plan.newAdSets.length + plan.updatedAdSets.length;
  const newAdSets = plan.newAdSets.filter((row) => row.id !== adSet.id);
  const updatedAdSets = plan.updatedAdSets.filter((row) => row.id !== adSet.id);
  const finalCount = newAdSets.length + updatedAdSets.length;
  const removedCount = initialCount - finalCount;
  return { ...plan, adSetCount: plan.adSetCount - removedCount, updatedAdSets, newAdSets };
};

export const initialPlanState: PlanData = {
  adSetCount: 0,
  id: null,
  name: null,
  notes: '',
  owner: null,
  status: null,
  supplier: null,
  wallet: null,
  newAdSets: [],
  updatedAdSets: [],
  budget: null,
  budgetLeft: null,
  retailerId: null,
  brandId: null,
  discount: null,
  walletId: null,
  newDiscounts: [],
  updatedDiscounts: [],
  deletedDiscounts: [],
};

export type PlanAction =
  | { type: 'updatePlan'; plan: PlanData }
  | { type: 'addAdSet' }
  | { type: 'removeAdSet'; adSet: AdSetData }
  | { type: 'updateAdSet'; adSet: AdSetData }
  | { type: 'reset'; payload: PlanData }
  | { type: 'savePlan' };

export const PlanContext = createContext<PlanData>(initialPlanState);

export const PlanDispatchContext = createContext<Dispatch<ReducerAction<typeof planReducer>>>(null);

export const usePlan = () => useContext<PlanData>(PlanContext);
export const usePlanDispatch = () => useContext(PlanDispatchContext);