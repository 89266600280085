import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectSession } from '../session/sessionSlice';
import styles from './ad_selection_modal.module.scss';
import { clearOrder, orderFieldUpdated, orderFormEditable } from './orderFormSlice';
import $style from './organisation_selection_modal.module.scss';
import { STATUS_PENDING_APPROVAL } from '../../lib/orders';
import {
  brandStoreConfigureFormUpdated,
  clearBrandStoreConfigureForm
} from '../brand_stores/state_management/BrandStoreSlice';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import type { ResponseScopedOrganisation } from 'v2/lib/api/access-management';

type OrganisationFilter = {
  searchTerm: string;
};

const applyFilters = (organisations: Array<ResponseScopedOrganisation>, filter: OrganisationFilter) => {
  return organisations.filter(
    (org) => !filter.searchTerm || org?.name?.toLowerCase().includes(filter.searchTerm.toLowerCase())
  );
};

interface OrganisationCardProps {
  name: string;
  logo: string;
  onClick: () => void;
}

const OrganisationCard: React.FC<OrganisationCardProps> = ({ name, logo, onClick }) => {
  return (
    <Card onClick={onClick} className={$style.selectionCard}>
      {logo ? (
        <Card.Img src={logo} alt={`${name} logo`} className={$style.selectionCardLogo} variant='top' />
      ) : (
        <div className={$style.selectionCardDiv}></div>
      )}

      <Card.Body className='p-2'>
        <h6>{name}</h6>
      </Card.Body>
    </Card>
  );
};

interface OrganisationSelectionModalProps {
  show?: boolean;
  onHide?: () => void;
  navPath?: string;
}

const OrganisationSelectionModal: React.FC<OrganisationSelectionModalProps> = ({
  show = false,
  onHide = () => { /* no implementation */ },
  navPath = '/order/create',
}) => {
  const session = useSelector(selectSession);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = session?.user;
  const userOrganisationId = user.active_organisation_id || {};
  const [filters, setFilters] = useState(() => ({
    searchTerm: '',
  }));

  const isRetailer = user?.active_organisation?.is_retailer;
  const { organisations, isPending } = useScopedOrganisations({ enabled: show });
  const [filteredOrganisations, setFilteredOrganisations] = useState<Array<ResponseScopedOrganisation>>([]);

  useEffect(() => {
    if (show && organisations && organisations.length > 0) {
      const filteredOrgs = applyFilters(organisations, filters);
      setFilteredOrganisations(filteredOrgs);
    }
  }, [show, organisations, filters]);

  const handleFilterChange = (filterType: OrganisationFilter) => setFilters({ ...filters, ...filterType });

  const handleOrganisationClick = (organisation: ResponseScopedOrganisation) => {
    const type = isRetailer ? 'supplier' : 'retailer';

    let orderDetails = {
      owner_id: organisation.id,
      supplier_id: userOrganisationId,
    };

    if (type === 'supplier') {
      orderDetails = {
        owner_id: userOrganisationId,
        supplier_id: organisation.id,
      };
    }

    dispatch(clearOrder());
    dispatch(orderFieldUpdated({ field: 'owner_id', value: orderDetails.owner_id }));
    dispatch(
      orderFieldUpdated({
        field: 'supplier_id',
        value: orderDetails.supplier_id,
      })
    );
    dispatch(orderFieldUpdated({ field: 'status', value: STATUS_PENDING_APPROVAL }));

    if (navPath === '/brandstores/home' || navPath === '/brandstores/configure') {
      dispatch(clearBrandStoreConfigureForm());
      dispatch(
        brandStoreConfigureFormUpdated({
          supplier_id: orderDetails.supplier_id,
          owner_id: orderDetails.owner_id,
          supplier_logo: organisation.logoUrl,
          supplier_name: organisation.name,
        })
      );
    }

    dispatch(orderFormEditable());

    navigate(navPath);
    onHide();
  };

  return (
    <Modal dialogClassName={styles.modal65w} show={show} onHide={onHide} style={{zIndex: 5000}}>
      <Modal.Header closeButton>Select an organisation</Modal.Header>
      <Modal.Body>
        <Container className='container-fluid'>
          <div>
            <Form.Group>
              <Form.Label>Search</Form.Label>
              <InputGroup>
                <Form.Control
                  type='text'
                  value={filters.searchTerm}
                  style={session?.theme?.components?.form_control}
                  onChange={(e) => handleFilterChange({ searchTerm: e.target.value })}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {filteredOrganisations.length > 0 && (
            <div className='my-3'>
              <h6>Your { isRetailer ? 'Suppliers' : 'Retailers' }: </h6>
              <Row className='center-block'>
                {filteredOrganisations.map((org) => {
                  return (
                    <Col xs={12} md={4} key={org.id} className='center-block my-2'>
                      <OrganisationCard name={org.name || ''} logo={org.logoUrl || ''} onClick={() => handleOrganisationClick(org)} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
          {isPending && (
            <div className='center-block'>
              <h6>Loading...</h6>
            </div>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default OrganisationSelectionModal;