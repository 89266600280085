import PropTypes from 'prop-types';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import styles from '../ad_section.module.scss';
import { Notification } from '../components/AssetsManager';
import OrderFacebookProducts from '../containers/OrderFacebookProducts';
import OrderGoogleProducts from '../containers/OrderGoogleProducts';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  ProductVariable
} from '../../brand_stores/propertiesComponents/Tabs/ContentTabComponents/ContentBlockInputs/ProductVariableComponents/ProductVariable';
import { useSelector } from 'react-redux';
import { isOwnerOrganisation, selectOwnerOrganisation, selectSupplierOrganisation } from '../orderFormSlice';
import { PRODUCT_SET_TYPE_FACEBOOK_MPA } from '../../../lib/constants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AdType } from '../../../lib/enums/ad_types';
import { useScopedProductSets } from 'v2/lib/hooks/useScopedProductSets';

const FacebookProducts = ({ errors = [], ad = {}, handleInsert = () => {} }) => {
  const [facebookFeedModal, setFacebookFeedModal] = useState(false);
  const selectedFacebookProducts = ad?.feed;
  const productSize = selectedFacebookProducts.products.length;
  const [facebookProducts, setFacebookProducts] = useState(selectedFacebookProducts);

  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  const supplierOrganisation = useSelector(selectSupplierOrganisation);
  const aisRetailer = useSelector(isOwnerOrganisation);

  const selectedOrganisation = aisRetailer ? supplierOrganisation : ownerOrganisation;
  const { productSets } = useScopedProductSets({ organisationId: selectedOrganisation.id });

  return (
    <div className='mx-auto text-center'>
      <Button
        variant='outline-secondary'
        className={`py-3 mb-2 position-relative ${errors.length > 0 ? 'border-danger' : ''}`}
        onClick={(_) => setFacebookFeedModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} size='4x' />
        <h6 className='mt-1'>Attach Products</h6>
        <Notification number={productSize} />
      </Button>
      <Modal
        size='xl'
        show={facebookFeedModal}
        onHide={(_) => {
          setFacebookFeedModal(false);
          setFacebookProducts(selectedFacebookProducts);
        }}
      >
        <Modal.Header closeButton>Select Facebook products you want to use.</Modal.Header>
        <Modal.Body>
          <OrderFacebookProducts
            value={facebookProducts}
            ad={ad}
            onChange={(value) => setFacebookProducts(value)}
            productSets={productSets}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='float-right'
            variant='primary'
            onClick={() => {
              handleInsert(facebookProducts);
              setFacebookFeedModal(false);
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const FacebookMpaProducts = ({ errors = [], ad = {}, handleInsert = () => {} }) => {
  const selectedFacebookProducts = ad?.feed;
  const [, setFacebookProducts] = useState(selectedFacebookProducts);

  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  const supplierOrganisation = useSelector(selectSupplierOrganisation);
  const aisRetailer = useSelector(isOwnerOrganisation);

  const selectedOrganisation = aisRetailer ? supplierOrganisation : ownerOrganisation;
  const { productSets: availableProductSets } = useScopedProductSets({ organisationId: selectedOrganisation.id });

  const productSets = availableProductSets?.filter(
    (set) => set?.type && set.type === PRODUCT_SET_TYPE_FACEBOOK_MPA && set.platform === 'facebook'
  );

  return (
    <div className='mx-auto text-center'>
      <ProductVariable
        handleChange={(value) => {
          //Only used in backend validation
          const selectedCatalog = value.length > 0 ? { id: value[0].catalogue_id ?? null } : {};
          setFacebookProducts({ group: selectedCatalog, products: value });
          handleInsert({ group: selectedCatalog, products: value });
        }}
        selectedValues={selectedFacebookProducts?.products ?? []}
        readOnly={false}
        constraints={{ min: 1, max: 0 /*no limit*/ }}
        variableErrors={[]}
        productSets={productSets || []}
      />
    </div>
  );
};

const GoogleProducts = ({ errors = [], feed = {}, handleInsert = () => {} }) => {
  const [googleFeedModal, setGoogleFeedModal] = useState(false);
  const selectedGoogleProducts = feed;
  const productSize = selectedGoogleProducts.products.length;
  const [googleProducts, setGoogleProducts] = useState(selectedGoogleProducts);
  const [totalProducts, setTotalProducts] = useState([]);

  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  const supplierOrganisation = useSelector(selectSupplierOrganisation);
  const aisRetailer = useSelector(isOwnerOrganisation);

  const selectedOrganisation = aisRetailer ? supplierOrganisation : ownerOrganisation;
  const { productSets } = useScopedProductSets({ organisationId: selectedOrganisation.id });

  return (
    <div className='mx-auto text-center'>
      <Button
        variant='outline-secondary'
        className={`py-3 mb-2 position-relative ${errors.length > 0 ? 'border-danger' : ''}`}
        onClick={(_) => setGoogleFeedModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} size='4x' />
        <h6 className='mt-1'>Attach Products</h6>
        <Notification number={productSize} />
      </Button>
      <Modal
        size='xl'
        show={googleFeedModal}
        enforceFocus={false}
        onHide={(_) => {
          setGoogleFeedModal(false);
          setGoogleProducts(selectedGoogleProducts);
        }}
      >
        <Modal.Header closeButton>Select Google products you want to use.</Modal.Header>
        <Modal.Body>
          <OrderGoogleProducts
            value={googleProducts}
            onChange={(value) => setGoogleProducts(value)}
            onProductsChange={(value) => setTotalProducts(value)}
            productSets={productSets}
          />
        </Modal.Body>
        <Modal.Footer>
          <span style={{ color: '#bfbfbf' }}>{new Intl.NumberFormat(undefined, {}).format(totalProducts.length)}</span>
          <Button
            className='ml-auto'
            variant='primary'
            onClick={() => {
              handleInsert(googleProducts);
              setGoogleFeedModal(false);
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export const FeedTab = ({ ad, errors, handleChange }) => {
  return (
    <div className={styles.form_section}>
      <Container>
        <Row>
          <Col sm='12'>
            {ad.platform === 'facebook' && ad.type !== AdType.FACEBOOK_MANAGED_PARTNER_AD ? (
              <FacebookProducts ad={ad} errors={errors?.feed} handleInsert={(feed) => handleChange({ feed })} />
            ) : ad.platform === 'facebook' && ad.type === AdType.FACEBOOK_MANAGED_PARTNER_AD ? (
              <DndProvider backend={HTML5Backend}>
                <FacebookMpaProducts ad={ad} errors={errors?.feed} handleInsert={(feed) => handleChange({ feed })} />
              </DndProvider>
            ) : (
              <GoogleProducts feed={ad?.feed} handleInsert={(feed) => handleChange({ feed })} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FeedTab.propTypes = {
  ad: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};