import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, InputAdornment, SearchIcon, TextField } from '@zitcha/component-library';
import { RequestSearchRoles } from "v2/lib/api/access-management";
import { TextFieldDebounced } from 'v2/components/TextFieldDebounced/TextFieldDebounced';
import { usePermissionSets } from 'v2/lib/hooks/usePermissionSets';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { useRoles } from "v2/lib/hooks/useRoles";

interface RoleManagementTableFiltersProps {
  onFilterChange: (newFilter: Partial<RequestSearchRoles>) => void;
}
type BaseOption = {
  id: number;
  name: string;
};

export const RoleManagementTableFilters: React.FC<RoleManagementTableFiltersProps> = ({ onFilterChange }) => {
  const [selectedRoles, setSelectedRoles] = useState<Array<BaseOption>>([]);
  const [roleQuery, setRoleQuery] = useState('');
  const [permissionSetQuery, setPermissionSetsQuery] = useState('');
  const permissionSets = usePermissionSets({
    query: permissionSetQuery,
  });
  const roles = useRoles({
    query: roleQuery,
  });
  const [selectedPermissionSets, setSelectedPermissionSets] = useState<Array<BaseOption>>([]);
  const debouncedRoleQuery = useDebounceSetter(setRoleQuery);
  const debouncedPermissionSetQuery = useDebounceSetter(setPermissionSetsQuery);

  useEffect(() => {
    onFilterChange({
      roleIds: selectedRoles.map((role) => role.id) as unknown as Array<number>,
      permissionSetIds: selectedPermissionSets.map((permissionSet) => permissionSet.id) as unknown as Array<number>,
    });
  }, [selectedRoles, selectedPermissionSets]);

  return (
    <Box sx={{ display: 'flex', padding: 2, justifyContent: 'space-between', gap: 2, flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Autocomplete
          multiple
          options={roles?.roles || []}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedRoles}
          onInputChange={(_event, value) => debouncedRoleQuery(value)}
          onChange={(_, data) => {
            setSelectedRoles(data);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Roles'
              placeholder={selectedRoles.length ? '' : 'All roles'}
              sx={{ minWidth: 276 }}
              variant='outlined'
              InputProps={{
                ...params.InputProps,

                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          multiple
          options={permissionSets?.data || []}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedPermissionSets}
          onInputChange={(_event, value) => debouncedPermissionSetQuery(value)}
          onChange={(_, data) => {
            setSelectedPermissionSets(data);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Permission sets'
              placeholder={selectedPermissionSets.length ? '' : 'All permissions sets'}
              sx={{ minWidth: 276 }}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <TextFieldDebounced
        label='Search'
        id='outlined-start-adornment'
        sx={{ minWidth: 276 }}
        InputProps={{
          placeholder: 'Search by role name',
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(value) => onFilterChange({ q: String(value) })}
        debounceMs={500}
      />
    </Box>
  );
};