import { RequestRole, useUpdateRole } from 'v2/lib/api/access-management';
import { useQueryClient } from '@tanstack/react-query';
import { ROLE_MANAGEMENT_ROLES_QUERY_KEY } from 'v2/constants/accessManagementConstants';

interface UpdateTeamWithHandlerParams {
  handleClose?: () => void;
}

export interface UpdateRoleData {
  roleId: number;
  data: RequestRole;
}

export const useUpdateRoleWithHandler = ({
  handleClose,
}: UpdateTeamWithHandlerParams) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateRole,
    isError,
    isSuccess,
    isPending,
    error,
  } = useUpdateRole({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [ROLE_MANAGEMENT_ROLES_QUERY_KEY]});
      },
    },
  });

  const handleUpdateRole = async (changedFields: UpdateRoleData) => {
    if (!changedFields) return;

    try {
      await updateRole(changedFields).then(() => {
        handleClose?.();
      });
    } catch (error) {
      return error;
    }
  };

  return { isError, isSuccess, isPending, error, handleUpdateRole };
};
