import { useGetRole } from 'v2/lib/api/access-management';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';

interface GetRoleFromDataParams {
  roleId: number | null;
}

export const useGetRoleFormData = ({
  roleId,
}: GetRoleFromDataParams) => {
  const { data, isLoading, isError } = useGetRole(Number(roleId));

  const role = data?.data;
  const name = role?.name;
  const description = role?.description;
  const permissionSets = role?.permissionSets || [] as Array<OptionsType>;

  return {
    name,
    description,
    permissionSets,
    isLoading,
    isError,
  };
};