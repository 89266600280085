import { useQuery } from '@tanstack/react-query';
// import Wallet from 'v2/Types/Wallets'; // currnetly using the old wallets endpoint
import { getSupplierWallets, getUserWallets } from 'lib/api/wallets';
import { selectUser } from 'features/session/sessionSlice';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import Wallet from 'v2/Types/Wallets';
import { useUserOrganisation } from './useUserOrganisation';
import { useBrands } from './useBrands';

// The useAllWallets hook is used to fetch wallets based on the user's role.
// If the user is a retailer, the wallets for all associated suppliers are fetched.
// If the user is a supplier, the wallets for the supplier organisation are fetched.
export const useAllWallets = () => {
  const user = useSelector(selectUser);
  const organisation = useUserOrganisation();
  const isRetailer = user.active_organisation?.is_retailer;
  const allBrands = useBrands();

  const fetchWallets = async (): Promise<Array<Wallet>> => {
    if (isRetailer) {
      const brandIds = allBrands.map((brand) => brand.id);
      const responses = await Promise.all(brandIds.map((id) => getSupplierWallets(id)));
      return responses.flatMap((response) => response.data?.data);
    } else {
      const response = await getSupplierWallets(organisation.id);
      return response.data?.data;
    }
  };

  const { data = [], isLoading } = useQuery<Array<Wallet>, Error>({
    queryKey: ['wallets', isRetailer, allBrands, organisation],
    queryFn: fetchWallets,
  });

  const wallets = useMemo(() => data, [data]);

  return { wallets, isLoading };
};

// The useWallets hook is used to fetch wallets for a specific brand.
// If brandId is null, an empty array is returned.
export const useWallets = (brandId: string | null) => {
  const { data, isLoading } = useQuery<Array<Wallet>, Error>({
    queryKey: ['supplierWallets', brandId],
    queryFn: () => (brandId ? getSupplierWallets(brandId) : Promise.resolve({ data: { data: [] } })),
  });

  return { wallets: data?.data?.data ?? [], isLoading };
};

export const useBrandWallets = (isUserRetailer: boolean, retailerId: string | null, brandId: string | null) => {
  const { data: supplierData, isLoading: isSupplierLoading } = useQuery<Array<Wallet>, Error>({
    queryKey: ['supplierWallets', brandId],
    queryFn: () => (brandId ? getSupplierWallets(brandId) : Promise.resolve({ data: { data: [] } })),
    enabled: isUserRetailer && !!brandId,
  });

  const { data: retailerData, isLoading: isRetailerLoading } = useQuery<Array<Wallet>, Error>({
    queryKey: ['retailerWallets', retailerId],
    queryFn: () => (retailerId ? getUserWallets(retailerId) : Promise.resolve({ data: { data: [] } })),
    enabled: !isUserRetailer && !!retailerId,
  });

  const data = isUserRetailer ? supplierData : retailerData;
  const isLoading = isUserRetailer ? isSupplierLoading : isRetailerLoading;

  return { wallets: data?.data?.data ?? [], isLoading };
};
