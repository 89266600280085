import { PerPageEnum, useGetProductSets } from 'v2/lib/api/access-management';

export const useProductSets = ({ query }: { query: string }) => {
  const { data, isLoading } = useGetProductSets({
    q: query,
    page: 1,
    perPage: PerPageEnum.NUMBER_25,
  });

  return {
    isLoading,

    data: data?.data?.map((productSet) => ({
      id: productSet.id,
      name: productSet.name,
    })) as unknown as Array<{
      id: number;
      name: string;
    }>,
  };
};
