import { useState } from 'react';
import { USER_MANAGEMENT_QUERY_KEY } from 'v2/constants/accessManagementConstants';
import { PerPageEnum, RequestSearchUsers, useSearchUsers } from 'v2/lib/api/access-management';

export const useFetchUsers = (initialUsersSearch: RequestSearchUsers = {}) => {
  const [usersSearch, setUsersSearch] = useState(initialUsersSearch);
  const [usersPage, setUsersPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState<PerPageEnum>(PerPageEnum.NUMBER_5);
  const { data, isLoading, isError } = useSearchUsers(
    {
      ...usersSearch,
      page: usersPage,
      perPage: usersPerPage,
    },
    {
      query: {
        queryKey: [USER_MANAGEMENT_QUERY_KEY, { ...usersSearch, page: usersPage, perPage: usersPerPage }],
      },
    }
  );
  return {
    users: data?.data ?? [],
    usersPagination: {
      currentPage: data?.meta?.current_page,
      from: data?.meta?.from,
      lastPage: data?.meta?.last_page,
      perPage: data?.meta?.per_page,
      to: data?.meta?.to,
      total: data?.meta?.total,
    },
    changeUsersPagination: (newPage: number, newPerPage: PerPageEnum) => {
      if (newPage > 0 && newPage !== usersPage) {
        setUsersPage(newPage);
      }
      if (newPerPage > 0 && newPerPage !== usersPerPage) {
        setUsersPerPage(newPerPage);
      }
    },
    isLoadingUsers: isLoading,
    usersError: isError,
    setUsersSearch: setUsersSearch,
  };
};
