import { Box, Chip } from "@zitcha/component-library";
import React from "react";

interface ChipListProps {
  list: Array<Record<string, string>>;
  keyId: string;
  labelId: string;
}

export const ChipList: React.FC<ChipListProps> = ({
  list,
  keyId = 'id',
  labelId = 'name',
}) => {
  return (
    <Box display='flex' gap={1} flexWrap='wrap' flexDirection='row'>
      {list.map((item: Record<string, string>) => (
        <Chip key={item[keyId]} label={item[labelId]} size='small' />
      ))}
    </Box>
  );
}