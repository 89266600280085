import { BRAND_STORE_ORDER_FORM_STATE, BRAND_STORE_ORDER_STATUS, ACTIONS } from '../constants';
import { Button, Col, Container, Image, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import styles from './brand_store_top_panel.module.scss';
import React from 'react';
import { BrandStoreStatusBadge } from '../BrandStoreStatusBadge';
import moment from 'moment';
import { formatDisplayDate } from 'helpers/DateHelpers';

const ActionsAvailableForStatus = {};
ActionsAvailableForStatus[ACTIONS.APPROVE] = [
  BRAND_STORE_ORDER_STATUS.pending_approval.status,
  BRAND_STORE_ORDER_STATUS.live_edits_pending_approval.status,
];
ActionsAvailableForStatus[ACTIONS.PROVIDE_FEEDBACK] = [
  BRAND_STORE_ORDER_STATUS.pending_approval.status,
  BRAND_STORE_ORDER_STATUS.scheduled.status,
  BRAND_STORE_ORDER_STATUS.live_edits_pending_approval.status,
];
ActionsAvailableForStatus[ACTIONS.REQUEST_APPROVAL] = [
  BRAND_STORE_ORDER_STATUS.draft.status,
  BRAND_STORE_ORDER_STATUS.live_edits_saved.status,
];

const RetailerDropDownButtons = ({ formState, onProvideFeedback, onApproval, storeStatus }) => (
  <DropdownButton variant='outline-primary' className={styles.drop_button} title=''>
    {ActionsAvailableForStatus[ACTIONS.PROVIDE_FEEDBACK].includes(storeStatus) && (
      <Dropdown.Item
        key='provide_feedback'
        disabled={formState === BRAND_STORE_ORDER_FORM_STATE.APPROVING}
        className='d-flex align-items-center'
        onClick={(e) => {
          e.preventDefault();
          onProvideFeedback();
        }}
      >
        Provide Feedback
      </Dropdown.Item>
    )}
    {ActionsAvailableForStatus[ACTIONS.APPROVE].includes(storeStatus) && (
      <Dropdown.Item
        key='approve'
        disabled={formState === BRAND_STORE_ORDER_FORM_STATE.APPROVING}
        className='d-flex align-items-center'
        onClick={(e) => {
          e.preventDefault();
          onApproval();
        }}
      >
        Approve
      </Dropdown.Item>
    )}
  </DropdownButton>
);

const EditButton = ({ formState, onEdit }) => (
  <Button
    className={styles.edit_button}
    variant='outline-primary'
    disabled={formState === BRAND_STORE_ORDER_FORM_STATE.SUBMITTING}
    onClick={onEdit}
  >
    Edit
  </Button>
);

const SubmitButton = ({ formState, storeStatus, onSubmitForReview }) => {
  let buttonText = 'Request Approval';

  if (storeStatus === BRAND_STORE_ORDER_STATUS.pending_approval.status) {
    buttonText = 'Pending Review';
  }

  if (formState === BRAND_STORE_ORDER_FORM_STATE.SUBMITTING) {
    buttonText = 'Submitting...';
  }

  return (
    <Button
      className={styles.submit_for_review_button}
      variant='outline-primary'
      disabled={
        formState === BRAND_STORE_ORDER_FORM_STATE.SUBMITTING ||
        storeStatus === BRAND_STORE_ORDER_STATUS.pending_approval.status
      }
      onClick={onSubmitForReview}
    >
      {buttonText}
    </Button>
  );
};

const CancelButton = ({ formState, onCancel }) => (
  <Button
    className={styles.cancel_button}
    variant='outline-primary'
    disabled={formState === BRAND_STORE_ORDER_FORM_STATE.SAVING}
    onClick={onCancel}
  >
    Cancel
  </Button>
);

const SaveButton = ({ formState, isDirty, onSave }) => (
  <Button
    className={styles.save_button}
    variant='outline-primary'
    disabled={!isDirty || formState === BRAND_STORE_ORDER_FORM_STATE.SAVING}
    onClick={onSave}
  >
    {formState === BRAND_STORE_ORDER_FORM_STATE.SAVING ? 'Saving...' : 'Save'}
  </Button>
);

export const BrandStoreTopPanel = ({
  brandStoreOrder,
  orderPermissions,
  isRetailer,
  formState,
  isDirty,
  onEdit,
  onCancel,
  onSubmitForReview,
  onSave,
  onProvideFeedback,
  onApproval,
}) => {
  const {
    name: storeName,
    id: brandStoreId,
    status: storeStatus,
    creator_name: createdBy,
    created_at: createdDate,
    updated_at: editedDateTime,
    retailer_logo: retailerLogo,
    supplier_logo: supplierLogo,
  } = brandStoreOrder;
  const logo = isRetailer ? supplierLogo : retailerLogo;
  return (
    <Container fluid className='border-bottom'>
      <Row className='mb-3'>
        <Col lg='1' md='2'>
          <div style={{ maxHeight: '3rem', overflow: 'hidden', marginBottom: '5px' }}>
            <Image
              className='w-100'
              style={{ maxWidth: '8rem', maxHeight: '3rem', objectFit: 'scale-down' }}
              src={logo}
            />
          </div>
          <div>
            <BrandStoreStatusBadge status={storeStatus} />
          </div>
        </Col>
        <Col lg='7' md='6' className='border-left'>
          <Row>
            <Col>
              <h2>{storeName}</h2>
            </Col>
          </Row>
          <Row>
            <Col style={{ fontSize: '0.85rem' }}>
              <span>ORDER # {brandStoreId}</span>
              <span className='ml-3 text-muted'>
                Created by {createdBy} on {formatDisplayDate(createdDate)}. Last edited{' '}
                {moment(editedDateTime).fromNow()}.
              </span>
            </Col>
          </Row>
        </Col>
        <Col lg='4'>
          <Row className='float-right'>
            <Col>
              {(formState === BRAND_STORE_ORDER_FORM_STATE.VIEW ||
                formState === BRAND_STORE_ORDER_FORM_STATE.SUBMITTING ||
                formState === BRAND_STORE_ORDER_FORM_STATE.APPROVING) && (
                <>
                  {isRetailer &&
                    (ActionsAvailableForStatus[ACTIONS.PROVIDE_FEEDBACK].includes(storeStatus) ||
                      ActionsAvailableForStatus[ACTIONS.APPROVE].includes(storeStatus)) &&
                      orderPermissions.canApproveOrRejectOrders && (
                      <RetailerDropDownButtons
                        formState={formState}
                        onApproval={onApproval}
                        onProvideFeedback={onProvideFeedback}
                        storeStatus={storeStatus}
                      />
                    )}
                  {storeStatus !== BRAND_STORE_ORDER_STATUS.expired.status && orderPermissions.canEditPendingOrders && (
                    <EditButton formState={formState} onEdit={onEdit} />
                  )}
                  {ActionsAvailableForStatus[ACTIONS.REQUEST_APPROVAL].includes(storeStatus) && orderPermissions.canRequestOrderReview && (
                    <SubmitButton
                      formState={formState}
                      storeStatus={storeStatus}
                      onSubmitForReview={onSubmitForReview}
                    />
                  )}
                </>
              )}

              {(formState === BRAND_STORE_ORDER_FORM_STATE.EDIT ||
                formState === BRAND_STORE_ORDER_FORM_STATE.SAVING) && (
                <>
                  <CancelButton formState={formState} onCancel={onCancel} />
                  <SaveButton formState={formState} isDirty={isDirty} onSave={onSave} />
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};