import React, { useEffect, useState } from 'react';
import PasswordIcon from '@mui/icons-material/Password';
import { Box, Button, Modal } from '@zitcha/component-library';
import { useForm } from 'react-hook-form';
import {
  RequestInviteUser,
  RequestUpdateUser,
  Response400BadRequest,
  Response403Forbidden,
  Response404NotFound,
  ResponseUser,
  ResponseUserInvite,
} from 'v2/lib/api/access-management';
import { LabelValuePair } from './LabelValuePair';
import { ErrorType } from 'v2/lib/axios/axiosMainInstance';
import { mapUserData } from './utils/userDataMapper';
import { getConfig } from './utils/getModalConfig';

export type Mode = 'view' | 'edit' | 'create';

type UsersModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: ({ userId, data }: { userId: ResponseUser['id']; data: RequestUpdateUser }) => Promise<ResponseUser>;
  onSendPasswordReset: (email: string) => Promise<void>;
  onInviteUser: ({ data }: { data: RequestInviteUser }) => Promise<ResponseUserInvite>;
  user: Partial<ResponseUser> | null;
  initialMode: Mode;
  validationErrors?: ErrorType<Response400BadRequest | Response404NotFound | Response403Forbidden> | null;
  disableActions?: boolean;
};
const defaultUser = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  teamsRoles: [] as ResponseUser['teamsRoles'],
  isActive: true,
};

export const UsersModal = ({
  open,
  onClose,
  user,
  initialMode,
  onSendPasswordReset,
  onSave,
  validationErrors,
  onInviteUser,
  disableActions,
}: UsersModalProps) => {
  const [mode, setMode] = useState<Mode>(initialMode);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: user || defaultUser,
    values: user || defaultUser,
  });

  const isViewMode = mode === 'view';
  const isCreateMode = mode === 'create';

  type FormData = Partial<ResponseUser>;

  const handleCreateNewUser = async (data: FormData | null) => {
    if (data) {
      try {
        await onInviteUser({ data: mapUserData(data) });
        onClose();
      } catch (e) {
        return e;
      }
    }
  };

  const handleCreateOrEditUser = (data: FormData | null, isPrimary: boolean) => {
    if (isPrimary) {
      isCreateMode ? handleCreateNewUser(data) : setMode('edit');
    } else {
      onClose();
    }
  };

  const prepareAndSaveUserData = async (user: Partial<ResponseUser>, data: FormData | null, isPrimary: boolean) => {
    const payload = {
      userId: String(user.id),
      data: isPrimary ? (data ? mapUserData(data) : ({} as RequestUpdateUser)) : mapUserData(user, true),
    };
    await onSave(payload);
    onClose();
  };

  const handleAction = async (data: ResponseUser | null, isPrimary: boolean) => {
    if (isViewMode || isCreateMode) {
      handleCreateOrEditUser(data, isPrimary);
      return;
    }

    if (user) {
      try {
        await prepareAndSaveUserData(user, data, isPrimary);
      } catch (e) {
        return e;
      }
    }
  };

  const handlePrimaryAction = async (data: ResponseUser) => {
    await handleAction(data, true);
  };

  const handleSecondaryAction = async () => {
    await handleAction(null, false);
  };
  const handleResetSendRequest = async () => {
    await onSendPasswordReset(String(user?.email));
  };

  const formValues = getValues();
  const displayedValues = isViewMode ? user : formValues;
  const isActive = displayedValues?.isActive as boolean;
  const {
    primaryButtonLabel,
    secondaryButtonLabel,
    secondaryButtonColor,
    secondaryButtonVariant,
    primaryButtonIcon: Icon,
    title,
  } = getConfig({
    isViewMode,
    isCreateMode,
    isActive,
  });

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  useEffect(() => {
    if (validationErrors) {
      const errors = validationErrors?.response?.data?.errors;
      if (errors) {
        Object.entries(errors).forEach(([key, value]: [string, Array<string>]) => {
          setError(key as keyof ResponseUser, {
            type: 'manual',
            message: value[0],
          });
        });
      }
    }
  }, [validationErrors]);

  return (
    <Modal size='medium' open={open} onClose={onClose} title={title}>
      <Box>
        <form onSubmit={handleSubmit(handlePrimaryAction)}>
          <Box pt={2} pb={2} display='flex' gap={1.5} flexDirection='column'>
            {Object.entries(displayedValues ?? {})
              .filter(([key]) => key !== 'id' && key !== 'isActive')
              .map(([key, value]) => (
                <LabelValuePair
                  key={key}
                  field={key as keyof ResponseUser}
                  value={value as string | ResponseUser['teamsRoles'] | undefined}
                  registerToform={register}
                  mode={mode}
                  teamRolesSetter={setValue}
                  watch={watch}
                  errors={errors}
                />
              ))}
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Button
              onClick={handleSecondaryAction}
              variant={secondaryButtonVariant}
              color={secondaryButtonColor}
              disabled={disableActions}
            >
              {secondaryButtonLabel}
            </Button>
            <Box display='flex' gap={1}>
              {!isCreateMode ? (
                <Button
                  variant='text'
                  color='primary'
                  startIcon={<PasswordIcon />}
                  onClick={handleResetSendRequest}
                  disabled={disableActions}
                >
                  Send password reset
                </Button>
              ) : null}
              <Button startIcon={<Icon />} type='submit' disabled={disableActions}>
                {primaryButtonLabel}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};