import type { AdSet, Bundle, CalendarPeriod } from 'v2/lib/api/ad-management';
import type { InventoryReport } from 'v2/lib/api/inventory';

type Placement = {
  value: string;
  slot: number;
  totalSlots: number;
};

type InventoryReportItem = {
  id: string;
  name: string;
  price: number;
  availablePlacements: number;
  mediaSpace?: { name: string };
  adSets: Array<AdSet & { bundles: Array<Bundle> }>;
};

type ColumnItem = {
  id: string;
  placement: Placement;
  rate: number;
  mediaType: string;
  adSet?: AdSet & { bundles: Array<Bundle> };
};

type RowItem = {
  id: string;
  placement: Placement;
  rate: number;
  mediaType: string;
  [calendarPeriodId: string]: unknown;
};

export const generateRows = ({
  inventoryReportData,
  calendarPeriodsData,
}: {
  inventoryReportData: (Array<unknown> & Array<InventoryReport>) | undefined;
  calendarPeriodsData: (Array<unknown> & Array<CalendarPeriod>) | undefined;
}) => {
  if (!inventoryReportData || !calendarPeriodsData) return [];

  // Create columns for each calendar period
  const columns: Array<Array<ColumnItem>> = calendarPeriodsData.map((calPeriod: CalendarPeriod) => {
    return inventoryReportData.flatMap((item: InventoryReportItem) => {
      const relevantAdSets = (item.adSets || []).filter(
        (adSet) =>
          adSet.status !== 'new' &&
          adSet.status !== 'draft' &&
          adSet.bundles?.some((bundle) => calPeriod.id === bundle?.calendarPeriod?.id)
      );

      return Array.from({ length: item.availablePlacements }, (_, i) => ({
        id: `${calPeriod.id}-${item.id}-${i}`,
        placement: { value: item.name, slot: i + 1, totalSlots: item.availablePlacements },
        rate: item.price,
        mediaType: item.mediaSpace?.name || '',
        adSet: relevantAdSets[i],
      }));
    });
  });

  // Transpose columns into rows
  const rows: Array<RowItem> = columns[0]?.map((_, rowIndex) => {
    const rowObject: RowItem = {
      id: `row-${rowIndex}`,
      placement: columns[0][rowIndex].placement,
      rate: columns[0][rowIndex].rate,
      mediaType: columns[0][rowIndex].mediaType,
    };

    calendarPeriodsData.forEach((calPeriod: CalendarPeriod, columnIndex) => {
      rowObject[String(calPeriod.id)] = columns[columnIndex][rowIndex].adSet;
    });

    return rowObject;
  });

  return rows;
};
