import React, { useState } from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useProductSets } from 'v2/lib/hooks/useProductSets';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';

type ProductSetAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const ProductSetAutocomplete: React.FC<ProductSetAutocompleteProps> = (props) => {
  const [productSetsQuery, setProductSetsQuery] = useState('');
  const productSets = useProductSets({
    query: productSetsQuery,
  });
  const debouncedProductSetQuery = useDebounceSetter(setProductSetsQuery);

  return (
    <AutocompleteFactory
      labelText={props.labelText || 'Product sets'}
      placeholderText={props.placeholderText || 'All product sets'}
      {...props}
      isLoading={productSets.isLoading}
      options={productSets.data || []}
      debouncedQuery={debouncedProductSetQuery}
    />
  );
};