import React, { useState } from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { useGetPlans } from 'v2/lib/api/access-management';

type PlanAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const PlanAutocomplete: React.FC<PlanAutocompleteProps> = (props) => {
  const [plansQuery, setPlansQuery] = useState('');
  const { data, isLoading } = useGetPlans({
    q: plansQuery,
    page: 1,
    perPage: 10,
  });
  const debouncedPlanQuery = useDebounceSetter(setPlansQuery);

  return (
    <AutocompleteFactory
      labelText={props.labelText || 'Plans'}
      placeholderText={props.placeholderText || 'All plans'}
      isLoading={isLoading}
      {...props}
      options={data?.data || []}
      debouncedQuery={debouncedPlanQuery}
    />
  );
};