import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { updateSettings } from '../lib/api';
import FacebookCustomAudiences from '../features/organisation_settings/containers/FacebookCustomAudiences';
import FacebookProductPermissions from '../features/organisation_settings/containers/FacebookProductPermissions';
import GoogleProductPermissions from '../features/organisation_settings/containers/GoogleProductPermissions';
import GoogleRemarketingAudiences from '../features/organisation_settings/containers/GoogleRemarketingAudiences';
import OrganisationWallets from '../features/wallets/OrganisationWallets';
import {
  getMPASupplierSettings,
  selectFacebookMPA,
  selectOrganisation,
  selectReadPermissions,
  selectStatus,
  updateOrganisation
} from '../features/organisation_settings/organisationSettingsSlice';
import { clearSession, selectAdTypes, selectSession } from '../features/session/sessionSlice';
import FacebookSavedAudiences from '../features/organisation_settings/containers/FacebookSavedAudiences';
import FacebookLookalikeAudiences from '../features/organisation_settings/containers/FacebookLookalikeAudiences';
import MetricPermissions from '../features/organisation_settings/components/MetricPermissions';
import AdSettings from '../features/organisation_settings/components/AdSettings';
import { FacebookMPA } from '../features/organisation_settings/components/FacebookMPA';
import Breadcrumbs from './components/Breadcrumbs';
import styled from 'styled-components';
import {
  CHANNELS_MANAGEMENT_FACEBOOK,
  CHANNELS_MANAGEMENT_GOOGLE,
  MANAGE_SUPPLIER_SETTINGS,
  WALLET_MANAGEMENT
} from 'lib/permissions';
import { findDifferentFalseyKeys } from '../lib/util/objects';
import { trackEnableChannel } from '../helpers/segment';
import { atof } from '../lib/financial';
import { AdType } from '../lib/enums/ad_types';
import { MPA_STATUS_COMPLETED } from '../lib/constants';
import { SupplierSettingsTabBtn as Tab } from './SupplierSettingsTabBtn';
import { usePermissions } from 'v2/lib/hooks/usePermissions';

const StyledImage = styled.img`
  width: 100%;
  max-width: 300px;
  display: none;
`;

const StyledLightText = styled.p`
  color: #3a3b4f;
`;

const StyledSave = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection {
    background: #7155ff;
    border-color: #644bf8 !important;
  }
`;

const LinkButton = styled.button`
  all: unset;
  color: #7155ff;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #3109ff;
    text-decoration: underline;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const METRICS_FORM_KEYS = {
  METRICS_CLICKS: 'clicks',
  METRICS_VIEW_RATE: 'view_rate',
  METRICS_VIEWS: 'views',
  METRICS_IMPRESSIONS: 'impressions',
  METRICS_REACH: 'reach',

  METRICS_CPC: 'cpc',
  METRICS_CPM: 'cpm',
  METRICS_CPV: 'cpv',
  METRICS_CTR: 'ctr',
  METRICS_SPEND: 'spend',
  METRICS_PURCHASE_ROAS: 'total_roas',
  METRICS_CONVERSION_VALUE: 'total_conversion_value',
  METRICS_LIFETIME_BUDGET: 'lifetime_budget',
  METRICS_DAILY_BUDGET: 'daily_budget',

  METRICS_ONLINE_SALES: 'online_sales',
  METRICS_OFFLINE_SALES: 'offline_sales',

  METRICS_WEBADS_TOTAL_CONVERSIONS: 'webads_total_conversions_value',
  METRICS_WEBADS_ASSOCIATED_CONVERSIONS: 'associated_conversions_value',
  METRICS_WEBADS_DIRECT_CONVERSIONS: 'direct_conversions_value',
  METRICS_WEBADS_TOTAL_CONVERSION_COUNT: 'webads_total_conversions_count',
};

const PERMISSIONS_FORM_KEYS = {
  PERMISSIONS_BID_AMOUNT: 'bid_amount',
  PERMISSIONS_BUDGET: 'budget',
  PERMISSIONS_SKEW_METRICS: 'skew_metrics',
  PERMISSIONS_SKEW_METRICS_RETAILER: 'skew_metrics_retailer',
};

const SupplierSettings = () => {
  const { organisation_id } = useParams();
  const navigate = useNavigate();
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const organisation = useSelector(selectOrganisation);
  const session = useSelector(selectSession);
  const [adSettings, setAdSettings] = useState(null);
  const permissions = organisation?.read_permissions;
  const read_permissions = useSelector(selectReadPermissions);
  const facebookMPASettings = useSelector(selectFacebookMPA);
  const facebookMPA = useSelector(selectFacebookMPA);
  const isMpaEligible = facebookMPA?.eligibility?.is_eligible;
  const isMpaOnboarded = facebookMPA?.status === MPA_STATUS_COMPLETED;
  const [msg, setMsg] = useState(null);
  const allAdTypes = useSelector(selectAdTypes);

  const { hasPermission } = usePermissions();
  const canManageChannelFacebook = hasPermission(CHANNELS_MANAGEMENT_FACEBOOK);
  const canManageChannelGoogle = hasPermission(CHANNELS_MANAGEMENT_GOOGLE);
  const canManageWallets = hasPermission(WALLET_MANAGEMENT);
  const canManageSupplierSettings = hasPermission(MANAGE_SUPPLIER_SETTINGS);

  const hasFacebookMPAEnabled = session?.feature_flags?.ad_types?.facebook_managed_partner_ad || false;
  const adTypes = allAdTypes.filter(
    (a) =>
      a.control_id !== AdType.FACEBOOK_MANAGED_PARTNER_AD ||
      (hasFacebookMPAEnabled && facebookMPASettings && !facebookMPASettings.disabled && isMpaEligible && isMpaOnboarded)
  );

  const [tab, setTab] = useSearchParams();
  const currentTab = tab.get('tab');
  useEffect(() => {
    if (!currentTab) {
      setTab({
        tab: 'permissions',
      });
    }
  }, [currentTab]);

  useEffect(() => {
    dispatch(updateOrganisation(organisation_id));
    if (hasFacebookMPAEnabled) {
      dispatch(getMPASupplierSettings(organisation_id));
    }
  }, [dispatch, organisation_id, hasFacebookMPAEnabled]);
  useEffect(() => {
    setAdSettings(organisation?.settings);
  }, [organisation, organisation_id]);

  const goBackToSuppliers = () => {
    navigate('/settings/suppliers?tab=supplier-settings');
  };

  const methods = useForm({
    mode: 'onSubmit',
  });

  if ((status === 'loading' || status === 'idle') && !organisation.id) {
    return (
      <Container className='pt-4'>
        <LoadingContainer>
          <Spinner animation='border' />
          &nbsp; Loading organisation, please wait...
        </LoadingContainer>
      </Container>
    );
  }

  if (!canManageSupplierSettings) {
    return <Navigate to='/organisation/settings' replace />;
  }

  const breadcrumbItems = [
    {
      path: '/settings/suppliers',
      title: 'Supplier Settings',
    },
    {
      path: `/settings/suppliers/${organisation_id}`,
      title: `${organisation.name}`,
      active: true,
    },
  ];

  const {
    setError,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const updateAllSettings = (key) => {
    const currentValues = getValues();
    let selectedKey = null;
    if (key === 'all_margin') {
      selectedKey = 'margin';
    } else if (key === 'all_min_budget') {
      selectedKey = 'min_budget';
    } else {
      return;
    }

    adTypes.forEach((adType) => {
      if (currentValues?.[adType?.control_id]) {
        setValue(`${adType.control_id}.${selectedKey}`, currentValues[key], {
          shouldValidate: true,
        });
      }
    });
    setValue(key, '');
  };

  const onSubmit = async (data) => {
    setMsg(null);

    const adSettings = {
      margin: {},
      min_budget: {},
      enabled_ads: {},
    };

    adTypes
      .filter(
        (a) =>
          a.control_id !== AdType.FACEBOOK_MANAGED_PARTNER_AD ||
          (hasFacebookMPAEnabled && facebookMPASettings && !facebookMPASettings.disabled && isMpaEligible)
      )
      .forEach((adType) => {
        if (data?.[adType?.control_id]) {
          adSettings.margin[adType.control_id] = data[adType.control_id].margin;
          if (data[adType.control_id].min_budget) {
            adSettings.min_budget[adType.control_id] = data[adType.control_id].min_budget;
          }
          adSettings.enabled_ads[adType.control_id] = data[adType.control_id].enabled;
        }
      });

    const updatedPermissions = {
      metrics: {
        insights: {},
      },
    };
    Object.values(METRICS_FORM_KEYS).forEach((key) => {
      updatedPermissions.metrics.insights[key] = data?.[key];
    });
    Object.values(PERMISSIONS_FORM_KEYS).forEach((key) => {
      updatedPermissions.metrics[key] = data?.[key];
    });

    const settings = { ...(organisation.settings || {}), ...adSettings };
    const updatedReadPermissions = { ...(read_permissions || {}), ...updatedPermissions };

    try {
      const res = await updateSettings(organisation.id, {
        settings,
        read_permissions: updatedReadPermissions,
      });
      findDifferentFalseyKeys(
        organisation?.settings?.enabled_ads || {},
        res?.data?.data?.settings?.enabled_ads || {},
        'yes'
      ).forEach((adformat) => {
        const minBudget = atof(res?.data?.data?.settings?.min_budget?.[adformat]);
        const margin = atof(res?.data?.data?.settings?.margin?.[adformat]);
        const platform = adTypes.find((adType) => adType.control_id === adformat)?.platform;
        trackEnableChannel(adformat, platform, organisation, margin, minBudget);
      });
      setMsg({
        type: 'success',
        body: 'Settings have been saved successfully.',
      });
      dispatch(updateOrganisation(organisation_id)); //<-if the setting were updated, we need to ping redux store to get recent changes
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(clearSession());
        navigate('/login');
      } else {
        const errors = e?.response?.data?.errors;
        const keys = Object.keys(errors || {});
        if (!errors || !keys.length) {
          setMsg({
            type: 'danger',
            body: 'Could not save the settings, please try again.',
          });
        } else {
          keys.forEach((errorKey) => {
            const key = errorKey.replace('settings.', '');
            const splitKey = key.split('.');
            if (splitKey.length === 1) {
              setError(splitKey[0], {
                type: 'manual',
                message: errors[`settings.${key}`][0],
              });
            }
          });
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Manage {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Container className='pt-4'>
        <FormProvider {...methods}>
          <Breadcrumbs items={breadcrumbItems} />
          <LinkButton onClick={goBackToSuppliers}> &#8592; Back</LinkButton>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {msg && (
              <Alert className='mt-3' variant={msg.type}>
                {msg.body}
              </Alert>
            )}

            <Row className='mb-3'>
              <Col>
                <div className='d-flex pt-3 pb-3 justify-content-between'>
                  <div>
                    <h3>Supplier Settings: {organisation.name}</h3>
                  </div>
                  {organisation.logo && (
                    <div className='d-flex'>
                      <StyledImage src={organisation.logo} alt={`${organisation.name} logo`} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

              <Row className='my-3 align-items-center'>
                <Col>
                  <StyledLightText className='m-0'>Customise settings for {organisation.name}</StyledLightText>
                </Col>
                <Col>
                  <StyledSave
                    variant='primary'
                    className='mr-auto px-5 py-2 float-right'
                    type='submit'
                    disabled={isSubmitting || !!Object.keys(errors).length}
                  >
                    <span className='small d-flex'>
                      {isSubmitting && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          className='mr-2'
                        />
                      )}
                      <span>Save Changes</span>
                    </span>
                  </StyledSave>
                </Col>
              </Row>
              <TabControls>
                <Tab id='permissions' />
                <Tab id='ad-type' />
                {canManageChannelFacebook && <Tab id='facebook' />}
                {canManageChannelGoogle && <Tab id='google' />}
                {canManageWallets && <Tab id='wallets' />}
              </TabControls>

              <TabContent>
                <Col sm={12}>
                  <Row className='mb-3'>
                    <Col>
                      <div className='rounded'>
                        {currentTab === 'permissions' && <MetricPermissions permissionsData={permissions} />}
                        {currentTab === 'ad-type' && (
                          <>
                            <h4>Extension Channels</h4>
                            <p>
                              Pro tip: Any value that you override for the supplier in this table, will have a blue
                              border around it.
                            </p>
                            <AdSettings
                              adTypes={adTypes}
                              adSettings={adSettings}
                              updateAllSettings={updateAllSettings}
                              isSupplierSettingsPage={true}
                            />
                          </>
                        )}
                        {currentTab === 'facebook' && canManageChannelFacebook && (
                          <>
                            <div className='d-flex align-items-center'>
                              <h3 className='flex-grow-1'>Facebook Permissions</h3>
                            </div>
                            <FacebookCustomAudiences controlId={`${organisation.id}-facebook-custom-audiences`} />
                            <FacebookLookalikeAudiences controlId={`${organisation.id}-facebook-lookalike-audiences`} />
                            <FacebookSavedAudiences controlId={`${organisation.id}-facebook-saved-audiences`} />
                            <FacebookProductPermissions controlId={`${organisation.id}-facebook-products`} />
                            {hasFacebookMPAEnabled && facebookMPASettings && !facebookMPASettings.disabled && (
                              <FacebookMPA controlId={`${organisation_id}-facebook-mpa`} />
                            )}
                          </>
                        )}
                        {currentTab === 'google' && canManageChannelGoogle && (
                          <>
                            <div className='d-flex align-items-center'>
                              <h3 className='flex-grow-1'>Google Permissions</h3>
                            </div>
                            <GoogleRemarketingAudiences controlId={`${organisation.id}-google-remarketing-audiences`} />
                            <GoogleProductPermissions controlId={`${organisation.id}-google-products`} />
                          </>
                        )}
                        {currentTab === 'wallets' && canManageWallets && (
                          <OrganisationWallets organisation={organisation} />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </TabContent>
            </Form>
          </FormProvider>
        </Container>
    </>
  );
};

export default SupplierSettings;
const TabContent = styled.div`
  width: 100%;
  height: 100%;
  .col {
    padding: 0;
  }
`;
const TabControls = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
`;