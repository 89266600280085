import { connect } from 'react-redux';
import { selectReadOnly } from '../orderFormSlice';
import ProductSelector from '../../common/products/ProductSelector';

const mapStateToProps = (state, ownProps) => {
    const availableProductSets = ownProps?.productSets || [];
    const productSets = availableProductSets.filter((set) => set.platform === 'facebook');

    return {
        readOnly: selectReadOnly(state),
        productSets: productSets,
    }
}

export default connect(mapStateToProps)(ProductSelector);