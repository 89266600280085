import React from 'react';
import { ListItem } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {
  useTheme,
  TextField,
  InputAdornment,
  Box,
  Typography,
  SearchIcon,
  Modal,
  List,
} from '@zitcha/component-library';
import { ResponseScopedOrganisation } from 'v2/lib/api/access-management';
interface OrgTileProps {
  id: string | undefined;
  name: string | undefined;
  logoUrl: string | undefined;
  onClick: (id: string) => void;
}

const OrgTile = ({ id, name, logoUrl, onClick }: OrgTileProps) => {
  const handleClick = () => {
    if (!id) return;
    onClick(id);
  };
  const hasValidLogo = logoUrl && logoUrl.length > 0;

  return (
    <div className='twd-flex twd-items-center twd-gap-2 twd-p-2 twd-cursor-pointer' onClick={handleClick}>
      {hasValidLogo ? (
        <img src={logoUrl} alt={name} className='twd-object-contain twd-max-h-8 twd-max-w-16' />
      ) : (
        <ImageNotSupportedIcon
          sx={{
            maxHeight: '2rem',
            width: '4rem',
          }}
        />
      )}
      <Typography variant='body1'>{name}</Typography>
    </div>
  );
};

export const OrganisationSelectionModal = ({
  isOpen,
  heading,
  onClose,
  onClick,
  retailers,
}: OrganisationSelectionModalProps) => {
  const SEARCH_FIELD_THRESHOLD = 5;
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredOrganisations = React.useMemo(() => {
    return retailers?.filter((organisation) => organisation?.name?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [retailers, searchTerm]);
  return (
    <Modal open={isOpen} onClose={onClose} size='medium' title={heading}>
      <Box>
        {retailers.length >= SEARCH_FIELD_THRESHOLD && (
          <TextField
            id='search-organisation'
            value={searchTerm}
            type='search'
            placeholder='Search Organisation'
            onChange={(e) => setSearchTerm(e.target.value)}
            variant='outlined'
            fullWidth
            sx={{
              marginTop: '1rem',
              marginBottom: '1.5rem',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        <List
          component='ul'
          disablePadding
          sx={{
            pl: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: '1rem',
            marginTop: '1rem',
            maxHeight: '25rem',
            overflowY: 'auto',
          }}
        >
          {filteredOrganisations.map((organisation) => (
            <ListItem alignItems='flex-start' key={organisation.id}>
              <OrgTile
                id={organisation?.id}
                name={organisation?.name}
                logoUrl={organisation?.logoUrl}
                onClick={(id) => onClick(id)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export type OrganisationSelectionModalOnClickHandler = (id: string) => void;

interface OrganisationSelectionModalProps {
  isOpen: boolean;
  heading: string;
  onClose: () => void;
  onClick: OrganisationSelectionModalOnClickHandler;
  retailers: Array<ResponseScopedOrganisation>;
}
