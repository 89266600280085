import React, { useMemo } from 'react';
import { Box, GridRenderCellParams } from '@zitcha/component-library';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { AdSetStatusEnum, BundleCalendarPeriod } from 'v2/lib/api/ad-management';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';

function getFormattedSchedule(calendarPeriod: BundleCalendarPeriod | undefined) {
  if (!calendarPeriod) return '';

  return `${calendarPeriod?.name} (${formatDisplayDate(calendarPeriod?.startAt)} - ${formatDisplayDate(calendarPeriod?.endAt)})`;
}

const ScheduleCell = ({
  ...props
}: GridRenderCellParams<any, string> & {
  retailerId: string;
}) => {
  const { row } = props;
  const { data: bundle, isLoading } = useSafeGetBundle(row.bundleIds?.[0]);
  const calendarPeriod: BundleCalendarPeriod | undefined = useMemo(() => bundle?.data?.calendarPeriod, [bundle]);
  const isClashed = row.status === AdSetStatusEnum.clashed;

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box
      sx={{
        backgroundColor: isClashed ? '#FDEDED' : '',
        width: '100%',
        height: '100%',
        paddingLeft: '12px',
      }}
    >
      <div>{getFormattedSchedule(calendarPeriod)}</div>
    </Box>
  );
};

export default ScheduleCell;