import { useState } from 'react';
import type { ResponseUser } from 'v2/lib/api/access-management';
import type { Mode } from '../UsersModal';

export interface ModalState {
  open: boolean;
  user: Partial<ResponseUser> | null;
  mode: Mode;
}
export const useUserManagementModalState = () => {
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    user: null,
    mode: 'create',
  });
  const closeModal = () => {
    setModalState({ user: null, mode: 'create', open: false });
  };
  const openModal = ({ user, mode }: { user: Partial<ResponseUser> | null; mode: Mode }) => {
    setModalState({ mode, open: true, user });
  };

  return {
    modalState,
    closeModal,
    openModal,
  };
};
