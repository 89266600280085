import { AddIcon, Box, Button, FactCheckIcon, ReceiptIcon, Typography, useTheme } from '@zitcha/component-library';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OrganisationSelectionModal from 'features/order_form/OrganisationSelectionModal';
import { MenuItemProps, MenuItems } from './ButtonMenuItems';
import { FeatureFlags } from 'v2/lib/api/system-api';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import { CREATE_ORDERS, PLAN_CREATE } from 'lib/permissions';

interface AddPlanBtnProps {
  featuresEnabled: FeatureFlags | undefined;
  onlyIcon?: boolean;
}

export const AddPlanBtn = ({ featuresEnabled, onlyIcon = false }: AddPlanBtnProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showOrganisationsModal, setOrganisationsModal] = useState(false);

  const openOrganisationModal = useCallback((e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    setAnchorElement(null);
    setOrganisationsModal(true);
  }, []);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElement) {
      setAnchorElement(event.currentTarget);
    } else {
      setAnchorElement(null);
    }
  }

  const { hasPermission } = usePermissions();
  const canCreatePlans = hasPermission(PLAN_CREATE);
  const canCreateOrders = hasPermission(CREATE_ORDERS);

  const handleClose = () => {
    setAnchorElement(null);
  };
  const menuItems: Array<MenuItemProps> = [];
  // TODO: update the commented code when we have a order feature flag
  if (/*featuresEnabled?.feature_orders*/ canCreateOrders) {
    menuItems.push({ icon: <ReceiptIcon />, text: 'New Order', onClick: (e) => openOrganisationModal(e) });
  }
  if (featuresEnabled?.feature_planning && canCreatePlans) {
    menuItems.push({ icon: <FactCheckIcon />, text: 'New Plan', onClick: () => navigate('/plans-ad-sets/new-plan') });
  }

  return menuItems.length > 0 && (
    <Box
      sx={{
        marginTop: '1rem',
        marginBottom: '2rem',
      }}
    >
      {onlyIcon ? (
        <Button
          variant='contained'
          color='primary'
          sx={{
            minWidth: '2.65rem',
            width: '2.65rem',
            height: '2.65rem',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={handleClick}
        >
          <AddIcon />
          <MenuItems
            anchorElement={anchorElement}
            items={menuItems}
            isOpen={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          />
        </Button>
      ) : (
        <>
          <Button
            color='primary'
            variant='contained'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '100%',
              color: theme.palette.primary.contrastText,
              justifyContent: 'center',
              fontWeight: '400',
              lineHeight: '150%',
              letterSpacing: '0.00938rem',
              padding: '.65rem 1.375rem',
              height: '2.65rem',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
          >
            <AddIcon />
            <Typography variant='button'>New</Typography>
          </Button>
          <MenuItems
            anchorElement={anchorElement}
            items={menuItems}
            isOpen={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          />
        </>
      )}
      <OrganisationSelectionModal
        key={Math.random()}
        show={showOrganisationsModal}
        onHide={() => setOrganisationsModal(false)}
        navPath={'/order/create'}
      />
    </Box>
  );
}