import React, { useEffect, useMemo, useState } from 'react';
import { SelectedProductsList } from './SelectedProductsList';
import { ProductSetSelectorAndFilter } from './ProductSetSelectorAndFilter';
import { Box, Modal, Button } from '@zitcha/component-library';
import { Product, ProductSet } from 'features/common/types';
import { useGetProducts } from 'v2/lib/hooks/useGetProducts';
import { ProductsContent } from './ProductsContent';
import { ProductSelectorInfoSection } from './ProductSelectorInfoSection';
import { ProductSelectorViewMode } from './ToggleViewSwitch';
import { ProductsContentControls } from './ProductsContentControls';

export interface ProductSelectorModalProps {
  isOpen: boolean;
  handleClose: () => void;
  productSets: Array<ProductSet>;
  minProducts: number;
  maxProducts?: number;
  initialSelectedProducts: Array<Product>;
  handleSave: ProductSelectorModalSaveHandler;
  infoSectionData?: {
    mediaSpace: string;
    schedule: string;
    placement: string;
  };
}

// Use the types in your component
export const ProductSelectorModal: React.FC<ProductSelectorModalProps> = ({
  isOpen,
  handleClose,
  minProducts,
  maxProducts,
  initialSelectedProducts,
  productSets,
  handleSave,
  infoSectionData,
}) => {
  //localListOfProdcuts is the value local to the modal, to be saved on click of Save, or thrown away on cancel/close
  const [selectedProducts, setSelectedProducts] = useState<Array<Product>>(initialSelectedProducts);

  // Fetch the products based on the selected product set and filter term
  const [filterTerm, setFilterTerm] = useState<string>('');
  const [selectedProductSetId, setSelectedProductSetId] = useState<string>(productSets[0]?.id || '');
  const { data: allProducts, status: getProductsStatus } = useGetProducts(selectedProductSetId);

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ProductSelectorViewMode>(ProductSelectorViewMode.Grid);

  const filteredProducts: Array<Product> = useMemo(() => {
    if (!filterTerm) {
      return allProducts || [];
    }
    return (
      allProducts?.filter(
        (product) =>
          product.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
          product.id.toLowerCase().includes(filterTerm.toLowerCase())
      ) || []
    );
  }, [allProducts, filterTerm]);

  const initializeState = () => {
    setSelectedProducts(initialSelectedProducts);
    setSelectedProductSetId(productSets[0]?.id || '');
    setFilterTerm('');
    setSelectAllChecked(false);
    setViewMode(ProductSelectorViewMode.Grid);
  };

  useEffect(() => {
    if (isOpen) {
      initializeState();
    }
  }, [isOpen]);

  // Remove a product locally
  const unselectProduct = (productId: string) => {
    setSelectedProducts(selectedProducts.filter((product: Product) => product.id !== productId));
  };

  // Handle the close of the modal and the saving of changes
  const onSave = () => {
    handleSave(selectedProducts);
    handleClose();
  };

  const handleSelectedProductSetChange = (value: string) => {
    setSelectedProductSetId(value);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAllChecked(event.target.checked);
    if (event.target.checked) {
      const newlySelectedProducts = filteredProducts.filter(
        (product) => !selectedProducts.some((p) => p.id === product.id)
      );
      setSelectedProducts([...selectedProducts, ...newlySelectedProducts]);
    } else {
      const remainingValues = selectedProducts.filter((v) => !filteredProducts.some((product) => product.id === v.id));
      setSelectedProducts(remainingValues);
    }
  };

  const handleProductClick = (product: Product): void => {
    setSelectedProducts((prevSelectedProducts) => {
      const productAlreadySelected = prevSelectedProducts.find((p: Product) => p.id === product.id);

      if (productAlreadySelected) {
        return prevSelectedProducts.filter((p: Product) => p.id !== product.id);
      } else {
        return [...prevSelectedProducts, product];
      }
    });
  };
  const selectedInvalidNumberOfProducts =
    selectedProducts.length < minProducts || (maxProducts !== undefined && selectedProducts.length > maxProducts);

  return (
    <Modal open={isOpen} size='large' onClose={handleClose} title='Select products'>
      <Box display='flex' flexDirection='column' height='calc(100vh - 258px)'>
        {/* 258px is height of the modal top/bottom margins todo find more dynamic way */}
        <Box flexShrink={0}>
          {infoSectionData && (
            <ProductSelectorInfoSection
              mediaSpace={infoSectionData.mediaSpace}
              schedule={infoSectionData.schedule}
              placement={infoSectionData.placement}
            />
          )}
          <hr />
        </Box>
        <Box flexGrow={1} display='flex' overflow='hidden'>
          <Box flexGrow={1} overflow='auto'>
            <ProductSetSelectorAndFilter
              productSets={productSets}
              selectedProductSetId={selectedProductSetId}
              onSelectedProductSetChange={handleSelectedProductSetChange}
              filterTerm={filterTerm}
              onFilterTermChange={setFilterTerm}
            />
            <ProductsContentControls
              filteredProducts={filteredProducts}
              selectAllChecked={selectAllChecked}
              handleSelectAllChange={handleSelectAllChange}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
            <ProductsContent
              status={getProductsStatus}
              filteredProducts={filteredProducts}
              selectAllChecked={selectAllChecked}
              handleSelectAllChange={handleSelectAllChange}
              handleProductClick={handleProductClick}
              viewMode={viewMode}
              setViewMode={setViewMode}
              selectedProducts={selectedProducts}
              productSets={productSets}
            />
          </Box>
          <Box width='400px' flexShrink={0} overflow='auto'>
            <SelectedProductsList
              products={selectedProducts}
              removeProduct={unselectProduct}
              minProducts={minProducts}
              maxProducts={maxProducts}
            />
          </Box>
        </Box>
        <Box flexShrink={0} display='flex' justifyContent='space-between' padding={2}>
          <Button variant='text' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' disabled={selectedInvalidNumberOfProducts} onClick={onSave}>
            Confirm selection
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export type ProductSelectorModalSaveHandler = (products: Array<Product>) => void;
