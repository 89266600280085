import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import DateRange from '../../features/order_form/components/DateRange';
import { selectUser } from '../../features/session/sessionSlice';
import { getOrderReportUrl } from '../../lib/api';
import { STATUS_APPROVED, STATUS_LIVE } from '../../lib/orders';

const selectStyles = {
  option: (styles) => ({
    ...styles,
    backgroundColor: 'while',
    color: 'black',
    overflow: 'wrap',
  }),
};

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const InputSelect = styled(Select)`
  width: 100%;
  margin-top: 1rem;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  h3 {
    font-size: 22px;
  }
  color: #000;
  text-align: left;
  width: 100%;
`;

const orderStatusOptions = [
  { value: STATUS_APPROVED, label: 'Approved' },
  { value: STATUS_LIVE, label: 'Live' },
];

const Reporting = () => {
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [linkExpiryInMinutes, setLinkValidityInMinutes] = useState(5);
  const user = useSelector(selectUser);

  const [reportingFilter, setReportignFilter] = useState({
    status: STATUS_LIVE,
    start_date: moment().subtract(1, 'months'),
    stop_date: moment(),
  });

  const handleSubmit = (e) => {
    setDownloadReady(false);

    if (!reportingFilter.start_date) {
      return setErrors('Please select start date');
    }

    if (!reportingFilter.stop_date) {
      return setErrors('Please select stop date');
    }

    let payload = {
      format: 'csv',
      startDate: moment(reportingFilter.start_date).format('YYYYMMDD'),
      endDate: moment(reportingFilter.stop_date).format('YYYYMMDD'),
      status: reportingFilter.status,
    };
    let url = new URLSearchParams(payload).toString();

    setIsLoading(true);
    setErrors('');
    getOrderReportUrl(user.active_organisation_id, url)
      .then((res) => {
        setDownloadUrl(res.data.data.url);
        setDownloadReady(true);
        setLinkValidityInMinutes(res.data.data.linkValidityInMinutes);
      })
      .catch((error) => {
        setErrors(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className='rounded py-3 d-flex'>
        <StyledTitle className='d-inline-block'>
          <h3 className='font-weight-bold'>Reporting</h3>
          <hr />
        </StyledTitle>
      </div>
      <Row>
        <Col lg='8'>
          <InputContainer>
            <DateRange
              controlIdStartAt='reporting-start-at'
              controlIdStopAt='reporting-stop-at'
              required
              inline
              startLabel='From'
              stopLabel='To'
              value={{ start_datetime: reportingFilter.start_date, stop_datetime: reportingFilter.stop_date }}
              onChange={({ start_datetime, stop_datetime }) =>
                setReportignFilter({ ...reportingFilter, start_date: start_datetime, stop_date: stop_datetime })
              }
              dateTime={false}
              directionUp
            />
            <InputSelect
              placeholder='Select Order Status'
              menuPlacement='top'
              styles={selectStyles}
              value={orderStatusOptions.find((option) => option.value === reportingFilter.status)}
              onChange={(e) => setReportignFilter({ ...reportingFilter, status: e.value })}
              options={orderStatusOptions}
            />
            <Button
              type='button'
              style={{ minWidth: '100px' }}
              variant='outline-secondary'
              disabled={isLoading}
              className='mt-3 '
              onClick={handleSubmit}
            >
              {isLoading ? (
                <Spinner
                  animation='border'
                  variant='dark'
                  size='md'
                  style={{
                    marginRight: '5px',
                    width: '20px',
                    height: '20px',
                  }}
                />
              ) : (
                'Download'
              )}
            </Button>
          </InputContainer>
          {errors != null && <div style={{ 'font-size': '13px', 'margin-top': '5px', color: 'red' }}>{errors}</div>}
          {downloadReady && (
            <div style={{ display: 'block', marginTop: '10px' }}>
              Please download report by clicking{' '}
              <a href={downloadUrl} target='_blank' rel='noreferrer'>
                here
              </a>
              .<br />
              <small>(Link expires in {linkExpiryInMinutes} minutes.)</small>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Reporting;
