import { Typography } from '@zitcha/component-library';
import React from 'react';
interface ModalInputCaptionProps {
  list: Array<{
    id: string | number;
    name: string;
  }>;
  name: string;
}

export const ModalInputCaption = ({ list, name }: ModalInputCaptionProps) => {
  return (
    <>
      {list.length > 0 ? (
        <Typography variant='caption'>
          {list?.length} {name} selected
        </Typography>
      ) : null}
    </>
  );
};