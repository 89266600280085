import { PerPageEnum, ResponseUser, ResponseUserList, useGetUsers } from 'v2/lib/api/access-management';
import { useMemo } from 'react';

export const useUsers = ({ query }: { query: string }) => {
  const { data, isLoading, isError, error } = useGetUsers({
    q: query,
    page: 1,
    perPage: PerPageEnum.NUMBER_25,
  });

  const transformedData = (data: ResponseUserList | undefined) =>
    data?.data?.map((user: ResponseUser) => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
    }));

  const transformed = useMemo(() => {
    return transformedData(data);
  }, [data?.data]);

  return {
    users: transformed,
    isLoading,
    isError,
    error,
  };
};
