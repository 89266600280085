import { useQuery } from '@tanstack/react-query';
import { Product } from 'features/common/types';
import { getProducts } from 'lib/api';

export const useGetProducts = (
    productSetId: string,
    query?: '',
    after?: string,
    limit?: 25
) => {
    const result = useQuery({
        queryKey: ['products', productSetId, query, after, limit],
        queryFn: () => getProducts(productSetId, query, after, limit)
    });
    return {
        data: result.data?.data?.data as Array<Product> | undefined,
        isLoading: result.isLoading,
        error: result.error,
        status: result.status
    };
};  