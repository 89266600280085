import React, { useMemo, useState } from 'react';
import {
  IconButton,
  Chip,
  GridRenderCellParams,
  EditIcon,
  VisibilityIcon,
  Typography,
  Stack,
  SearchIcon,
  ErrorIcon,
  Switch,
  Box,
} from '@zitcha/component-library';
import { formatRecentDate, formatDisplayDate } from 'helpers/DateHelpers';
import { AdSetStatusEnum, PlanStatus } from 'v2/lib/api/ad-management';
import { statusNameMapping, statusChipVariantMap, statusChipColorMap } from 'v2/utils/planStatusUtils';
import { useNavigate } from 'react-router-dom';
import { ViewMode } from './PlanPage/ViewModeType';
import { PLAN_EDIT } from 'lib/permissions';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'; // TODO: Replace with component-library icon
import { CenteredTableCellContainer } from './NewPlanAdSetsCellRenders';

export const actionVariants: { [key: string]: 'filled' | 'outlined' } = {
  clash: 'outlined',
  [AdSetStatusEnum.clashed]: 'filled',
  pending_approval: 'filled',
  'need assets': 'outlined',
  'deadline soon': 'outlined',
  'deadline past': 'outlined',
  'need more info': 'outlined',
  validation: 'outlined',
  [AdSetStatusEnum.draft]: 'filled',
};

export const actionColors: {
  [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
} = {
  clash: 'error',
  [AdSetStatusEnum.new]: 'primary',
  [AdSetStatusEnum.clashed]: 'error',
  rejected: 'error',
  pending_approval: 'primary',
  'need assets': 'info',
  'deadline soon': 'warning',
  'deadline past': 'error',
  validation: 'error',
  [AdSetStatusEnum.reserved]: 'warning',
  'need more info': 'secondary',
  [AdSetStatusEnum.live]: 'success',
  [AdSetStatusEnum.draft]: 'default',
};

export const actionLabels = (label: string) => {
  if (label === 'deadline past') {
    return 'Late';
  }
  const str = label.replaceAll('_', ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const isEditable = (params: GridRenderCellParams): boolean => params.row.permissions[PLAN_EDIT] || false;

export const renderActionButtons = (params: GridRenderCellParams, navigate: ReturnType<typeof useNavigate>) => {
  const planId = params.id;

  return (
    <>
      <IconButton
        color='primary'
        ariaLabel={'Edit plan button'}
        onClick={() => {
          navigate(`/plans-ad-sets/${planId}`, { state: { viewMode: ViewMode.EDITING } });
        }}
        disabled={!isEditable(params)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        color='primary'
        ariaLabel={'View plan button'}
        onClick={() => {
          navigate(`/plans-ad-sets/${planId}`, { state: { viewMode: ViewMode.REVIEWING } });
        }}
      >
        <VisibilityIcon />
      </IconButton>
    </>
  );
};
export const EllipsisTypography = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      variant='body2'
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {children}
    </Typography>
  );
};
export const renderStatusChip = (params: GridRenderCellParams) => {
  const status = params.value as PlanStatus;
  const statusName = statusNameMapping[status];
  const variant = statusChipVariantMap[status];
  const color = statusChipColorMap[status];
  return <Chip label={statusName} variant={variant} color={color} />;
};

export const renderLastModified = (params: GridRenderCellParams) => {
  const date = params.value as Date;
  return formatRecentDate(date);
};

export const renderWalletDateRange = (params: GridRenderCellParams) => {
  const dateRange = params.value as string;
  const [startAt, endAt] = dateRange.split(' - ');
  return `${formatDisplayDate(new Date(startAt))} - ${formatDisplayDate(new Date(endAt))}`;
};

// PlanValue
export const renderPlanValue = (params: GridRenderCellParams) => {
  const value = params.value as number;
  return (
    <CenteredTableCellContainer>
      <RenderCurrency amount={value} />
    </CenteredTableCellContainer>
  );
};

//very rough implementation of the 'hidden chips' feature
export const renderAdSetActions = (params: GridRenderCellParams) => {
  type AdSetAction = {
    count: number;
    type: string;
  };
  const actions: Array<AdSetAction> = params.value;

  // Get the width of the column, default to 400 if undefined
  const columnWidth = params.colDef.width || 400;

  // Calculate the width of each chip based on the length of the label and count
  // 8px per character, 6px for padding
  const chipWidths = actions.map((action) => (action.count.toString().length + action.type.length) * 8 + 6);

  // Calculate the maximum number of Chips that can be displayed
  let totalWidth = 0;
  let maxChips = 0;
  for (const width of chipWidths) {
    if (totalWidth + width > columnWidth) break;
    totalWidth += width;
    maxChips++;
  }

  // Split the actions into those that will be displayed and those that won't
  const displayedActions = actions.slice(0, maxChips);
  const hiddenActions = actions.slice(maxChips);

  // Calculate the total count of the hidden actions
  const hiddenCount = hiddenActions.reduce((total, action) => total + action.count, 0);

  return (
    <>
      {displayedActions.map((action, index) => (
        <Chip
          sx={{ cursor: 'pointer', marginRight: '4px' }}
          key={index}
          label={`${action.count} ${action.type.replace('_', ' ')}`}
          variant={actionVariants[action.type] || 'filled'}
          color={actionColors[action.type] || 'default'}
        />
      ))}
      {hiddenActions.length > 0 && (
        <Chip sx={{ cursor: 'pointer' }} label={`+${hiddenCount}`} variant='outlined' color='default' />
      )}
    </>
  );
};

// AdSetsTable cell renders for the DataGrid

export const renderSKUCode = ({
  params,
  onClick,
}: {
  params: GridRenderCellParams;
  onClick: (skuCode: Array<string>, adData: unknown) => void;
}) => {
  return (
    <Stack spacing={1} direction='row' alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
      <Typography variant='caption'>
        {`${params?.value[0]?.['id'] ? params.value[0]['id'] : ''} ${params.value.length > 1 ? `+${params?.value?.length - 1}` : ''}`}
      </Typography>
      <IconButton
        color='primary'
        size='small'
        ariaLabel={'View SKU codes'}
        onClick={() => {
          onClick(params.value, params?.row);
        }}
      >
        <SearchIcon />
      </IconButton>
    </Stack>
  );
};

export const renderRetailerOrBrand = (params: GridRenderCellParams) => {
  return <EllipsisTypography>{params.value}</EllipsisTypography>;
};
export const renderText = (params: GridRenderCellParams) => (
  <CenteredTableCellContainer>
    <EllipsisTypography>{params.value}</EllipsisTypography>
  </CenteredTableCellContainer>
);

export const renderSwitch = (
  params: GridRenderCellParams,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, params: GridRenderCellParams) => void
) => {
  return (
    <Switch
      value={params.value}
      checked={params.value === 'live'}
      onChange={(event, checked) => onChange(event, checked, params)}
    />
  );
};
export const RenderAdSetsStatusChip = (params: GridRenderCellParams) => {
  const [showRestOfChips, setShowRestOfChips] = useState(false);
  const adSetActions = params.value.actions || [];
  const adSetStatus = params.value.status || '';
  const columnWidth = params.colDef.width || 250;

  const chipWidths = [...adSetActions].map((action) => +action.type.length * 8 + 6);

  // Calculate the width of params.value.adsetStatus
  const adsetStatusWidth = adSetStatus.length * 8 + 6;
  // Calculate the maximum number of Chips that can be displayed
  const maxRenderableAdsetActionChips = useMemo(() => {
    let totalWidth = adsetStatusWidth + 60; //60 is the approximate width of the chip with + sign and number with added
    let maxChips = 0;
    for (const width of chipWidths) {
      if (totalWidth + width > columnWidth) break;
      totalWidth += width;
      maxChips++;
    }
    return maxChips;
  }, [columnWidth, chipWidths]);

  const toggleShowRestOfChips = () => {
    setShowRestOfChips(!showRestOfChips);
  };
  const removeUnderscoreAndLowercaseActionType = (str: string) => str.replace('_', ' ').toLowerCase();
  const hiddenActionsCount = adSetActions.length - maxRenderableAdsetActionChips;
  return (
    <Box gap={1} display='flex' alignItems='center' flexWrap='wrap' py={'8px'}>
      <Chip label={adSetStatus} color={actionColors[adSetStatus] || 'default'} />
      {adSetActions.length > 0 ? (
        <>
          {[...(showRestOfChips ? adSetActions : adSetActions.slice(0, maxRenderableAdsetActionChips))].map(
            (action: { id: string; type: string; entityType: 'adset' | 'plan'; field: string; message: string }) => (
              <Chip
                key={action.id}
                label={`${removeUnderscoreAndLowercaseActionType(action.type)}`}
                variant={actionVariants[removeUnderscoreAndLowercaseActionType(action.type)] || 'outlined'}
                color={actionColors[removeUnderscoreAndLowercaseActionType(action.type)] || 'default'}
              />
            )
          )}

          <>
            {showRestOfChips ? (
              <KeyboardDoubleArrowLeftIcon onClick={toggleShowRestOfChips} sx={{ cursor: 'pointer' }} />
            ) : (
              hiddenActionsCount > 0 && (
                <Chip
                  size='small'
                  onClick={toggleShowRestOfChips}
                  label={`+ ${hiddenActionsCount}`}
                  sx={{ cursor: 'pointer' }}
                />
              )
            )}
          </>
        </>
      ) : null}
    </Box>
  );
};

export const renderAdSetsActions = ({
  isLive,
  isEditable,
  onClickEdit,
  onClickView,
  onClickClash,
  adSetStatus,
}: {
  isLive: boolean;
  isEditable: boolean;
  onClickEdit: () => void;
  onClickView: () => void;
  onClickClash: () => void;
  adSetStatus: AdSetStatusEnum;
}) => (
  <>
    {isLive ? (
      <IconButton color='primary' ariaLabel={'View plan button'} onClick={onClickView}>
        <VisibilityIcon />
      </IconButton>
    ) : (
      <IconButton color='primary' ariaLabel={'Edit plan button'} disabled={!isEditable} onClick={onClickEdit}>
        <EditIcon />
      </IconButton>
    )}
    {adSetStatus === AdSetStatusEnum.clashed && (
      <IconButton color='error' ariaLabel={'Manage clash button'} onClick={onClickClash}>
        <ErrorIcon />
      </IconButton>
    )}
  </>
);
