import { connect } from 'react-redux';
import Feed from '../components/Feed';
import { selectReadOnly } from '../orderFormSlice';

const mapStateToProps = (state, ownProps) => {
  const availableProductSets = ownProps?.productSets || [];
  const productSets = availableProductSets.filter((set) => set.platform === 'facebook');

  return {
    controlId: 'facebook-catalogs',
    required: false,
    readOnly: !ownProps?.type ? selectReadOnly(state) : false,
    productSets: productSets,
  };
};

export default connect(mapStateToProps)(Feed);