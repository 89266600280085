import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FALLBACK_CURRENCY, calculateRoas } from '../../../lib/financial';
import { financialMetricKeys } from '../../organisation_settings/components/MetricPermissions';
import { selectSession } from '../../session/sessionSlice';
import moment from 'moment';

const StatisticsListTable = styled.div`
  tr:nth-child(even) {
    background-color: #f6f5ff;
  }
  tr {
    height: 50px;
    width: 100%;
  }
  td {
    width: 100%;
    padding: 10px;
    font-size: 17px;
    border: none;
  }
`;

const UpdatedAtText = styled.div`
  margin: 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
`;

const MetricsHeadings = styled.div`
  margin-bottom: 12px;
`;

const StyledSelection = ({ value, onClick = () => {}, selected = false }) => {
  return (
    <a
      href='javascript:void(0)'
      onClick={(e) => onClick(e)}
      className='font-weight-bold'
      style={{ color: selected ? '#a28eff' : '#575868' }}
    >
      {value}
    </a>
  );
};

// Todo: look at refactoring this to use the formatToCurrency function from src/lib/financial.js
// and also make min and max fraction digits configurable
const formattedCurrency = (body, unit, currency = FALLBACK_CURRENCY) => {
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      minimumFractionDigits: 0,
      currency,
      maximumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol',
    }).format(body);
  } catch (e) {
    if (e.name !== 'RangeError') {
      throw e;
    }
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      minimumFractionDigits: 0,
      currency,
      maximumFractionDigits: 2,
      currencyDisplay: 'symbol',
    }).format(body);
  }
};

const formattedNumber = (body) =>
  new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(body);

const formatPercentage = (body) =>
  new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(body) + '%';

const getHumanisedRefreshedAt = (refreshedAt) => {
  const now = moment();
  const refreshed = moment(refreshedAt);
  const diffInMinutes = now.diff(refreshed, 'minutes');

  if (diffInMinutes < 1) {
    return 'just now';
  } else if (diffInMinutes === 1) {
    return '1 minute ago';
  } else {
    return refreshed.fromNow();
  }
};

const InsightsView = ({
  insightSchema = [],
  insights = {},
  overviewFields,
  sortByName = false,
  additionalInsightsSchema = [], //temporary to add more insights for MPA. Needs to be added to BE Order model.
  refreshedAt,
  currency = FALLBACK_CURRENCY,
}) => {
  const combinedInsightSchema = [...insightSchema, ...additionalInsightsSchema];
  const fields = insights
    ? combinedInsightSchema.filter(
        (field) => field.name in insights && insights[field.name] !== undefined && insights[field.name] !== null
      )
    : {};
  const sortedFields = sortByName
    ? sortBy(fields, ['title'])
    : sortBy(fields, (field) => (financialMetricKeys.includes(field.name) ? 1 : 0));

  const totalRoas = parseInt(insights?.total_roas);
  const updatedRoasInsights = {
    ...insights,
    total_roas: totalRoas
      ? totalRoas?.toFixed(2)
      : calculateRoas(insights?.total_conversion_value ?? 0, insights?.spend ?? 0),
  };

  const [selection, setSelection] = useState(overviewFields ? 'overview' : 'select-all');
  const [metrics, setMetrics] = useState(sortedFields);
  const session = useSelector(selectSession);

  const organisation = session.user.active_organisation;
  const permissions = organisation?.permissions;
  const skewMetrics = permissions?.metrics?.skew_metrics_retailer ?? false;

  useEffect(() => {
    let filteredMetrics = sortedFields;

    if (selection === 'financial') {
      filteredMetrics = filteredMetrics.filter((f) => f.unit === 'currency');
    } else if (selection === 'performance') {
      filteredMetrics = filteredMetrics.filter((f) => f.unit !== 'currency');
    } else if (selection === 'overview') {
      filteredMetrics = filteredMetrics.filter((f) => overviewFields.includes(f.name));
    }

    // Filter based on permissions but always include metrics from additionalInsightsSchema
    if (permissions && skewMetrics) {
      const additionalMetricsNames = new Set(additionalInsightsSchema.map((item) => item.name));
      filteredMetrics = filteredMetrics.filter((field) => {
        return additionalMetricsNames.has(field.name) || permissions.metrics.insights[field.name];
      });
    }

    setMetrics(filteredMetrics);
  }, [selection, overviewFields]);

  return (
    <>
      <>
        <MetricsHeadings>
          {overviewFields && (
            <span style={{ paddingRight: '51px' }}>
              <StyledSelection
                value={'Overview'}
                onClick={() => setSelection('overview')}
                selected={selection === 'overview'}
              />
            </span>
          )}
          <span style={{ paddingRight: '51px' }}>
            <StyledSelection
              value={'Financial'}
              onClick={() => setSelection('financial')}
              selected={selection === 'financial'}
            />
          </span>
          <span style={{ paddingRight: '51px' }}>
            <StyledSelection
              value={'Performance'}
              onClick={() => setSelection('performance')}
              selected={selection === 'performance'}
            />
          </span>
          <span style={{ paddingRight: '51px', color: 'red' }}>
            <StyledSelection
              value={'All'}
              onClick={() => setSelection('select-all')}
              selected={selection === 'select-all'}
            />
          </span>
        </MetricsHeadings>
        {metrics.length > 0 && (
          <>
            <StatisticsListTable className='table'>
              <table>
                <tbody>
                  {metrics.map((field) => (
                    <tr key={field.name}>
                      <td style={{ textAlign: 'left' }}>{field.title}</td>
                      <td style={{ textAlign: 'right' }}>
                        {field.unit && field.unit === 'currency'
                          ? formattedCurrency(updatedRoasInsights[field.name], field.unit, currency)
                          : field.unit && field.unit === 'percentage'
                            ? field.name !== 'total_roas'
                              ? formatPercentage(updatedRoasInsights[field.name] * 100)
                              : formatPercentage(updatedRoasInsights[field.name])
                            : formattedNumber(updatedRoasInsights[field.name])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </StatisticsListTable>
            {refreshedAt && <UpdatedAtText>Updated {getHumanisedRefreshedAt(refreshedAt)}</UpdatedAtText>}
          </>
        )}
      </>
      {metrics.length === 0 && (
        <div className='text-center' style={{ paddingTop: '10px' }}>
          No Metrics available.
        </div>
      )}
    </>
  );
};

InsightsView.propTypes = {
  insightSchema: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  insights: PropTypes.object,
  currency: PropTypes.string,
};

export default InsightsView;
