import { useGetInventoryByMediaSpaceLocation } from 'v2/lib/api/inventory';
import { Bundle } from '../api/ad-management';
import { useMemo } from 'react';

export const useInventoryForBundle = (bundle: Bundle | null | undefined) => {
    const mediaSpaceId = bundle?.mediaSpace?.id;
    const locationId = bundle?.locations?.[0]?.id;

    const {
        data: inventoryData,
        isLoading: isLoadingInventory,
        error: inventoryError
    } = useGetInventoryByMediaSpaceLocation(
        mediaSpaceId ?? '',
        locationId ?? '',
        {
            query: {
                enabled: !!mediaSpaceId && !!locationId,
            },
        }
    );

    return useMemo(() => ({
        inventoryData: inventoryData?.data,
        isLoadingInventory: isLoadingInventory && !!mediaSpaceId && !!locationId,
        inventoryError: inventoryError ? true : false
    }), [inventoryData, isLoadingInventory, inventoryError, mediaSpaceId, locationId]);
};