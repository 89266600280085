import { Box, Chip, GridRenderCellParams } from '@zitcha/component-library';
import React, { ReactNode, useState } from 'react';

export const RenderTeamRoleCell = (params: GridRenderCellParams): ReactNode => {
  const [showAll, setShowAll] = useState(false);
  // group by roles and relevalnt teams
  // example: Admin : [Team 1, Team 2]
  const groupedTeams = params?.value?.reduce(
    (
      acc: Record<string, Array<string>>,
      {
        team,
        role,
      }: {
        team: { id: number; name: string };
        role: { id: number; name: string };
      }
    ) => {
      if (!acc[role.name]) {
        acc[role.name] = [];
      }
      acc[role.name].push(team.name);
      return acc;
    },
    {}
  );
  const displayable = showAll ? Object.entries(groupedTeams) : Object.entries(groupedTeams).slice(0, 1);

  return (
    <Box display='flex' gap={1} alignItems='center' height='100%'>
      {displayable.map(([role, teams]: [string, Array<string>]) => (
        <Chip key={role} label={`${role} : ${teams.join(' . ')}`} size='small' />
      ))}
      {Object.entries(groupedTeams).length > 1 && !showAll ? (
        <Chip
          label={`+ ${Object.entries(groupedTeams).length - 1}`}
          clickable={true}
          onClick={() => {
            setShowAll(!showAll);
          }}
        />
      ) : null}
    </Box>
  );
};