import React from 'react';
import { Box, Typography } from '@zitcha/component-library';

const statusColours = new Map([
  ['pending-approval', '#4D216D'],
  ['clash', '#FECDD2'],
  ['reserved', '#FBC02D'],
  ['ready', '#8EC02D4D'],
  ['live', '#8BC34A'],
  ['paused', '#FF7043'],
  ['completed', '#2D333A'],
  ['ineligible', '#f7f7f7'],
  ['failed', '#C51D25'],
  ['expired', '#C51D25'],
]);

export const statusKeys = Array.from(statusColours.keys());

export const StatusColours = () => {
  return (
    <Box display='flex' gap={1} padding={2} alignItems='center' role='presentation'>
      {Array.from(statusColours.entries()).map(([key, value]) => {
        return (
          <Box key={key} display='flex' gap={1} alignItems='center'>
            <div style={{ backgroundColor: value }} className='twd-size-5 twd-rounded-[20%]' role='status-color' />
            <Typography variant='caption'>{key}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
